import {Card, CardBody, CardHeader} from 'reactstrap';
import {ReactNode} from 'react';

type Props = {
  className?: string
  bodyClassName?: string
  children: ReactNode
}

const AssessmentRollAnalysisV3Card = ({
                                        className,
                                        bodyClassName,
                                        children
                                      }: Props) => {
  return (
    <Card className={className}>
      <CardHeader className="font-weight-bold">
        Assessment Roll Analysis for Substantial Compliance
      </CardHeader>
      <CardBody className={bodyClassName}>
        {children}
      </CardBody>
    </Card>
  );
};

export default AssessmentRollAnalysisV3Card;