import {Button, Form, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';

import {FormikInput, useAlerts, windowUtils} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {capApi} from '../../api';
import {DenyCapFormFields, LocalUnitAudit} from '../../types';
import {denyCapFormSchema} from '../../schema';

type Props = {
  isOpen: boolean
  localUnitAudit: LocalUnitAudit
  onSubmit: (values: DenyCapFormFields, formikHelpers: FormikHelpers<DenyCapFormFields>) => void
  onCancel: () => void
}

const DenyCapModal = ({
                        isOpen,
                        localUnitAudit,
                        onSubmit,
                        onCancel
                      }: Props) => {
  const {showErrorAlert} = useAlerts();
  const initialValues: DenyCapFormFields = {denialReason: '', draftPdfVerified: false};

  const handleViewLetter = async (formikProps: FormikProps<DenyCapFormFields>) => {
    try {
      await windowUtils.openFileInNewWindow(capApi.generateDenyDraftPdf(localUnitAudit.id, formikProps.values));
      formikProps.setFieldValue('draftPdfVerified', true);
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT);
    }
  };

  const handleCancel = (formikProps: FormikProps<DenyCapFormFields>) => {
    formikProps.resetForm();
    onCancel();
  };

  return (
    <Formik initialValues={initialValues}
            validationSchema={denyCapFormSchema}
            onSubmit={onSubmit}>
      {(formikProps) => (
        <Modal className="DenyCapModal"
               isOpen={isOpen}
               size="lg"
               backdrop="static"
               autoFocus={false}
               toggle={() => handleCancel(formikProps)}>
          <ModalHeader toggle={() => handleCancel(formikProps)}>Deny Corrective Action Plan</ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              <p>
                Are you sure you want to deny the Corrective Action Plan for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
              </p>
              <FormikInput name="denialReason"
                           labelText="Denial Reason"
                           type="textarea"
                           formGroupClass="larger-label"
                           maxLength={355}
                           rows={4}
                           autoFocus
                           aria-label="Denial Reason"/>
            </ModalBody>
            <ModalFooter>
              <Button color="primary"
                      onClick={() => handleViewLetter(formikProps)}
                      disabled={formikProps.isSubmitting || formikProps.values.denialReason === ''}>
                View Letter
              </Button>
              <Button color="success"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}>
                Deny Cap
              </Button>
              <Button color="secondary"
                      onClick={() => handleCancel(formikProps)}
                      disabled={formikProps.isSubmitting}>
                No
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default DenyCapModal;