import {ReactNode} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';

type Props = {
  className?: string
  bodyClassName?: string
  children: ReactNode
}

const ReviewOfExemptionsCard = ({
                                  className,
                                  bodyClassName,
                                  children
                                }: Props) => {
  return (
    <Card className={className}>
      <CardHeader>
        5. Review of Exemptions Granted under MCL 211.7u (poverty exemptions)
      </CardHeader>
      <CardBody className={bodyClassName}>
        {children}
      </CardBody>
    </Card>
  );
};


export default ReviewOfExemptionsCard;