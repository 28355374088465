import {Card, CardBody, CardHeader} from 'reactstrap';
import {ReactNode} from 'react';

type Props = {
  className?: string
  bodyClassName?: string
  children: ReactNode
}

const AssessmentRollAnalysisCard = ({
                                      className,
                                      bodyClassName,
                                      children
                                    }: Props) => {
  return (
    <Card className={className}>
      <CardHeader>Assessment Roll Analysis</CardHeader>
      <CardBody className={bodyClassName}>
        {children}
      </CardBody>
    </Card>
  );
};

export default AssessmentRollAnalysisCard;