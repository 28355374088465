import {ReactNode} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';

import ReviewSheetContactInfo from './ReviewSheetContactInfo';
import {LocalUnitAudit, LocalUnitContactInfo} from '../../types';

type Props = {
  className?: string
  showContactInfo: boolean
  localUnitAudit: LocalUnitAudit
  localUnitContactInfo?: LocalUnitContactInfo
  children: ReactNode
}

const LocalUnitBackgroundCard = ({
                                   className,
                                   showContactInfo = false,
                                   localUnitAudit,
                                   localUnitContactInfo,
                                   children
                                 }: Props) => {
  return (
    <Card className={className}>
      <CardHeader>Local Unit Background Information</CardHeader>
      <CardBody>
        {showContactInfo && localUnitContactInfo &&
          <ReviewSheetContactInfo auditYear={localUnitAudit.year}
                                  localUnitName={localUnitAudit.localUnit.displayNameWithType}
                                  countyName={localUnitAudit.county.displayNameWithType}
                                  localUnitContactInfo={localUnitContactInfo}/>
        }
        {children}
      </CardBody>
    </Card>
  );
};

export default LocalUnitBackgroundCard;