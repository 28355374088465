import {ReactNode} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';

type Props = {
  className?: string
  bodyClassName?: string
  children: ReactNode
}

const AssessmentProcedureAnalysisCard = ({
                                           className,
                                           bodyClassName,
                                           children
                                         }: Props) => {
  return <Card className={className}>
      <CardHeader className="font-weight-bold">
        Assessment Procedure Analysis for Technical Compliance
      </CardHeader>
      <CardBody className={bodyClassName}>
        {children}
      </CardBody>
    </Card>
};

export default AssessmentProcedureAnalysisCard;