import * as Yup from 'yup';

import {
  assessmentRollAnalysisV1Schema,
  baseSchema,
  personalPropertyReviewSchema,
  reviewOfExemptionsGrantedSchema,
  standardYesNoValidation
} from './capBaseSchema';
import {LocalUnitAuditAction} from '../../enum';

// Only a single question was added to this section in version 2
const reviewOfExemptionsGrantedV2Schema = reviewOfExemptionsGrantedSchema.concat(
  Yup.object()
    .when('saveAction', {
      is: LocalUnitAuditAction.CAP_SUBMIT,
      then: Yup.object().shape({
        implementedCamaDataStandardsDate: standardYesNoValidation('implementedCamaDataStandards', true),
        implementedCamaDataStandardsComments: standardYesNoValidation('implementedCamaDataStandards')
      })
    })
);

export default baseSchema.concat(
  Yup.object().shape({
    assessmentRollAnalysis: assessmentRollAnalysisV1Schema,
    personalPropertyReview: personalPropertyReviewSchema,
    reviewOfExemptionsGranted: reviewOfExemptionsGrantedV2Schema
  })
);