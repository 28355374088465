import {pa660Api} from './apiUtils';
import {AuditHistoryRecord} from '../types';

export const findByLocalUnitAudit = (localUnitAuditId: number,
                                     portal: string): Promise<AuditHistoryRecord[]> => pa660Api.getWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/audit-history?portal=${portal}`
);

export const findDocument = (localUnitAuditId: number,
                             auditHistoryRecordId: number): Promise<string> => pa660Api.getWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/audit-history/${auditHistoryRecordId}`
);

export const findReviewSheetDocument = (localUnitAuditId: number): Promise<string> => pa660Api.getWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/audit-history/review-sheet`
);

export const findCapDocument = (localUnitAuditId: number): Promise<string> => pa660Api.getWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/audit-history/cap`
);

export const findCapZip = (localUnitAuditId: number,
                           auditHistoryRecordId: number) => pa660Api.getWithBlobResponse(
  `local-unit-audits/${localUnitAuditId}/audit-history/${auditHistoryRecordId}/cap/zip`
);