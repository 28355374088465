import * as Yup from 'yup';

import {YupFileArray} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {LocalUnitAuditAction} from '../../enum';

export const standardYesNoValidation = (reviewSheetKey: string, isDate?: boolean) => {
  return isDate ?
    Yup.date()
      .when(reviewSheetKey, {
        is: 'No',
        then: Yup.date().required(messages.REQUIRED)
      })
    :
    Yup.string()
      .when(reviewSheetKey, {
        is: 'No',
        then: Yup.string().required(messages.REQUIRED)
      });
};

export const assessmentRollAnalysisBaseSchema = Yup.object().shape({
  economicConditionFactorsMetDate: standardYesNoValidation('economicConditionFactorsMet', true),
  economicConditionFactorsMetComments: standardYesNoValidation('economicConditionFactorsMet'),
  landValueDeterminationsAppropriateDate: standardYesNoValidation('landValueDeterminationsAppropriate', true),
  landValueDeterminationsAppropriateComments: standardYesNoValidation('landValueDeterminationsAppropriate'),
  cashValueAgreesDate: standardYesNoValidation('cashValueAgrees', true),
  cashValueAgreesComments: standardYesNoValidation('cashValueAgrees')
});

export const assessmentRollAnalysisV1Schema = assessmentRollAnalysisBaseSchema.concat(Yup.object().shape({
  economicConditionFactorsMetResolution: standardYesNoValidation('economicConditionFactorsMet'),
  landValueDeterminationsAppropriateResolution: standardYesNoValidation('landValueDeterminationsAppropriate'),
  cashValueAgreesResolution: standardYesNoValidation('cashValueAgrees')
}));

export const assessmentRollAnalysisV2Schema = assessmentRollAnalysisBaseSchema;

export const personalPropertyReviewSchema = Yup.object().shape({
  conductsAnnualPersonalPropertyCanvasDate: standardYesNoValidation('conductsAnnualPersonalPropertyCanvas', true),
  conductsAnnualPersonalPropertyCanvasComments: standardYesNoValidation('conductsAnnualPersonalPropertyCanvas'),
  exemptionsProcessingRequirementMetDate: standardYesNoValidation('exemptionsProcessingRequirementMet', true),
  exemptionsProcessingRequirementMetComments: standardYesNoValidation('exemptionsProcessingRequirementMet')
});

export const reviewOfExemptionsGrantedSchema = Yup.object().shape({
  hasPovertyExemptionGuidelinesDate: standardYesNoValidation('hasPovertyExemptionGuidelines', true),
  hasPovertyExemptionGuidelinesComments: standardYesNoValidation('hasPovertyExemptionGuidelines'),
  guidelinesIncludeAssetLevelTestDate: standardYesNoValidation('guidelinesIncludeAssetLevelTest', true),
  guidelinesIncludeAssetLevelTestComments: standardYesNoValidation('guidelinesIncludeAssetLevelTest'),
  statutoryRequirementsMetAndPolicyFollowedDate: standardYesNoValidation('statutoryRequirementsMetAndPolicyFollowed', true),
  statutoryRequirementsMetAndPolicyFollowedComments: standardYesNoValidation('statutoryRequirementsMetAndPolicyFollowed'),
  onlyStatutoryAuthorityItemsConsideredDate: standardYesNoValidation('onlyStatutoryAuthorityItemsConsidered', true),
  onlyStatutoryAuthorityItemsConsideredComments: standardYesNoValidation('onlyStatutoryAuthorityItemsConsidered'),
  ptaFilingImposedDate: Yup.date()
    .when(['ptaFilingImposed', 'penaltyWaivedByResolution'], {
      is: (ptaFilingImposed: string, penaltyWaivedByResolution: string) => ptaFilingImposed === 'No' && penaltyWaivedByResolution === 'No',
      then: Yup.date()
        .required(messages.REQUIRED)
    }),
  ptaFilingImposedComments: Yup.string()
    .when(['ptaFilingImposed', 'penaltyWaivedByResolution'], {
      is: (ptaFilingImposed: string, penaltyWaivedByResolution: string) => ptaFilingImposed === 'No' && penaltyWaivedByResolution === 'No',
      then: Yup.string()
        .required(messages.REQUIRED)
    })
});

export const baseSchema = Yup.object().shape({
  saveAction: Yup.string().oneOf([
    LocalUnitAuditAction.CAP_SAVE,
    LocalUnitAuditAction.CAP_SUBMIT
  ]),
  version: Yup.string()
    .required(),
  files: new YupFileArray()
    .maxFileSize(50 * 1000 * 1000, messages.MAX_FILE_SIZE)
    .acceptedFileTypes(['PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX'], messages.BAD_CAP_UPLOAD_FORMAT)
    .schema()
    .min(1),
  filesToDelete: Yup.array()
    .of(Yup.number().positive())
});