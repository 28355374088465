import {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {without} from 'lodash';

import {FormikCheckboxGroup, FormikSelect, useAlerts} from '@reasoncorp/kyber-js';

import {DeficiencyDto, FollowUpDto, FollowUpFormFields, LocalUnitAudit} from '../../types';
import {followUpFormSchema} from '../../schema';
import * as messages from '../../messages';
import {deficiencyApi} from '../../api';

type Props = {
  isOpen: boolean
  localUnitAudit: LocalUnitAudit
  onSubmit: (values: FollowUpDto, formikHelpers: FormikHelpers<FollowUpFormFields>) => void
  onCancel: () => void
  isAssume?: boolean
  requireYear?: boolean
}

const getSubDeficiencyDisplayText = (subDeficiency: string) => {
  if (subDeficiency === 'A') {
    return 'Agricultural';
  } else if (subDeficiency === 'B') {
    return 'Commercial';
  } else if (subDeficiency === 'C') {
    return 'Industrial';
  } else if (subDeficiency === 'D') {
    return 'Residential';
  } else {
    return '';
  }
};

const FollowUpModal = ({
                         isOpen,
                         localUnitAudit,
                         onSubmit,
                         onCancel,
                         requireYear = true,
                         isAssume = false
                       }: Props) => {
  const {showErrorAlert} = useAlerts();
  const [deficiencies, setDeficiencies] = useState<DeficiencyDto[]>([]);
  const [subDeficiencies, setSubDeficiencies] = useState<DeficiencyDto[]>([]);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedDeficiencies, setSelectedDeficiencies] = useState<DeficiencyDto[]>([]);
  const selectedDeficiencyIds = useMemo(() => selectedDeficiencies.map(deficiency => deficiency.id),
    [selectedDeficiencies]
  );
  const [
    selectedSubDeficiencies,
    setSelectedSubDeficiencies
  ] = useState<{[key: string]: DeficiencyDto[]}>({});

  const getSelectedSubDeficiencies = useCallback((deficiencyId: number) => {
    return selectedSubDeficiencies[deficiencyId] || [];
  }, [selectedSubDeficiencies]);

  const requiredSubDeficienciesAreSelected = useMemo(() => {
    const deficiency1Selected = selectedDeficiencyIds.includes(1);
    const deficiency2Selected = selectedDeficiencyIds.includes(2);

    if (deficiency1Selected || deficiency2Selected) {
      return (deficiency1Selected && getSelectedSubDeficiencies(1).length > 0) ||
        (deficiency2Selected && getSelectedSubDeficiencies(2).length > 0);
    }

    return true;
  }, [getSelectedSubDeficiencies, selectedDeficiencyIds]);

  // Deferring to custom validation for this over Yup
  const isValid = useMemo(() => {
    return requiredSubDeficienciesAreSelected &&
      selectedDeficiencyIds.length > 0 &&
      selectedDeficiencyIds.length <= 5;
  }, [
    selectedDeficiencyIds,
    requiredSubDeficienciesAreSelected
  ]);

  const handleSubmit = useCallback(({
                                      year
                                    }: FollowUpFormFields,
                                    formikHelpers: FormikHelpers<FollowUpFormFields>) => {
    onSubmit({
      year,
      deficiencyIds: selectedDeficiencyIds,
      subDeficiencyIds: {
        1: getSelectedSubDeficiencies(1).map(subDeficiency => subDeficiency.id),
        2: getSelectedSubDeficiencies(2).map(subDeficiency => subDeficiency.id)
      }
    }, formikHelpers);
  }, [onSubmit, selectedDeficiencyIds, getSelectedSubDeficiencies]);

  const mapDeficiencies = useCallback((deficiencies: DeficiencyDto[]) => deficiencies.map(({
                                                                                             id,
                                                                                             key,
                                                                                             subDeficiency
                                                                                           }) => ({
    id,
    selected: selectedDeficiencyIds.includes(id),
    requiresSubDeficiencies: !subDeficiency && (key === 'A' || key === 'B')
  })), [selectedDeficiencyIds]);

  const initialValues: FollowUpFormFields = useMemo(() => ({
    year: selectedYear,
    deficiencies: mapDeficiencies(deficiencies),
    subDeficiencies: {
      1: mapDeficiencies(getSelectedSubDeficiencies(1)),
      2: mapDeficiencies(getSelectedSubDeficiencies(2))
    }
  }), [deficiencies, selectedYear, getSelectedSubDeficiencies, mapDeficiencies]);

  useEffect(() => {
    const loadDeficiencies = async () => {
      try {
        const {
          deficiencies,
          subDeficiencies
        } = await deficiencyApi.findAll();

        setDeficiencies(deficiencies);
        setSubDeficiencies(subDeficiencies);
      } catch (error) {
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadDeficiencies();
  }, [showErrorAlert]);

  // Implementing this simple business logic here versus backend.
  // This follow-up year list should be the year of the audit + 1, and then the next 3 years after that.
  const followUpYears = useMemo(() => (
    [
      localUnitAudit.year + 1,
      localUnitAudit.year + 2,
      localUnitAudit.year + 3,
      localUnitAudit.year + 4
    ]
  ), [localUnitAudit.year]);

  const handleCancel = useCallback((formikProps: FormikProps<FollowUpFormFields>) => {
    formikProps.resetForm();
    onCancel();
  }, [onCancel]);

  const handleDeficiencyToggle = useCallback((deficiency: DeficiencyDto,
                                              formikProps: FormikProps<FollowUpFormFields>) => {
    const mustSelectSubDeficiency = deficiency.key === 'A' || deficiency.key === 'B';
    const isChecking = !selectedDeficiencyIds.includes(deficiency.id);
    const updatedDeficiencies = isChecking ?
      [...selectedDeficiencies, deficiency] : without(selectedDeficiencies, deficiency);

    setSelectedDeficiencies(updatedDeficiencies);

    // If the deficiency A or B is being unselected, we need to reset the sub-deficiencies for that deficiency.
    if (mustSelectSubDeficiency && !isChecking) {
      setSelectedSubDeficiencies({...selectedSubDeficiencies, [deficiency.id]: []});
      void formikProps.setFieldValue(`subDeficiencies['${deficiency.id}']`, mapDeficiencies([]));
    }
  }, [selectedDeficiencyIds, selectedSubDeficiencies, selectedDeficiencies, mapDeficiencies]);

  const handleSubDeficiencyToggle = useCallback((parentDeficiencyId: number,
                                                 subDeficiency: DeficiencyDto) => {
    const currentSelectedSubDeficiencies = getSelectedSubDeficiencies(parentDeficiencyId);
    setSelectedSubDeficiencies({
      ...selectedSubDeficiencies,
      [parentDeficiencyId]: currentSelectedSubDeficiencies.includes(subDeficiency) ?
        without(currentSelectedSubDeficiencies, subDeficiency) :
        [...currentSelectedSubDeficiencies, subDeficiency]
    });
  }, [getSelectedSubDeficiencies, selectedSubDeficiencies]);

  const renderDeficiencyRow = useMemo(() => (deficiency: DeficiencyDto,
                                             formikProps: FormikProps<FollowUpFormFields>,
                                             index: number) => {
    const mustSelectSubDeficiency = deficiency.key === 'A' || deficiency.key === 'B';
    const hasNoRequiredSubDeficiencies = mustSelectSubDeficiency &&
      selectedDeficiencyIds.includes(deficiency.id) &&
      getSelectedSubDeficiencies(deficiency.id).length === 0;

    return <>
      <tr key={deficiency.id}>
        <td className="text-center align-middle w-5">
          <FormikCheckboxGroup disabled={selectedDeficiencyIds.length >= 5 && !selectedDeficiencyIds.includes(deficiency.id)}
                               formGroupClass="mb-0"
                               checkboxes={[{
                                 name: `deficiencies['${index}'].selected`,
                                 labelText: '',
                                 ariaLabel: `Toggle select ${deficiency}: ${deficiency.description}`,
                                 onChange: () => handleDeficiencyToggle(deficiency, formikProps)
                               }]}/>

        </td>
        <td className="align-middle w-95" colSpan={2}>
          <span className="font-weight-bold text-primary">
            {deficiency.description}
          </span>
          {mustSelectSubDeficiency && <span className="ml-2">
            (must select one or more sub-deficiencies)
          </span>}
        </td>
      </tr>
      {mustSelectSubDeficiency && <>
        {subDeficiencies.map((subDeficiency, index) =>
          <tr key={`sub-deficiency-${subDeficiency.id}`}>
            <td className="w-5"/>
            <td className="text-center align-middle w-5">
              <FormikCheckboxGroup disabled={!selectedDeficiencyIds.includes(deficiency.id)}
                                   formGroupClass="mb-0"
                                   checkboxes={[{
                                     name: `subDeficiencies['${deficiency.id}'][${index}].selected`,
                                     labelText: '',
                                     ariaLabel: `Toggle select ${deficiency}: ${subDeficiency}`,
                                     onChange: () => handleSubDeficiencyToggle(deficiency.id, subDeficiency)
                                   }]}/>
            </td>
            <td className="align-middle w-90 text-secondary font-weight-bold">
              {getSubDeficiencyDisplayText(subDeficiency.key)}
            </td>
          </tr>)}
        {hasNoRequiredSubDeficiencies && <tr key={`sub-deficiency-error-${deficiency}`}>
          <td className="w-5"/>
          <td colSpan={2}>
            <span className="text-danger font-weight-bold">{messages.MUST_SELECT_AT_LEAST_1_SUB_DEFICIENCY}</span>
          </td>
        </tr>}
      </>}
    </>;
  }, [
    handleDeficiencyToggle,
    subDeficiencies,
    selectedDeficiencyIds,
    handleSubDeficiencyToggle,
    getSelectedSubDeficiencies
  ]);

  const renderYearOption = useMemo(() => (year: number) => <option key={year} value={year}>{year}</option>, []);

  return (
    <Formik initialValues={initialValues}
            validateOnMount={true}
            validationSchema={followUpFormSchema(requireYear)}
            onSubmit={handleSubmit}>
      {(formikProps) => (
        <Modal className="FollowUpModal"
               isOpen={isOpen}
               backdrop="static"
               size="lg"
               autoFocus={false}
               toggle={() => handleCancel(formikProps)}>
          <ModalHeader toggle={() => handleCancel(formikProps)}>
            {isAssume && 'Assume & '} Select <span className="follow-up-modal-local-unit">{localUnitAudit.localUnit.displayNameWithType}</span> Follow Up Details
          </ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              {requireYear && <Row>
                <Col>
                  <FormikSelect name="year"
                                labelText="Follow Up Year"
                                autoFocus
                                onChange={(event) => setSelectedYear(event.target.value)}
                                aria-required>
                    <option key="" value="">Select</option>
                    {followUpYears.map(renderYearOption)}
                  </FormikSelect>
                </Col>
              </Row>}
              <Row>
                <Col>
                  <Table striped responsive bordered>
                    <thead className="font-weight-bold text-primary">
                      <tr>
                        <th colSpan={3}>
                          Select Up to 5 Deficiencies
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {deficiencies.map((deficiency, index) => renderDeficiencyRow(
                        deficiency,
                        formikProps,
                        index
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      onClick={formikProps.submitForm}
                      disabled={!formikProps.dirty || !formikProps.isValid || !isValid || formikProps.isSubmitting}>
                Confirm
              </Button>
              <Button color="secondary"
                      onClick={() => handleCancel(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default memo(FollowUpModal);