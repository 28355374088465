import * as Yup from 'yup';

import {
  assessmentRollAnalysisV1Schema,
  baseSchema,
  personalPropertyReviewSchema,
  reviewOfExemptionsGrantedSchema
} from './capBaseSchema';


export default baseSchema.concat(
  Yup.object().shape({
    assessmentRollAnalysis: assessmentRollAnalysisV1Schema,
    personalPropertyReview: personalPropertyReviewSchema,
    reviewOfExemptionsGranted: reviewOfExemptionsGrantedSchema
  })
);