import {useMemo} from 'react';
import {Form} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {JurisdictionFormFields, LocalUnitPermission} from '../types';

type Props = {
  localUnitId: number
  localUnitPermissions: LocalUnitPermission[]
  disabled: boolean
  onChange: (values: JurisdictionFormFields, formikHelpers: FormikHelpers<JurisdictionFormFields>) => void
}

const JurisdictionSelect = ({
                              localUnitId,
                              localUnitPermissions,
                              disabled,
                              onChange
                            }: Props) => {
  const renderOption = useMemo(() => (localUnitPermission: LocalUnitPermission) => {
    return (
      <option key={localUnitPermission.localUnitId} value={localUnitPermission.localUnitId}>
        {localUnitPermission.localUnitDisplayName}
      </option>
    );
  }, []);

  return (
    <Formik initialValues={{localUnitId: localUnitId}}
            onSubmit={onChange}>
      {(formikProps) => (
        <Form autoComplete="off">
          <FormikSelect name="localUnitId"
                        labelText="Jurisdiction"
                        disabled={disabled}
                        aria-required={true}
                        onChange={() => {
                          formikProps.handleSubmit();
                        }}
                        style={{width: 'auto'}}>
            {localUnitPermissions.map(renderOption)}
          </FormikSelect>
        </Form>
      )}
    </Formik>
  );
};

export default JurisdictionSelect;