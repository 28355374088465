import {downloadUtils, RestApiHelper, SsoSpringConfig, SsoSpringRestApi} from '@reasoncorp/kyber-js';

import config from '../config';
import * as messages from '../messages';

export const ssoApi = new SsoSpringRestApi(new SsoSpringConfig({...config.sso}));

export const pa660Api = new RestApiHelper({
  path: config.apiHost,
  unauthorizedHandler: ssoApi.redirectToLogin,
  bumpLastActivity: ssoApi.bumpLastActivity,
  defaultFetchConfig: {
    credentials: 'include'
  }
});

export const downloadFile = (showErrorAlert: (message: string, disableTimeout?: boolean) => void,
                             message = messages.VIEW_FILE_FAILURE
) => async (url: string) => {
  await downloadUtils.downloadFile(pa660Api.getWithTextResponse(url), undefined, () => showErrorAlert(message));
};