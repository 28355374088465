import * as Yup from 'yup';

import {YupFileArray} from '@reasoncorp/kyber-js';

import * as messages from '../messages';

export default Yup.object().shape({
  files: new YupFileArray()
    .maxFileSize(50 * 1000 * 1000, messages.MAX_FILE_SIZE)
    .acceptedFileTypes(['CSV', 'PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX', 'TXT', 'XLS', 'XLSM', 'XLSX'], messages.BAD_FILE_FORMAT_UPLOAD)
    .schema()
    .required(messages.REQUIRED)
    .max(5, messages.MAX_FILES_UPLOAD),
  descriptions: Yup.array(Yup.string()
    .max(200, messages.maxCharactersMessage(200))
    .nullable()
  )
});