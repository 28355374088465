import {pa660Api} from './apiUtils';
import {FollowUpDto, LocalUnitAudit, LocalUnitContactInfo} from '../types';

export const findById = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.getWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}`
);

export const findByLocalUnitId = (localUnitId: number): Promise<LocalUnitAudit[]> => pa660Api.getWithJsonResponse(
  'local-unit-audits',
  {
    queryParams: {localUnitId}
  }
);

export const contactInfo = (localUnitAuditId: number): Promise<LocalUnitContactInfo> => pa660Api.getWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/contact-info`
);

export const exclude = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/exclude`
);

export const include = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/include`
);

export const sendNotice = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/send-notice`
);

export const assume = (localUnitAuditId: number,
                       followUpRequest: FollowUpDto): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/assume`,
  {
    body: JSON.stringify(followUpRequest)
  }
);

export const rollback = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `/local-unit-audits/${localUnitAuditId}/rollback`
);

export const fail = (localUnitAuditId: number, followUpDto: FollowUpDto): Promise<LocalUnitAudit> => {
  return pa660Api.patchWithJsonResponse(
    `local-unit-audits/${localUnitAuditId}/fail`,
    {
      body: JSON.stringify(followUpDto)
    }
  )
};
