import * as Yup from 'yup';

import {assessmentRollAnalysisV2Schema, baseSchema, standardYesNoValidation} from './capBaseSchema';
import * as messages from '../../messages';

const assessmentTechnicalAnalysisSchema = Yup.object().shape({
  doesUseCamaDate: standardYesNoValidation('doesUseCama', true),
  doesUseCamaComments: standardYesNoValidation('doesUseCama'),
  question5ResolutionDate: Yup.date()
    .when([
      'hasPublishedPolicyDesignation',
      'hasPublishedPolicyEstimatedResponseTime',
      'hasPublishedPolicyInPersonMeetingInformation',
      'hasPublishedPolicyRequestsInformation',
      'hasPublishedPolicyDisputeInformation'
    ], {
      is: (hasPublishedPolicyDesignation: string,
           hasPublishedPolicyEstimatedResponseTime: string,
           hasPublishedPolicyInPersonMeetingInformation: string,
           hasPublishedPolicyRequestsInformation: string,
           hasPublishedPolicyDisputeInformation: string
      ) => hasPublishedPolicyDesignation === 'No' ||
        hasPublishedPolicyEstimatedResponseTime === 'No' ||
        hasPublishedPolicyInPersonMeetingInformation === 'No' ||
        hasPublishedPolicyRequestsInformation === 'No' ||
        hasPublishedPolicyDisputeInformation === 'No',
      then: Yup.date()
        .required(messages.REQUIRED)
    }),
  question5ResolutionComments: Yup.string()
    .when([
      'hasPublishedPolicyDesignation',
      'hasPublishedPolicyEstimatedResponseTime',
      'hasPublishedPolicyInPersonMeetingInformation',
      'hasPublishedPolicyRequestsInformation',
      'hasPublishedPolicyDisputeInformation'
    ], {
      is: (hasPublishedPolicyDesignation: string,
           hasPublishedPolicyEstimatedResponseTime: string,
           hasPublishedPolicyInPersonMeetingInformation: string,
           hasPublishedPolicyRequestsInformation: string,
           hasPublishedPolicyDisputeInformation: string
      ) => hasPublishedPolicyDesignation === 'No' ||
        hasPublishedPolicyEstimatedResponseTime === 'No' ||
        hasPublishedPolicyInPersonMeetingInformation === 'No' ||
        hasPublishedPolicyRequestsInformation === 'No' ||
        hasPublishedPolicyDisputeInformation === 'No',
      then: Yup.string()
        .required(messages.REQUIRED)
    }),
  publishedPolicyNotes: Yup.string(),
  doesProvideOnlineInformationDate: standardYesNoValidation('doesProvideOnlineInformation', true),
  doesProvideOnlineInformationComments: standardYesNoValidation('doesProvideOnlineInformation'),
  doesProvideContactInformationDate: standardYesNoValidation('doesProvideContactInformation', true),
  doesProvideContactInformationComments: standardYesNoValidation('doesProvideContactInformation'),
  doesTrainSupportStaffDate: standardYesNoValidation('doesTrainSupportStaff', true),
  doesTrainSupportStaffComments: standardYesNoValidation('doesTrainSupportStaff'),
  doesComplyWithFeeCollectionDate: standardYesNoValidation('doesComplyWithFeeCollection', true),
  doesComplyWithFeeCollectionComments: standardYesNoValidation('doesComplyWithFeeCollection'),
  doesConductCanvassDate: standardYesNoValidation('doesConductCanvass', true),
  doesConductCanvassComments: standardYesNoValidation('doesConductCanvass'),
  doesBoardReviewMeetRequirementsDate: standardYesNoValidation('doesBoardReviewMeetRequirements', true),
  doesBoardReviewMeetRequirementsComments: standardYesNoValidation('doesBoardReviewMeetRequirements'),
  doesHaveAdequateProcessDate: standardYesNoValidation('doesHaveAdequateProcess', true),
  doesHaveAdequateProcessComments: standardYesNoValidation('doesHaveAdequateProcess'),
  doesMeetSTCRequirementsDate: standardYesNoValidation('doesMeetSTCRequirements', true),
  doesMeetSTCRequirementsComments: standardYesNoValidation('doesMeetSTCRequirements'),
  reviewOfExemptionsGrantedComments: standardYesNoValidation('reviewOfExemptionsGranted'),
  hasImplementedCamaStandardsDate: standardYesNoValidation('hasImplementedCamaStandards', true),
  hasImplementedCamaStandardsComments: standardYesNoValidation('hasImplementedCamaStandards'),
  question14ResolutionDate: Yup.date()
    .when([
      'doesLocalUnitHavePovertyExemptionGuidelines',
      'doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest',
      'doesSamplingMeetRequirements'
    ], {
      is: (doesLocalUnitHavePovertyExemptionGuidelines: string,
           doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest: string,
           doesSamplingMeetRequirements: string
      ) => doesLocalUnitHavePovertyExemptionGuidelines === 'No' ||
        doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest === 'No' ||
        doesSamplingMeetRequirements === 'No',
      then: Yup.date()
        .required(messages.REQUIRED)
    }),
  question14ResolutionComments: Yup.string()
    .when([
      'doesLocalUnitHavePovertyExemptionGuidelines',
      'doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest',
      'doesSamplingMeetRequirements'
    ], {
      is: (doesLocalUnitHavePovertyExemptionGuidelines: string,
           doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest: string,
           doesSamplingMeetRequirements: string
      ) => doesLocalUnitHavePovertyExemptionGuidelines === 'No' ||
        doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest === 'No' ||
        doesSamplingMeetRequirements === 'No',
      then: Yup.string()
        .required(messages.REQUIRED)
    })
});

export default baseSchema.concat(
  Yup.object().shape({
    assessmentRollAnalysis: assessmentRollAnalysisV2Schema,
    assessmentTechnicalAnalysis: assessmentTechnicalAnalysisSchema
  })
);