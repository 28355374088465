import * as Yup from 'yup';
import {
  assessmentRollAnalysis,
  baseSchema,
  localUnitBackground,
  personalPropertyReview,
  reviewOfExemptionsGranted
} from './baseReviewSheetSchema';

export default baseSchema.concat(
  Yup.object().shape({
    localUnitBackground: localUnitBackground,
    assessmentRollAnalysis: assessmentRollAnalysis,
    personalPropertyReview: personalPropertyReview,
    reviewOfExemptionsGranted: reviewOfExemptionsGranted
  })
);