import {useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Alerts, MiFooter, NotFoundV2, SkipNav, useAlerts, useUserContext} from '@reasoncorp/kyber-js';

import './font-awesome-icons';
import * as messages from './messages';
import {useAppContext} from './hooks';
import {
  CapLocalUnitView,
  CapStateView,
  CountyAuditDetails,
  CountyAudits,
  FilingCabinet,
  LocalUnitAuditHistory,
  LocalUnitPortalAudits,
  ReviewSheetReasonView,
  ReviewSheetStateView
} from './containers';
import {Pa660AuditAppBar} from './components';
import {localUnitPermissionsApi} from './api';

const App = () => {
  const {currentUser, permissions, loadingUser} = useUserContext();
  const {showErrorAlert} = useAlerts();
  const {localUnitPermissions, setLocalUnitPermissions} = useAppContext();

  useEffect(() => {
      const loadData = async () => {
        try {
          const localUnitPermissions = await localUnitPermissionsApi.findAll();
          setLocalUnitPermissions(localUnitPermissions);
        } catch (error) {
          showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
        }
      };
      // Load local unit audit permissions if there is a change to the current user
      // and if they are a local unit user. Setting this here is a one time setup
      // that will allow all Local Unit Portal components to easier access this information
      // in order for them to make rendering decisions.
      if (permissions.isLocalUnitUser && !localUnitPermissions.length) {
        void loadData();
      }
    },
    [permissions, localUnitPermissions.length, setLocalUnitPermissions, showErrorAlert]
  );

  return (
    <>
      <SkipNav/>
      <Pa660AuditAppBar currentUser={currentUser}/>
      {!loadingUser &&
        <main role="main" id="content">
          <Routes>
            {/* Reason Portal Routes*/}
            {permissions.isReasonUser && <Route path="/" element={<Navigate replace to="/reason-portal"/>}/>}
            {permissions.isReasonUser && <Route path="/reason-portal" element={<CountyAudits portal="reason"/>}/>}
            {permissions.isReasonUser && <
              Route path="/reason-portal/county-audits/:countyAuditId/local-units/:localUnitId/:year/filing-cabinet"
                    element={<FilingCabinet portal="reason"/>}/>
            }
            {permissions.isReasonUser &&
              <Route path="/reason-portal/county-audits/:countyAuditId"
                     element={<CountyAuditDetails portal="reason"/>}/>
            }
            {permissions.isReasonUser &&
              <Route path="/reason-portal/county-audits/:countyAuditId/local-unit-audits/:localUnitAuditId/audit-history"
                     element={<LocalUnitAuditHistory portal="reason"/>}/>
            }
            {permissions.isReasonUser &&
              <Route path="/reason-portal/county-audits/:countyAuditId/local-unit-audits/:localUnitAuditId/review-sheet"
                     element={<ReviewSheetReasonView/>}/>
            }

            {/*State Portal Routes*/}
            {permissions.isStateUser && <Route path="/" element={<Navigate replace to="/state-portal"/>}/>}
            {permissions.isStateUser && <Route path="/state-portal" element={<CountyAudits portal="state"/>}/>}
            {permissions.isStateUser &&
              <Route path="/state-portal/county-audits/:countyAuditId/local-units/:localUnitId/:year/filing-cabinet"
                     element={<FilingCabinet portal="state"/>}/>
            }
            {permissions.isStateUser &&
              <Route path="/state-portal/county-audits/:countyAuditId"
                     element={<CountyAuditDetails portal="state"/>}/>
            }
            {permissions.isStateUser &&
              <Route path="/state-portal/county-audits/:countyAuditId/local-unit-audits/:localUnitAuditId/audit-history"
                     element={<LocalUnitAuditHistory portal="state"/>}/>
            }
            {permissions.isStateUser &&
              <Route path="/state-portal/county-audits/:countyAuditId/local-unit-audits/:localUnitAuditId/review-sheet"
                     element={<ReviewSheetStateView/>}/>
            }
            {permissions.isStateUser &&
              <Route path="/state-portal/county-audits/:countyAuditId/local-unit-audits/:localUnitAuditId/cap"
                     element={<CapStateView/>}/>
            }

            {/*Local Unit Portal Routes*/}
            {permissions.isLocalUnitUser && <Route path="/" element={<Navigate replace to="/local-unit-portal"/>}/>}
            {permissions.isLocalUnitAssessorOfRecord &&
              <Route path="/local-unit-portal/:localUnitId/:year/filing-cabinet"
                     element={<FilingCabinet portal="local-unit"/>}/>
            }
            {permissions.isLocalUnitUser &&
              <Route path="/local-unit-portal">
                <Route path=":localUnitId" element={<LocalUnitPortalAudits/>}/>
                <Route path="" element={<LocalUnitPortalAudits/>}/>
              </Route>
            }
            {permissions.isLocalUnitUser &&
              <Route path="/local-unit-portal/:localUnitId/local-unit-audits/:localUnitAuditId/audit-history"
                     element={<LocalUnitAuditHistory portal="local-unit"/>}/>
            }
            {permissions.isLocalUnitUser &&
              <Route path="/local-unit-portal/:localUnitId/local-unit-audits/:localUnitAuditId/cap"
                     element={<CapLocalUnitView/>}/>
            }
            <Route path="*" element={<NotFoundV2/>}/>
          </Routes>
        </main>
      }
      <MiFooter/>
      <Alerts/>
    </>
  );
};

export default App;