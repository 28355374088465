import {useFormikContext} from 'formik';
import {Col, Row} from 'reactstrap';

import {FormikDatePicker, FormikInput} from '@reasoncorp/kyber-js';

import {AssessmentProcedureAnalysisCard, AssessmentRollAnalysisV3Card} from '../shared';
import CapResolutionV3 from './CapResolutionV3';
import CapComments from './CapComments';
import {reviewSheetV3Questions} from '../../schema/reviewSheet';
import {ReviewSheetMultiQuestion} from '../reviewSheet';
import {CapV3FormFields} from '../../types/cap';

type Props = {
  formEditable: boolean
}

const CapV3Form = ({
                     formEditable
                   }: Props) => {
  const {values} = useFormikContext<CapV3FormFields>();

  const renderQuestion5 = () => {
    return values.assessmentTechnicalAnalysis.hasPublishedPolicyDesignation === 'No' ||
      values.assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime === 'No' ||
      values.assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation === 'No' ||
      values.assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation === 'No' ||
      values.assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation === 'No'
  }

  const renderQuestion14 = () => {
    return values.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines === 'No' ||
      values.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest === 'No' ||
      values.assessmentTechnicalAnalysis.doesSamplingMeetRequirements === 'No';
  }

  return (
    <div className="cap-v3">
      <AssessmentRollAnalysisV3Card className="mb-4"
                                    bodyClassName="cap-resolution-group">
        <CapResolutionV3 renderCondition={values.assessmentRollAnalysis.landValueDeterminationsAppropriate === 'No'}
                         radioFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriate"
                         radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.landValueDeterminationsAppropriate}
                         notesFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateNotes"
                         resolutionDateFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateDate"
                         resolutionCommentsFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateComments"
                         disabled={!formEditable}/>
        <CapResolutionV3 renderCondition={values.assessmentRollAnalysis.economicConditionFactorsMet === 'No'}
                         radioFieldName="assessmentRollAnalysis.economicConditionFactorsMet"
                         radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.economicConditionFactorsMet}
                         notesFieldName="assessmentRollAnalysis.economicConditionFactorsMetNotes"
                         resolutionDateFieldName="assessmentRollAnalysis.economicConditionFactorsMetDate"
                         resolutionCommentsFieldName="assessmentRollAnalysis.economicConditionFactorsMetComments"
                         disabled={!formEditable}/>
        <CapResolutionV3 renderCondition={values.assessmentRollAnalysis.cashValueAgrees === 'No'}
                         radioFieldName="assessmentRollAnalysis.cashValueAgrees"
                         radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.cashValueAgrees}
                         notesFieldName="assessmentRollAnalysis.cashValueAgreesNotes"
                         resolutionDateFieldName="assessmentRollAnalysis.cashValueAgreesDate"
                         resolutionCommentsFieldName="assessmentRollAnalysis.cashValueAgreesComments"
                         className="border-bottom-0"
                         disabled={!formEditable}
        />
      </AssessmentRollAnalysisV3Card>

      <AssessmentProcedureAnalysisCard className="mb-4">
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesUseCama"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesUseCama}
                         notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesUseCamaDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesUseCamaComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesUseCama === 'No'}/>
        <ReviewSheetMultiQuestion className="review-sheet-question-5"
                                  headerText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicy}
                                  renderCondition={renderQuestion5()}>
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDesignation"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyDesignation}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDesignationDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDesignationComments"
                           className="mb-2"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.hasPublishedPolicyDesignation === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTimeDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTimeComments"
                           className="mb-2"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformationDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformationComments"
                           className="mb-2"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />

          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformationDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformationComments"
                           className="mb-2"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />

          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformationDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformationComments"
                           className="mb-2"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3">
              <Row className="mb-4">
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.hasPublishedPolicyNotes"
                               labelText="Audit Notes"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={750}
                               aria-label="Audit notes"
                               disabled={true}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-2 pb-4 review-sheet-v3-question-row">
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row>
                <Col>
                  <Row className="mb-2">
                    <Col>
                      <FormikDatePicker name="assessmentTechnicalAnalysis.question5ResolutionDate"
                                        labelText="Resolution Date"
                                        aria-label="Resolution Date mm/dd/yyyy"
                                        formGroupClass="larger-label"
                                        wrapperClassName="w-auto"
                                        disabled={!formEditable}/>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.question5ResolutionComments"
                               labelText="Resolution Comments"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={650}
                               aria-label="Resolution Comments"
                               disabled={!formEditable}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </ReviewSheetMultiQuestion>
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformation"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesProvideOnlineInformation}
                         notesFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformationNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformationDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformationComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesProvideOnlineInformation === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesProvideContactInformation"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesProvideContactInformation}
                         notesFieldName="assessmentTechnicalAnalysis.doesProvideContactInformationNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesProvideContactInformationDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesProvideContactInformationComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesProvideContactInformation === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaff"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesTrainSupportStaff}
                         notesFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaffNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaffDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaffComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesTrainSupportStaff === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollection"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesComplyWithFeeCollection}
                         notesFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollectionNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollectionDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollectionComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesComplyWithFeeCollection === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesConductCanvass"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesConductCanvass}
                         notesFieldName="assessmentTechnicalAnalysis.doesConductCanvassNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesConductCanvassDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesConductCanvassComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesConductCanvass === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirements"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesBoardReviewMeetRequirements}
                         notesFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirementsNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirementsDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirementsComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesBoardReviewMeetRequirements === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcess"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesHaveAdequateProcess}
                         notesFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcessNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcessDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcessComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesHaveAdequateProcess === 'No'}
        />
        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirements"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesMeetSTCRequirements}
                         notesFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirementsNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirementsDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirementsComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.doesMeetSTCRequirements === 'No'}
        />

        <ReviewSheetMultiQuestion className="review-sheet-question-14"
                                  headerText={reviewSheetV3Questions.assessmentTechnicalAnalysis.reviewOfExemptionsGranted}
                                  renderCondition={renderQuestion14()}>
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesComments"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTestDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTestComments" disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.doesSamplingMeetRequirements"
                           radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesSamplingMeetRequirements}
                           notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                           resolutionDateFieldName="assessmentTechnicalAnalysis.doesSamplingMeetRequirementsDate"
                           resolutionCommentsFieldName="assessmentTechnicalAnalysis.doesSamplingMeetRequirementsComments"
                           disabled={!formEditable}
                           renderCondition={values.assessmentTechnicalAnalysis.doesSamplingMeetRequirements === 'No'}
                           hasNotes={false}
                           showDate={false}
                           showComments={false}
                           isMultiQuestion={true}
          />
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3">
              <Row className="mb-4">
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.reviewOfExemptionsGrantedNotes"
                               labelText="Audit Notes"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={750}
                               aria-label="Review of Exemptions Granted Notes"
                               disabled={true}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 pb-4 review-sheet-v3-question-row">
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row>
                <Col>
                  <Row className="mb-2">
                    <Col>
                      <FormikDatePicker name="assessmentTechnicalAnalysis.question14ResolutionDate"
                                        labelText="Resolution Date"
                                        aria-label="Resolution Date mm/dd/yyyy"
                                        formGroupClass="larger-label"
                                        wrapperClassName="w-auto"
                                        disabled={!formEditable}/>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.question14ResolutionComments"
                               labelText="Resolution Comments"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={650}
                               aria-label="Resolution Comments"
                               disabled={!formEditable}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </ReviewSheetMultiQuestion>

        <CapResolutionV3 radioFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandards"
                         radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasImplementedCamaStandards}
                         notesFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandardsNotes"
                         resolutionDateFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandardsDate"
                         resolutionCommentsFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandardsComments"
                         disabled={!formEditable}
                         renderCondition={values.assessmentTechnicalAnalysis.hasImplementedCamaStandards === 'No'}
        />
      </AssessmentProcedureAnalysisCard>

      <CapComments formEditable={formEditable}/>
    </div>
  );
};

export default CapV3Form;