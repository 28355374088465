import * as Yup from 'yup';

import * as messages from '../../messages';
import {
  assessmentRollAnalysis,
  baseSchema,
  localUnitBackground,
  personalPropertyReview,
  reviewOfExemptionsGranted
} from './baseReviewSheetSchema';

// Only a single question was added to this section in version 2
const reviewOfExemptionsGrantedV2 = reviewOfExemptionsGranted.concat(
  Yup.object().shape({
    implementedCamaDataStandards: Yup.string()
      .required(messages.REQUIRED),
    implementedCamaDataStandardsNotes: Yup.string()
  })
);

export default baseSchema.concat(
  Yup.object().shape({
    localUnitBackground: localUnitBackground,
    assessmentRollAnalysis: assessmentRollAnalysis,
    personalPropertyReview: personalPropertyReview,
    reviewOfExemptionsGranted: reviewOfExemptionsGrantedV2
  })
);