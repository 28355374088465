import {ReviewSheet} from '../types/reviewSheet';
import {LocalUnitAudit} from '../types';
import {pa660Api} from './apiUtils';

export const save = (localUnitAuditId: number,
                     reviewSheet: ReviewSheet): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet`,
  {
    body: JSON.stringify(reviewSheet)
  }
);

export const sendToReview = (localUnitAuditId: number,
                             reviewSheet: ReviewSheet): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet/send-to-review`,
  {
    body: JSON.stringify(reviewSheet)
  }
);

export const returnReviewSheet = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet/return`
);

export const markReviewed = (localUnitAuditId: number,
                             reviewSheet: ReviewSheet): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet/mark-reviewed`,
  {
    body: JSON.stringify(reviewSheet)
  }
);

export const accept = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet/accept`
);

export const acceptWithTechnicalIssues = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/review-sheet/accept/with-technical-issues`
);

export const generateDraftPdf = (localUnitAuditId: number, reviewSheet: ReviewSheet): Promise<string> => pa660Api.postWithTextResponse(
  `/local-unit-audits/${localUnitAuditId}/review-sheet/draft`,
  {
    body: JSON.stringify(reviewSheet)
  }
);