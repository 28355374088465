import {AnyObjectSchema} from 'yup';

import {ReviewSheet, ReviewSheetFormFields, ReviewSheetV1, ReviewSheetV2, ReviewSheetV3} from '../../types/reviewSheet';
import reviewSheetV1Utils from './reviewSheetV1Utils';
import reviewSheetV1Schema from './reviewSheetV1Schema';
import reviewSheetV2Utils from './reviewSheetV2Utils';
import reviewSheetV2Schema from './reviewSheetV2Schema';
import reviewSheetV3Schema from './reviewSheetV3Schema';
import reviewSheetV3Utils from './reviewSheetV3Utils';

const getInitialValues = (reviewSheet: ReviewSheet): ReviewSheetFormFields => {
  switch (reviewSheet.version as string) {
    case 'v1':
      return reviewSheetV1Utils.getInitialValues(reviewSheet as ReviewSheetV1);
    case 'v2':
      return reviewSheetV2Utils.getInitialValues(reviewSheet as ReviewSheetV2);
    case 'v3':
      return reviewSheetV3Utils.getInitialValues(reviewSheet as ReviewSheetV3);
    default:
      throw new Error(`Review sheet ${reviewSheet.version} is not supported`);
  }
};

const getValidationSchema = (reviewSheet: ReviewSheet): AnyObjectSchema => {
  switch (reviewSheet.version) {
    case 'v1':
      return reviewSheetV1Schema;
    case 'v2':
      return reviewSheetV2Schema;
    case 'v3':
      return reviewSheetV3Schema;
    default:
      throw new Error(`Review sheet ${reviewSheet} is not supported`);
  }
};

const getScrubbedValues = (reviewSheet: ReviewSheet): ReviewSheet => {
  switch (reviewSheet.version as string) {
    case 'v1':
      return reviewSheetV1Utils.scrubSubmissionValues(reviewSheet as ReviewSheetV1);
    case 'v2':
      return reviewSheetV2Utils.scrubSubmissionValues(reviewSheet as ReviewSheetV2);
    case 'v3':
      return reviewSheetV3Utils.scrubSubmissionValues(reviewSheet as ReviewSheetV3);
    default:
      throw new Error(`Review sheet ${reviewSheet.version} is not supported`);
  }
};

const reviewSheetUtils = {
  getInitialValues,
  getValidationSchema,
  getScrubbedValues
};

export default reviewSheetUtils;