import {AnyObjectSchema} from 'yup';

import {ReviewSheet, ReviewSheetV1, ReviewSheetV2, ReviewSheetV3} from '../../types/reviewSheet';
import {Cap, CapFormFields, CapV1, CapV2, CapV3} from '../../types/cap';
import capV1Utils from './capV1Utils';
import capV1Schema from './capV1Schema';
import capV2Utils from './capV2Utils';
import capV2Schema from './capV2Schema';
import capV3Schema from './capV3Schema';
import capV3Utils from './capV3Utils';

const getInitialValues = (reviewSheet: ReviewSheet, cap: Cap): CapFormFields => {
  switch (reviewSheet.version as string) {
    case 'v1':
      return capV1Utils.getInitialValues(reviewSheet as ReviewSheetV1, cap as CapV1);
    case 'v2':
      return capV2Utils.getInitialValues(reviewSheet as ReviewSheetV2, cap as CapV2);
    case 'v3':
      return capV3Utils.getInitialValues(reviewSheet as ReviewSheetV3, cap as CapV3);
    default:
      throw new Error(`CAP ${reviewSheet.version} is not supported`);
  }
};

const getValidationSchema = (reviewSheet: ReviewSheet): AnyObjectSchema => {
  switch (reviewSheet.version as string) {
    case 'v1':
      return capV1Schema;
    case 'v2':
      return capV2Schema;
    case 'v3':
      return capV3Schema;
    default:
      throw new Error(`CAP ${reviewSheet.version} is not supported`);
  }
};

const capUtils = {
  getInitialValues,
  getValidationSchema
};

export default capUtils;