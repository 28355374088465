import {Col, Row} from 'reactstrap';
import {get} from 'lodash';

import {FormikInput, FormikRadioGroup} from '@reasoncorp/kyber-js';

import {ReviewSheet} from '../../types/reviewSheet';
import {useMemo} from 'react';

type Props = {
  radioFieldName: string
  radioLabelText: string
  radioButtons?: {value: string, labelText: string}[]
  notesFieldName?: string
  notesLabelText?: string
  notesMaxLength?: 250 | 500 | 750
  notesAriaLabel?: string
  formEditable: boolean
  isFollowUpAudit?: boolean
  previousReviewSheet?: ReviewSheet
  hasNotes?: boolean
}
const ReviewSheetQuestion = ({
                               radioFieldName,
                               radioLabelText,
                               radioButtons = [
                                 {value: 'Yes', labelText: 'Yes'},
                                 {value: 'No', labelText: 'No'}
                               ],
                               notesFieldName = '',
                               notesLabelText = '',
                               notesMaxLength,
                               notesAriaLabel,
                               formEditable,
                               isFollowUpAudit,
                               previousReviewSheet,
                               hasNotes = true
                             }: Props) => {
  // As questions get added and new review sheet versions get created, this could be easy to set incorrectly
  if (isFollowUpAudit && previousReviewSheet === undefined) {
    throw new Error('previousReviewSheet cannot be undefined for a follow up audit');
  }

  const disabled = useMemo(() => {
    return !formEditable || (isFollowUpAudit && get(previousReviewSheet, radioFieldName) === 'Yes');
  }, [
    formEditable,
    isFollowUpAudit,
    previousReviewSheet,
    radioFieldName
  ]);

  return (
    <>
      <Row>
        <Col>
          <FormikRadioGroup name={radioFieldName}
                            labelText={radioLabelText}
                            formGroupClass="larger-label"
                            inline
                            radioButtons={radioButtons}
                            disabled={disabled}/>
        </Col>
      </Row>
      {hasNotes && <Row>
        <Col>
          <FormikInput name={notesFieldName}
                       labelText={notesLabelText}
                       type="textarea"
                       formGroupClass="larger-label"
                       maxLength={notesMaxLength}
                       aria-label={notesAriaLabel}
                       disabled={disabled}/>
        </Col>
      </Row>}
    </>
  );
};

export default ReviewSheetQuestion;