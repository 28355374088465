const capV1Resolutions = {
  assessmentRollAnalysis: {
    economicConditionFactorsMetResolutions: [
      'ECF’s will be property calculated, documented and applied as calculated.',
      'ECF’s will be developed and applied for each property classification. Data from surrounding communities will ' +
      'be used if sales data is not available in the local unit.'
    ],
    landValueDeterminationsAppropriateResolutions: [
      'Land Value Determinations will be property calculated, documented and applied as calculated.',
      'Land Value Determinations will be developed and applied for each property classification. Data from ' +
      'surrounding communities will be used if sales data is not available in the local unit.',
      'Land Adjustments without reason will be corrected.',
      'Land Value Determinations will be developed and applied for each property classification. Data from ' +
      'surrounding communities will be used if sales data is not available in the local unit. Also, land ' +
      'adjustments without reason will be corrected.'
    ],
    cashValueAgreesResolutions: [
      'Overrides will be removed.',
      'Flat land values will be removed.',
      'Flat land values will be properly documented but will be less than 1%.',
      'Overrides will be removed and flat land values will be properly documented but will be less than 1%.'
    ]
  }
};

export default capV1Resolutions;