import * as Yup from 'yup';

import * as messages from '../../messages';
import {LocalUnitAuditAction} from '../../enum';
import {assessmentRollAnalysis} from './baseReviewSheetSchema';

const localUnitBackground = Yup.object().shape({
  assessmentRollCertificationDate: Yup.date()
    .required(messages.REQUIRED).nullable(),
  didAssessorReceiveAssistance: Yup.string()
    .required(messages.REQUIRED),
  didAssessorReceiveAssistanceNotes: Yup.string()
    .when('didAssessorReceiveAssistance', {
      is: (didAssessorReceiveAssistance: string) => didAssessorReceiveAssistance === 'Yes',
      then: Yup.string()
        .required(messages.REQUIRED),
      otherwise: Yup.string()
    })
});

const assessmentTechnicalAnalysis = Yup.object().shape({
  doesUseCama: Yup.string()
    .required(messages.REQUIRED),
  doesUseCamaNotes: Yup.string(),
  hasPublishedPolicyNotes: Yup.string(),
  hasPublishedPolicyDesignation: Yup.string()
    .required(messages.REQUIRED),
  hasPublishedPolicyEstimatedResponseTime: Yup.string()
    .required(messages.REQUIRED),
  hasPublishedPolicyInPersonMeetingInformation: Yup.string()
    .required(messages.REQUIRED),
  hasPublishedPolicyRequestsInformation: Yup.string()
    .required(messages.REQUIRED),
  hasPublishedPolicyDisputeInformation: Yup.string(),
  doesProvideOnlineInformation: Yup.string(),
  doesProvideOnlineInformationNotes: Yup.string(),
  doesProvideContactInformation: Yup.string()
    .required(messages.REQUIRED),
  doesProvideContactInformationNotes: Yup.string(),
  doesTrainSupportStaff: Yup.string()
    .required(messages.REQUIRED),
  doesTrainSupportStaffNotes: Yup.string(),
  doesComplyWithFeeCollection: Yup.string()
    .required(messages.REQUIRED),
  doesComplyWithFeeCollectionNotes: Yup.string(),
  doesConductCanvass: Yup.string()
    .required(messages.REQUIRED),
  doesConductCanvassNotes: Yup.string(),
  doesBoardReviewMeetRequirements: Yup.string()
    .required(messages.REQUIRED),
  doesBoardReviewMeetRequirementsNotes: Yup.string(),
  doesHaveAdequateProcess: Yup.string()
    .required(messages.REQUIRED),
  doesHaveAdequateProcessNotes: Yup.string(),
  doesMeetSTCRequirements: Yup.string()
    .required(messages.REQUIRED),
  doesMeetSTCRequirementsNotes: Yup.string(),
  wasAuditorMadeAware: Yup.string()
    .required(messages.REQUIRED),
  wasAuditorMadeAwareNotes: Yup.string(),
  reviewOfExemptionsGrantedNotes: Yup.string(),
  didLocalUnitGrantPovertyExemptions: Yup.string()
    .required(messages.REQUIRED),
  doesLocalUnitHavePovertyExemptionGuidelines: Yup.string()
    .required(messages.REQUIRED),
  doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest: Yup.string()
    .required(messages.REQUIRED),
  doesSamplingMeetRequirements: Yup.string()
    .when('didLocalUnitGrantPovertyExemptions', {
      is: (didLocalUnitGrantPovertyExemptions: string) => didLocalUnitGrantPovertyExemptions === 'Yes',
      then: Yup.string()
        .required(messages.REQUIRED),
      otherwise: Yup.string()
    }),
  hasImplementedCamaStandards: Yup.string()
    .required(messages.REQUIRED),
  hasImplementedCamaStandardsNotes: Yup.string()
});

const reviewSheetV3Schema = Yup.object().shape({
  saveAction: Yup.string().oneOf([
    LocalUnitAuditAction.REVIEW_SHEET_SAVE,
    LocalUnitAuditAction.REVIEW_SHEET_SEND_TO_REVIEW,
    LocalUnitAuditAction.REVIEW_SHEET_RETURN,
    LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED
  ]),
  version: Yup.string()
    .required(),
  localUnitBackground: localUnitBackground,
  assessmentRollAnalysis: assessmentRollAnalysis,
  assessmentTechnicalAnalysis: assessmentTechnicalAnalysis,
  comments: Yup.string(),
  signature: Yup.object()
    .when('saveAction', {
      is: (saveAction: LocalUnitAuditAction) => saveAction === LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED,
      then: Yup.object().shape({
        name: Yup.string()
          .required(messages.REQUIRED),
        date: Yup.date()
          .required(messages.REQUIRED)
          .nullable(),
        attestationConfirmation: Yup.boolean()
          .oneOf([true], messages.REQUIRED)
      }),
      otherwise: Yup.object().shape({
        name: Yup.string(),
        date: Yup.date()
          .nullable(),
        attestationConfirmation: Yup.boolean()
      })
    })
});

export default reviewSheetV3Schema;