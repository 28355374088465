import {Col, Row} from 'reactstrap';

import {FormikDatePicker, FormikInput, useUserContext} from '@reasoncorp/kyber-js';

import {LocalUnitAudit, LocalUnitContactInfo} from '../../types';
import {reviewSheetV3Questions} from '../../schema/reviewSheet';
import {AssessmentProcedureAnalysisCard, AssessmentRollAnalysisV3Card} from '../shared';
import ReviewSheetComments from './ReviewSheetComments';
import ReviewSheetQuestion from './ReviewSheetQuestion';
import LocalUnitBackgroundV3Card from './LocalUnitBackgroundV3Card';
import ReviewSheetCertificationCard from './ReviewSheetCertificationCard';
import ReviewSheetMultiQuestion from './ReviewSheetMultiQuestion';

type Props = {
  showContactInfo?: boolean
  formEditable: boolean
  localUnitAudit: LocalUnitAudit
  localUnitContactInfo?: LocalUnitContactInfo
}

const ReviewSheetV3Form = ({
                             showContactInfo = false,
                             formEditable,
                             localUnitAudit,
                             localUnitContactInfo
                           }: Props) => {
  const {permissions} = useUserContext();

  return (
    <div className="review-sheet-v3">
      <LocalUnitBackgroundV3Card className="mb-4"
                                 showContactInfo={showContactInfo}
                                 localUnitAudit={localUnitAudit}
                                 localUnitContactInfo={localUnitContactInfo}>
        <Row className="mb-4 pb-4 review-sheet-v3-question-row">
          <Col>
            <FormikDatePicker name="localUnitBackground.assessmentRollCertificationDate"
                              labelText={reviewSheetV3Questions.localUnitBackground.assessmentRollCertificationDate}
                              aria-label={`${reviewSheetV3Questions.localUnitBackground.assessmentRollCertificationDate} mm/dd/yyyy`}
                              formGroupClass="larger-label"
                              wrapperClassName="w-auto"
                              disabled={!formEditable}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <ReviewSheetQuestion radioFieldName="localUnitBackground.didAssessorReceiveAssistance"
                                 radioLabelText={reviewSheetV3Questions.localUnitBackground.didAssessorReceiveAssistance}
                                 notesFieldName="localUnitBackground.didAssessorReceiveAssistanceNotes"
                                 notesLabelText={reviewSheetV3Questions.localUnitBackground.didAssessorReceiveAssistanceNotes}
                                 notesMaxLength={500}
                                 notesAriaLabel="Did assessor receive assistance notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
      </LocalUnitBackgroundV3Card>

      <AssessmentRollAnalysisV3Card className="mb-4">
        <Row className="review-sheet-question-1 mb-4 pb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriate"
                                 radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.landValueDeterminationsAppropriate}
                                 notesFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Land Value Determinations Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-2 mb-4 pb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.economicConditionFactorsMet"
                                 radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.economicConditionFactorsMet}
                                 notesFieldName="assessmentRollAnalysis.economicConditionFactorsMetNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Economic Condition Factors Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-3">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.cashValueAgrees"
                                 radioLabelText={reviewSheetV3Questions.assessmentRollAnalysis.cashValueAgrees}
                                 notesFieldName="assessmentRollAnalysis.cashValueAgreesNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Cash Value Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
      </AssessmentRollAnalysisV3Card>

      <AssessmentProcedureAnalysisCard className="mb-4">
        <Row className="review-sheet-question-4 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesUseCama"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesUseCama}
                                 notesFieldName="assessmentTechnicalAnalysis.doesUseCamaNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Uses CAMA Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <ReviewSheetMultiQuestion className="review-sheet-question-5"
                                  headerText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicy}>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDesignation"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyDesignation}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyEstimatedResponseTime}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyInPersonMeetingInformation}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyRequestsInformation}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-4">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasPublishedPolicyDisputeInformation}
                                       formEditable={formEditable}
                                       hasNotes={false}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-4 pb-4 review-sheet-v3-question-row">
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-4">
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.hasPublishedPolicyNotes"
                               labelText="Notes"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={750}
                               aria-label="Published policy notes"
                               disabled={!formEditable}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </ReviewSheetMultiQuestion>
        <Row className="review-sheet-question-6 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformation"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesProvideOnlineInformation}
                                 notesFieldName="assessmentTechnicalAnalysis.doesProvideOnlineInformationNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Online Information notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-7 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesProvideContactInformation"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesProvideContactInformation}
                                 notesFieldName="assessmentTechnicalAnalysis.doesProvideContactInformationNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Provided Contact Information Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-8 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaff"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesTrainSupportStaff}
                                 notesFieldName="assessmentTechnicalAnalysis.doesTrainSupportStaffNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Support Staff Training Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-9 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollection"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesComplyWithFeeCollection}
                                 notesFieldName="assessmentTechnicalAnalysis.doesComplyWithFeeCollectionNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Complies with fee collection Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-10 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesConductCanvass"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesConductCanvass}
                                 notesFieldName="assessmentTechnicalAnalysis.doesConductCanvassNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Personal Property Canvass Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-11 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirements"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesBoardReviewMeetRequirements}
                                 notesFieldName="assessmentTechnicalAnalysis.doesBoardReviewMeetRequirementsNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Board Review meets Requirements Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-12 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcess"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesHaveAdequateProcess}
                                 notesFieldName="assessmentTechnicalAnalysis.doesHaveAdequateProcessNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Adequate Process Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-13 mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirements"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesMeetSTCRequirements}
                                 notesFieldName="assessmentTechnicalAnalysis.doesMeetSTCRequirementsNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="STC Requirement Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <Row className="review-sheet-question-13a mb-4 review-sheet-v3-question-row">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.wasAuditorMadeAware"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.wasAuditorMadeAware}
                                 notesFieldName="assessmentTechnicalAnalysis.wasAuditorMadeAwareNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Auditor made aware Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
        <ReviewSheetMultiQuestion className="review-sheet-question-14"
                                  headerText={reviewSheetV3Questions.assessmentTechnicalAnalysis.reviewOfExemptionsGranted}>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.didLocalUnitGrantPovertyExemptions"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.didLocalUnitGrantPovertyExemptions}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelines}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-2">
                <Col>
                  <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.doesSamplingMeetRequirements"
                                       radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.doesSamplingMeetRequirements}
                                       hasNotes={false}
                                       formEditable={formEditable}
                                       isFollowUpAudit={localUnitAudit.followUp}
                                       previousReviewSheet={localUnitAudit.previousReviewSheet}/>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-4 pb-4 review-sheet-v3-question-row">
            <Col className="mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item">
              <Row className="mb-4">
                <Col>
                  <FormikInput name="assessmentTechnicalAnalysis.reviewOfExemptionsGrantedNotes"
                               labelText="Notes"
                               type="textarea"
                               formGroupClass="larger-label"
                               maxLength={750}
                               aria-label="Review of Exemptions Granted Notes"
                               disabled={!formEditable}/>
                </Col>
              </Row>
            </Col>
          </Row>
        </ReviewSheetMultiQuestion>

        <Row className="review-sheet-question-15">
          <Col>
            <ReviewSheetQuestion radioFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandards"
                                 radioLabelText={reviewSheetV3Questions.assessmentTechnicalAnalysis.hasImplementedCamaStandards}
                                 notesFieldName="assessmentTechnicalAnalysis.hasImplementedCamaStandardsNotes"
                                 notesLabelText="Notes"
                                 notesMaxLength={750}
                                 notesAriaLabel="Implemented Cama Standards Notes"
                                 formEditable={formEditable}
                                 isFollowUpAudit={localUnitAudit.followUp}
                                 previousReviewSheet={localUnitAudit.previousReviewSheet}/>
          </Col>
        </Row>
      </AssessmentProcedureAnalysisCard>

      <ReviewSheetComments formEditable={formEditable}
                           permissions={permissions}/>

      <ReviewSheetCertificationCard formEditable={formEditable}
                                    permissions={permissions}/>
    </div>
  );
};

export default ReviewSheetV3Form;