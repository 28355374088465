import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BreadcrumbsNav, ProgressIndicator, useAlerts, useUserContext, windowUtils} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {localUnitAuditApi, reviewSheetApi} from '../../api';
import {ReviewSheetV1Form, ReviewSheetV2Form, ReviewSheetV3Form} from '../../components/reviewSheet';
import {ReviewSheetFormFields} from '../../types/reviewSheet';
import {LocalUnitAudit} from '../../types';
import {LocalUnitAuditAction, LocalUnitAuditStatus} from '../../enum';
import {reviewSheetUtils} from '../../schema/reviewSheet';

const ReviewSheetReasonView = () => {
  const navigate = useNavigate();
  const params = useParams() as {countyAuditId: string, localUnitAuditId: string};
  const [countyAuditId, localUnitAuditId] = useMemo(() => ([
    parseInt(params.countyAuditId),
    parseInt(params.localUnitAuditId)
  ]), [params]);
  const {permissions} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnitAudit, setLocalUnitAudit] = useState<LocalUnitAudit | undefined>(undefined);
  const [localUnitContactInfo, setLocalUnitContactInfo] = useState({});
  const saveAllowed = useMemo(() => {
    return localUnitAudit?.rawStatus === LocalUnitAuditStatus.NOT_STARTED ||
      localUnitAudit?.rawStatus === LocalUnitAuditStatus.REVIEW_SHEET_IN_PROGRESS ||
      ((permissions.isAuditSupport || permissions.isBranchManager) && localUnitAudit?.rawStatus === LocalUnitAuditStatus.REVIEW_SHEET_IN_REVIEW) ||
      localUnitAudit?.rawStatus === LocalUnitAuditStatus.REVIEW_SHEET_RETURNED;
  }, [
    localUnitAudit?.rawStatus,
    permissions
  ]);

  const sendToReviewAllowed = useMemo(() => {
    return saveAllowed && localUnitAudit?.rawStatus !== LocalUnitAuditStatus.REVIEW_SHEET_IN_REVIEW;
  }, [
    saveAllowed,
    localUnitAudit?.rawStatus
  ]);

  const returnAllowed = useMemo(() => {
    return saveAllowed &&
      (permissions.isAuditSupport || permissions.isBranchManager) &&
      localUnitAudit?.rawStatus === LocalUnitAuditStatus.REVIEW_SHEET_IN_REVIEW;
  }, [
    saveAllowed,
    permissions,
    localUnitAudit?.rawStatus
  ]);

  const reviewedAllowed = useMemo(() => {
    return saveAllowed && (permissions.isAuditSupport || permissions.isBranchManager);
  }, [
    saveAllowed,
    permissions
  ]);

  const breadcrumbs = useMemo(() => ([{
    text: 'Dashboard',
    icon: 'home' as IconProp,
    route: `/reason-portal`
  }, {
    text: `${localUnitAudit?.county?.displayNameWithType} Local Units`,
    route: `/reason-portal/county-audits/${countyAuditId}`
  }, {
    text: `${localUnitAudit?.localUnit?.displayNameWithType} Assessment Roll Audit`,
    active: true
  }]), [
    localUnitAudit,
    countyAuditId
  ]);

  const handleViewPdf = useCallback(async (formikProps: FormikProps<ReviewSheetFormFields>) => {
    await windowUtils.openFileInNewWindow(
      reviewSheetApi.generateDraftPdf(localUnitAuditId, reviewSheetUtils.getScrubbedValues(formikProps.values)),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [
    localUnitAuditId,
    showErrorAlert
  ]);

  const handlePreSubmit = useCallback(async (action: LocalUnitAuditAction,
                                             formikProps: FormikProps<ReviewSheetFormFields>) => {
    // wait for setFieldValue promise to complete, so validate form has the latest values
    await formikProps.setFieldValue('saveAction', action);
    const errors = await formikProps.validateForm();
    if (Object.keys(errors).length > 0) {
      showErrorAlert(messages.REVIEW_SHEET_FORM_INVALID);
    }
  }, [
    showErrorAlert
  ]);

  const handleReviewSheetSubmit = useCallback(async (values: ReviewSheetFormFields,
                                                     formikHelpers: FormikHelpers<ReviewSheetFormFields>) => {
    let successMessage = '', errorMessage = '', localUnitAudit;
    try {
      const valuesToSubmit = reviewSheetUtils.getScrubbedValues(values);
      switch (values.saveAction) {
        case LocalUnitAuditAction.REVIEW_SHEET_SAVE:
          successMessage = messages.REVIEW_SHEET_SAVE_SUCCESSFUL;
          errorMessage = messages.REVIEW_SHEET_SAVE_FAILURE;
          localUnitAudit = await reviewSheetApi.save(localUnitAuditId, valuesToSubmit);
          break;
        case LocalUnitAuditAction.REVIEW_SHEET_SEND_TO_REVIEW:
          successMessage = messages.REVIEW_SHEET_SEND_TO_REVIEW_SUCCESSFUL;
          errorMessage = messages.REVIEW_SHEET_SEND_TO_REVIEW_FAILURE;
          localUnitAudit = await reviewSheetApi.sendToReview(localUnitAuditId, valuesToSubmit);
          break;
        case LocalUnitAuditAction.REVIEW_SHEET_RETURN:
          successMessage = messages.REVIEW_SHEET_RETURN_SUCCESSFUL;
          errorMessage = messages.REVIEW_SHEET_RETURN_FAILURE;
          localUnitAudit = await reviewSheetApi.returnReviewSheet(localUnitAuditId);
          break;
        case LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED:
          successMessage = messages.REVIEW_SHEET_REVIEWED_SUCCESSFUL;
          errorMessage = messages.REVIEW_SHEET_REVIEWED_FAILURE;
          localUnitAudit = await reviewSheetApi.markReviewed(localUnitAuditId, valuesToSubmit);
          break;
        default:
          // Major bug if we ever get here
          formikHelpers.setSubmitting(false);
          showErrorAlert(values.saveAction + ' not a valid save action');
      }

      if (values.saveAction !== LocalUnitAuditAction.REVIEW_SHEET_SAVE) {
        // Show alert and then return to list of local unit audits
        showSuccessAlert(successMessage);
        navigate(`/reason-portal/county-audits/${countyAuditId}`);
      } else {
        formikHelpers.setSubmitting(false);
        setLocalUnitAudit(localUnitAudit);
        showSuccessAlert(successMessage);
      }
    } catch (error) {
      formikHelpers.setSubmitting(false);
      showErrorAlert(errorMessage);
    }
  }, [
    countyAuditId,
    localUnitAuditId,
    navigate,
    showErrorAlert,
    showSuccessAlert
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [localUnitAudit, localUnitContactInfo] = await Promise.all([
          localUnitAuditApi.findById(localUnitAuditId),
          localUnitAuditApi.contactInfo(localUnitAuditId)
        ]);
        // If user directly enters URL and the audit is done send them back to previous breadcrumb
        if (localUnitAudit.completed) {
          navigate(`/reason-portal/county-audits/${countyAuditId}`);
        } else {
          setLocalUnitAudit(localUnitAudit);
          setLocalUnitContactInfo(localUnitContactInfo);
          setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
        }
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert]);

  const handleCancel = useCallback(() => {
    navigate(`/reason-portal/county-audits/${countyAuditId}`);
  }, [
    navigate,
    countyAuditId
  ]);

  const handleAction = useCallback(async (localUnitAuditAction: LocalUnitAuditAction,
                                          formikProps: FormikProps<ReviewSheetFormFields>) => {
    if (localUnitAuditAction === LocalUnitAuditAction.REVIEW_SHEET_SAVE) {
      const valuesToSubmit = reviewSheetUtils.getScrubbedValues(formikProps.values);
      const successMessage = messages.REVIEW_SHEET_SAVE_SUCCESSFUL;
      const errorMessage = messages.REVIEW_SHEET_SAVE_FAILURE;
      await reviewSheetApi.save(localUnitAuditId, valuesToSubmit);

      try {
        formikProps.setSubmitting(false);
        showSuccessAlert(successMessage);
      } catch (error) {
        formikProps.setSubmitting(false);
        showErrorAlert(errorMessage);
      }
      return;
    }

    await handlePreSubmit(localUnitAuditAction, formikProps);
    await formikProps.submitForm();
  }, [
    handlePreSubmit,
    localUnitAuditId,
    showErrorAlert,
    showSuccessAlert
  ]);

  /* Update this code as new versions of the review sheet come about */
  const ReviewSheetCard = useMemo(() => () => {
    if (!localUnitAudit) {
      return null;
    } else if (localUnitAudit.reviewSheet.version === 'v1') {
      return <ReviewSheetV1Form showContactInfo
                                formEditable={saveAllowed}
                                localUnitAudit={localUnitAudit}
                                localUnitContactInfo={localUnitContactInfo}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v2') {
      return <ReviewSheetV2Form showContactInfo
                                formEditable={saveAllowed}
                                localUnitAudit={localUnitAudit}
                                localUnitContactInfo={localUnitContactInfo}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v3') {
      return <ReviewSheetV3Form showContactInfo
                                formEditable={saveAllowed}
                                localUnitAudit={localUnitAudit}
                                localUnitContactInfo={localUnitContactInfo}/>;
    } else {
      return null;
    }
  }, [
    saveAllowed,
    localUnitAudit,
    localUnitContactInfo
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="CountyAuditDetails">
        {(loadingState.loading || !localUnitAudit) && <ProgressIndicator/>}
        {!loadingState.loading && localUnitAudit && <>
          <Formik initialValues={reviewSheetUtils.getInitialValues(localUnitAudit.reviewSheet)}
                  validateOnMount={true}
                  validationSchema={reviewSheetUtils.getValidationSchema(localUnitAudit.reviewSheet)}
                  onSubmit={handleReviewSheetSubmit}>
            {(formikProps) => (
              <Form autoComplete="off">
                <Row className="mb-3">
                  <Col md="6">
                    <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <Button color="primary"
                            aria-label="View PDF"
                            onClick={() => handleViewPdf(formikProps)}>
                      View PDF
                    </Button>
                  </Col>
                </Row>
                <ReviewSheetCard />
                <Row className="pt-4">
                  <Col className="d-flex justify-content-end">
                    <Button className="mr-1"
                            color="secondary"
                            onClick={handleCancel}
                            aria-label="Cancel">
                      Cancel
                    </Button>
                    <Button className="mr-1"
                            color="success"
                            onClick={() => handleAction(LocalUnitAuditAction.REVIEW_SHEET_SAVE, formikProps)}
                            aria-label="Save Review Sheet"
                            disabled={!formikProps.dirty || formikProps.isSubmitting || !saveAllowed}>
                      Save
                    </Button>
                    {(permissions.isBranchManager || permissions.isAuditSupport) &&
                      <Button className="mr-1"
                              color="danger"
                              onClick={() => handleAction(LocalUnitAuditAction.REVIEW_SHEET_RETURN, formikProps)}
                              aria-label="Return Review Sheet"
                              disabled={formikProps.isSubmitting || !returnAllowed}>
                        Return
                      </Button>
                    }
                    <Button className={permissions.isBranchManager || permissions.isAuditSupport ? 'mr-1' : ''}
                            color="danger"
                            onClick={() => handleAction(LocalUnitAuditAction.REVIEW_SHEET_SEND_TO_REVIEW, formikProps)}
                            aria-label="Send Audit Review Sheet to Review"
                            disabled={formikProps.isSubmitting || !sendToReviewAllowed}>
                      Send to Review
                    </Button>
                    {(permissions.isBranchManager || permissions.isAuditSupport) &&
                      <Button color="success"
                              onClick={() => handleAction(LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED, formikProps)}
                              aria-label="Mark Audit Review Sheet as Reviewed"
                              disabled={formikProps.isSubmitting || !reviewedAllowed}>
                        Reviewed
                      </Button>
                    }
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
        }
      </Container>
    );
  }
};

export default ReviewSheetReasonView;