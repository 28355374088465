import {AppBar, SOMLogo, SsoNotificationButton, User, UserProfile} from '@reasoncorp/kyber-js';

import config from '../config';
import {ssoApi} from '../api';
import {useCallback, useMemo} from 'react';

type Props = {
  currentUser?: User
}

const PA660AuditAppBar = ({
                            currentUser
                          }: Props) => {
  const handleProfileClick = useCallback(() => {
    window.location.href = `${config.sso.webUrl}/user-profile`;
  }, []);

  const handleNotificationsClick = useCallback(() => {
    window.location.href = `${config.sso.webUrl}/notifications`;
  }, []);

  const handleSignOut = useCallback(async () => {
    await ssoApi.signOut();
  }, []);

  const renderNotificationButton = useMemo(() => {
    return currentUser ? () => <SsoNotificationButton onNotificationsClick={handleNotificationsClick}
                                                      ssoApi={ssoApi}/> : undefined;
  }, [currentUser, handleNotificationsClick]);

  const renderUserProfile =useMemo(() => {
    return currentUser ? () => <UserProfile handleProfileClick={handleProfileClick}
                                            handleSignOut={handleSignOut}/> : undefined;
  }, [currentUser, handleSignOut, handleProfileClick]);

  return (
    <AppBar brandLink={`${config.sso.webUrl}/dashboard`}
            brandImage={SOMLogo}
            brandImageAlt="MiSuite"
            appName="PA 660 Audit"
            organizationName="The Department of Treasury"
            environmentName={config.envName !== 'prod' ? config.envName : undefined}
            renderNotificationButton={renderNotificationButton}
            renderUserProfile={renderUserProfile}/>
  );
};

export default PA660AuditAppBar;