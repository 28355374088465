import {useMemo} from 'react';
import {Form} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';

import {FormikSelect} from '@reasoncorp/kyber-js';

import {AuditYearFormFields} from '../types';

type Props = {
  selectedAuditYear: number | string
  auditYears: number[]
  disabled: boolean
  onChange: (values: AuditYearFormFields, formikHelpers: FormikHelpers<AuditYearFormFields>) => void
}

const AuditYearSelect = ({
                           selectedAuditYear,
                           auditYears,
                           disabled,
                           onChange
                         }: Props) => {
  const renderOption = useMemo(() => (auditYear: number) => {
    return <option key={auditYear}
                   value={auditYear}>
      {auditYear}
    </option>;
  }, []);

  return (
    <Formik initialValues={{auditYear: selectedAuditYear}}
            onSubmit={onChange}>
      {(formikProps) => (
        <Form autoComplete="off">
          <FormikSelect name="auditYear"
                        labelText="Year"
                        disabled={disabled}
                        aria-required={true}
                        onChange={() => {
                          formikProps.handleSubmit();
                        }}>
            <option key="Open" value="">Open</option>
            {auditYears.map(renderOption)}
          </FormikSelect>
        </Form>
      )}
    </Formik>
  );
};

export default AuditYearSelect;