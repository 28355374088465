import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {
  BreadcrumbsNav,
  MiSuiteRole,
  ProgressIndicator,
  ProgressModal,
  useAlerts,
  windowUtils
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {CAP_SUBMIT_FAILURE} from '../../messages';
import {useAppContext} from '../../hooks';
import {capApi, localUnitAuditApi} from '../../api';
import {CapUploadsCard, CapV1Form, CapV2Form, CapV3Form} from '../../components/cap';
import {LocalUnitAudit} from '../../types';
import {Cap, CapFormFields, CapUpload} from '../../types/cap';
import {LocalUnitAuditStatus} from '../../enum';
import {formUtils} from '../../schema';
import {capUtils} from '../../schema/cap';

const CapLocalUnitView = () => {
  const navigate = useNavigate();
  const params = useParams() as {countyAuditId: string, localUnitId: string, localUnitAuditId: string};
  const [countyAuditId, localUnitId, localUnitAuditId] = useMemo(() => ([
    parseInt(params.countyAuditId),
    parseInt(params.localUnitId),
    parseInt(params.localUnitAuditId)
  ]), [params]);
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const {localUnitPermissions} = useAppContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [assessorForLocalUnit, setAssessorForLocalUnit] = useState(false);
  const [localUnitAudit, setLocalUnitAudit] = useState<LocalUnitAudit | undefined>(undefined);
  const [cap, setCap] = useState<Cap | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progressModalState, setProgressModalState] = useState({
    isOpen: false,
    title: '',
    content: ''
  });
  const [capUploadsToDelete, setCapUploadsToDelete] = useState<number[]>([]);
  const [capUploads, setCapUploads] = useState<File[]>([]);

  const formEditable = useMemo(() => {
    return assessorForLocalUnit &&
      (localUnitAudit?.rawStatus === LocalUnitAuditStatus.CAP_REQUESTED ||
        localUnitAudit?.rawStatus === LocalUnitAuditStatus.CAP_DENIED ||
        localUnitAudit?.rawStatus === LocalUnitAuditStatus.NOTICE_RECEIVED);
  }, [
    assessorForLocalUnit,
    localUnitAudit
  ]);

  const breadcrumbs = useMemo(() => ([{
    text: 'Dashboard',
    icon: 'home' as IconProp,
    route: `/local-unit-portal/${localUnitId}`
  }, {
    text: `${localUnitAudit?.county.displayNameWithType} - ${localUnitAudit?.localUnit.displayNameWithType} Corrective Action Plan (CAP)`,
    active: true
  }]), [
    localUnitAudit,
    localUnitId
  ]);

  const handleSave = useCallback(async (values: CapFormFields, formikHelpers: FormikHelpers<CapFormFields>) => {
    const valuesToSubmit = formUtils.trimAndNullValues(values);
    const capFormRequest = new FormData();

    // Build the request object
    capFormRequest.append('capForm', JSON.stringify(valuesToSubmit));
    capUploads.forEach(file => capFormRequest.append('files', file));
    capFormRequest.append('capUploadsToDelete', capUploadsToDelete.join(','));

    try {
      setProgressModalState({
        isOpen: true,
        title: 'Saving CAP',
        content: 'CAP is being saved. Please do not refresh the page, as this could take a few moments.'
      });
      await capApi.save(localUnitAuditId, capFormRequest);
      showSuccessAlert(messages.CAP_SAVE_SUCCESSFUL);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      setProgressModalState({isOpen: false, title: '', content: ''});
      showErrorAlert(messages.CAP_SAVE_FAILURE);
    } finally {
      setProgressModalState({
        title: 'Saving CAP',
        content: 'CAP is being saved. Please do not refresh the page, as this could take a few moments.',
        isOpen: false
      });
    }
  }, [
    capUploads,
    capUploadsToDelete,
    localUnitAuditId,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleSubmit = useCallback(async (values: CapFormFields, formikHelpers: FormikHelpers<CapFormFields>) => {
    const valuesToSubmit = formUtils.trimAndNullValues(values);
    const capFormRequest = new FormData();

    // Build the request object
    capFormRequest.append('capForm', JSON.stringify(valuesToSubmit));
    capUploads.forEach(file => capFormRequest.append('files', file));
    capFormRequest.append('capUploadsToDelete', capUploadsToDelete.join(','));

    try {
      setProgressModalState({
        isOpen: true,
        title: 'Submitting CAP',
        content: 'CAP is being submitted. Please do not refresh the page, as this could take a few moments.'
      });

      const cap = await capApi.submit(localUnitAuditId, capFormRequest);
      setCap(cap);

      // Show alert and then return back to the previous page
      showSuccessAlert(messages.CAP_SUBMIT_SUCCESSFUL);
      navigate(`/local-unit-portal/${localUnitId}`);

      setCapUploadsToDelete([]);
      setCapUploads([]);
    } catch (error) {
      formikHelpers.setSubmitting(false);
      setProgressModalState({isOpen: false, title: '', content: ''});
      showErrorAlert(CAP_SUBMIT_FAILURE);
    } finally {
      setProgressModalState({
        title: 'Submitting CAP',
        content: 'CAP is being submitted. Please do not refresh the page, as this could take a few moments.',
        isOpen: false
      });
    }
  }, [
    capUploads,
    capUploadsToDelete,
    navigate,
    localUnitId,
    localUnitAuditId,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleCapUploadDelete = useCallback((capUpload: CapUpload) => {
    if (capUpload.id > 0) {
      let currentCapUploadsToDelete = [...capUploadsToDelete];
      currentCapUploadsToDelete.push(capUpload.id);
      setCapUploadsToDelete(currentCapUploadsToDelete);
    }

    const index = capUploads.findIndex(file => file.name === capUpload.fileName);
    let updatedUploads: File[] = [...capUploads];
    updatedUploads.splice(index, 1);
    setCapUploads(updatedUploads);
  }, [
    capUploads,
    capUploadsToDelete
  ]);

  const handleCapUploadSubmit = useCallback((newCapUploads: File[]) => {
    let currentCapUploads = [...capUploads];
    currentCapUploads = currentCapUploads.concat(newCapUploads);
    setCapUploads(currentCapUploads);
  }, [
    capUploads
  ]);

  const handleViewPdf = useCallback(async (values: CapFormFields) => {
    try {
      setIsSubmitting(true);
      await windowUtils.openFileInNewWindow(capApi.generateDraftPdf(localUnitAuditId, values));
      setIsSubmitting(false);
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT);
    }
  }, [
    showErrorAlert,
    localUnitAuditId
  ]);

  useEffect(() => {
      const loadData = async () => {
        try {
          const [localUnitAudit, cap] = await Promise.all([
            localUnitAuditApi.findById(localUnitAuditId),
            capApi.findLatest(localUnitAuditId)
          ]);
          // If user directly enters URL and the audit is done send them back to previous breadcrumb
          if (localUnitAudit.completed) {
            navigate(`/local-unit-portal/${localUnitId}`);
          } else {
            setLocalUnitAudit(localUnitAudit);
            setCap(cap);
            setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
          }
        } catch (error) {
          setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
          showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
        }
      };

      void loadData();
    },
    [
      navigate,
      localUnitId,
      countyAuditId,
      localUnitAuditId,
      showErrorAlert
    ]
  );

  useEffect(() => {
      if (localUnitPermissions.length !== 0 && localUnitAudit) {
        setAssessorForLocalUnit(localUnitPermissions.some(localUnitPermission => localUnitPermission.localUnitId === localUnitAudit.localUnit.id &&
          localUnitPermission.role === MiSuiteRole.ASSESSOR_OF_RECORD));
      }
    },
    [localUnitAudit, localUnitPermissions]
  );

  /* Update this code as new versions of the CAP come about */
  const CapForm = useMemo(() => () => {
    if (!localUnitAudit) {
      return null;
    } else if (localUnitAudit.reviewSheet.version === 'v1') {
      return <CapV1Form formEditable={formEditable}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v2') {
      return <CapV2Form formEditable={formEditable}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v3') {
      return <CapV3Form formEditable={formEditable}/>;
    } else {
      return null;
    }
  }, [
    formEditable,
    localUnitAudit
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="Cap">
        {(loadingState.loading || !localUnitAudit || !cap) && <ProgressIndicator/>}
        {!loadingState.loading && localUnitAudit && cap && <>
          <Formik initialValues={capUtils.getInitialValues(localUnitAudit.reviewSheet, cap)}
                  validationSchema={capUtils.getValidationSchema(localUnitAudit.reviewSheet)}
                  validateOnMount={true}
                  onSubmit={handleSubmit}>
            {(formikProps) => (
              <Form autoComplete="off">
                <Row className="mb-3">
                  <Col md="6">
                    <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <Button color="primary"
                            aria-label="View PDF"
                            disabled={isSubmitting}
                            onClick={() => handleViewPdf(formikProps.values)}>
                      View PDF
                    </Button>
                  </Col>
                </Row>
                <CapForm/>
                <CapUploadsCard editable={formEditable}
                                cap={cap}
                                localUnitAudit={localUnitAudit}
                                handleCapUploadDelete={handleCapUploadDelete}
                                handleCapUploadSubmit={handleCapUploadSubmit}/>
                {assessorForLocalUnit && <>
                  <Row className="pt-4">
                    <Col className="d-flex justify-content-end">
                      <Button className="mr-1"
                              color="secondary"
                              onClick={() => navigate(`/local-unit-portal/${localUnitId}`)}
                              aria-label="Cancel">
                        Cancel
                      </Button>
                      <Button className="mr-1"
                              color="primary"
                              onClick={() => handleSave(formikProps.values, formikProps)}
                              aria-label="Save CAP"
                              disabled={!formikProps.dirty || formikProps.isSubmitting || !formEditable}>
                        Save
                      </Button>
                      <Button color="success"
                              onClick={() => handleSubmit(formikProps.values, formikProps)}
                              aria-label="Submit"
                              disabled={!formikProps.isValid || formikProps.isSubmitting || !formEditable}>
                        Submit
                      </Button>
                    </Col>
                  </Row>
                  <ProgressModal {...progressModalState} />
                </>}
              </Form>
            )}
          </Formik>
        </>
        }
      </Container>
    );
  }
};

export default CapLocalUnitView;