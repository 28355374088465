import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  ProgressIndicator,
  useAlerts,
  useUserContext,
  windowUtils
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {capApi, localUnitAuditApi} from '../../api';
import {CapUploadsCard, CapV1Form, CapV2Form, CapV3Form, DenyCapModal} from '../../components/cap';
import {FollowUpModal} from '../../components/shared';
import {DenyCapFormFields, FollowUpDto, FollowUpFormFields, LocalUnitAudit} from '../../types';
import {Cap, CapFormFields} from '../../types/cap';
import {capUtils} from '../../schema/cap';
import {LocalUnitAuditStatus} from '../../enum';

const CapStateView = () => {
  const navigate = useNavigate();
  const params = useParams() as {countyAuditId: string, localUnitAuditId: string};
  const [countyAuditId, localUnitAuditId] = useMemo(() => ([
    parseInt(params.countyAuditId),
    parseInt(params.localUnitAuditId)
  ]), [params]);
  const {permissions} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnitAudit, setLocalUnitAudit] = useState<LocalUnitAudit | undefined>(undefined);
  const [cap, setCap] = useState<Cap | undefined>(undefined);
  const [displayDenyCapModal, setDisplayDenyCapModal] = useState(false);
  const [displayAcceptModal, setDisplayAcceptModal] = useState(false);
  const [displayFollowUpModal, setDisplayFollowUpModal] = useState(false);
  const [displaySendNoticeModal, setDisplaySendNoticeModal] = useState(false);
  const [displayAssumeModal, setDisplayAssumeModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const breadcrumbs = useMemo(() => ([{
    text: 'Dashboard',
    icon: 'home' as IconProp,
    route: `/state-portal`
  }, {
    text: `${localUnitAudit?.county.displayNameWithType} Local Units`,
    route: `/state-portal/county-audits/${countyAuditId}`
  }, {
    text: `${localUnitAudit?.localUnit.displayNameWithType} Corrective Action Plan (CAP)`,
    active: true
  }]), [localUnitAudit, countyAuditId]);

  const handleDenyCap = useCallback(async (values: DenyCapFormFields,
                                           formikHelpers: FormikHelpers<DenyCapFormFields>) => {
    try {
      await capApi.deny(localUnitAuditId, values);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
      showSuccessAlert(messages.CAP_DENY_SUCCESSFUL);
    } catch (error) {
      formikHelpers.resetForm();
      setDisplayDenyCapModal(false);
      showErrorAlert(messages.CAP_DENY_FAILURE);
    }
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert, showSuccessAlert]);

  const handleAcceptWithoutFollowUp = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await capApi.acceptWithoutFollowUp(localUnitAuditId);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
      showSuccessAlert(messages.REVIEW_SHEET_ACCEPT_SUCCESSFUL);
    } catch (error) {
      setIsSubmitting(false);
      setDisplayAcceptModal(false);
      showErrorAlert(messages.REVIEW_SHEET_ACCEPT_FAILURE);
    }
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert, showSuccessAlert]);

  const handleAcceptWithFollowUp = useCallback(async (followUpDto: FollowUpDto,
                                                      formikHelpers: FormikHelpers<FollowUpFormFields>) => {
    try {
      await capApi.acceptWithFollowUp(localUnitAuditId, followUpDto);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
      showSuccessAlert(messages.REVIEW_SHEET_ACCEPT_SUCCESSFUL);
    } catch (error) {
      formikHelpers.resetForm();
      setDisplayDenyCapModal(false);
      showErrorAlert(messages.REVIEW_SHEET_ACCEPT_FAILURE);
    }
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert, showSuccessAlert]);

  const handleSendNotice = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await localUnitAuditApi.sendNotice(localUnitAuditId);
      showSuccessAlert(messages.SEND_NOTICE_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setIsSubmitting(false);
      setDisplaySendNoticeModal(false);
      showErrorAlert(messages.SEND_NOTICE_FAILURE);
    }
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert, showSuccessAlert]);

  const handleAssume = useCallback(async (followUpDto: FollowUpDto) => {
    try {
      await localUnitAuditApi.assume(localUnitAuditId, followUpDto);
      showSuccessAlert(messages.ASSUME_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setDisplaySendNoticeModal(false);
      showErrorAlert(messages.ASSUME_FAILURE);
    }
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert, showSuccessAlert]);

  const handleViewPdf = useCallback(async (values: CapFormFields) => {
    try {
      setIsSubmitting(true);
      await windowUtils.openFileInNewWindow(capApi.generateDraftPdf(localUnitAuditId, values));
      setIsSubmitting(false);
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT);
    }
  }, [localUnitAuditId, showErrorAlert]);

  useEffect(() => {
      const loadData = async () => {
        try {
          const [localUnitAudit, cap] = await Promise.all([
            localUnitAuditApi.findById(localUnitAuditId),
            capApi.findLatest(localUnitAuditId)
          ]);
          // If user directly enters URL and the audit is done send them back to previous breadcrumb
          if (localUnitAudit.completed) {
            navigate(`/state-portal/county-audits/${countyAuditId}`);
          } else {
            setLocalUnitAudit(localUnitAudit);
            setCap(cap);
            setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
          }
        } catch (error) {
          setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
          showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
        }
      };

      void loadData();
    },
    [navigate, countyAuditId, localUnitAuditId, showErrorAlert]
  );

  const CapForm = useMemo(() => () => {
    if (!localUnitAudit) {
      return null;
    } else if (localUnitAudit.reviewSheet.version === 'v1') {
      return <CapV1Form formEditable={false}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v2') {
      return <CapV2Form formEditable={false}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v3') {
      return <CapV3Form formEditable={false}/>;
    } else {
      return null;
    }
  }, [
    localUnitAudit
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="Cap">
        {(loadingState.loading || !localUnitAudit || !cap) && <ProgressIndicator/>}
        {!loadingState.loading && localUnitAudit && cap && <>
          <Formik initialValues={capUtils.getInitialValues(localUnitAudit.reviewSheet, cap)}
                  validationSchema={capUtils.getValidationSchema(localUnitAudit.reviewSheet)}
                  onSubmit={() => void 0}>
            {(formikProps) => (
              <Form autoComplete="off">
                <Row className="mb-3">
                  <Col md="6">
                    <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <Button color="primary"
                            aria-label="View PDF"
                            disabled={isSubmitting}
                            onClick={() => handleViewPdf(formikProps.values)}>
                      View PDF
                    </Button>
                  </Col>
                </Row>
                <CapForm/>
                <CapUploadsCard editable={false}
                                cap={cap}
                                localUnitAudit={localUnitAudit}/>
                {permissions.isStateAdmin &&
                  <Row className="pt-4">
                    <Col className="d-flex justify-content-end">
                      <Button className="mr-1"
                              color="secondary"
                              onClick={() => navigate(`/state-portal/county-audits/${countyAuditId}`)}
                              aria-label="Cancel">
                        Cancel
                      </Button>
                      {!localUnitAudit.followUp &&
                        <Button className="mr-1"
                                color="danger"
                                onClick={() => setDisplayDenyCapModal(true)}
                                aria-label="Deny CAP"
                                disabled={localUnitAudit.rawStatus !== LocalUnitAuditStatus.CAP_RECEIVED}>
                          Deny CAP
                        </Button>
                      }
                      <Button className="mr-1"
                              color="danger"
                              onClick={() => setDisplaySendNoticeModal(true)}
                              aria-label="Notice"
                              disabled={localUnitAudit.noticeBy !== null}>
                        Notice
                      </Button>
                      <Button className="mr-1"
                              color="danger"
                              onClick={() => setDisplayAssumeModal(true)}
                              aria-label="Assume"
                              hidden={localUnitAudit.noticeBy === null}>
                        Assume
                      </Button>
                      <Button
                        color="success"
                        onClick={() => setDisplayFollowUpModal(true)}
                        aria-label="Accept with Follow Up">
                        Accept with Follow Up
                      </Button>
                      {!localUnitAudit.followUp &&
                        <Button className="ml-1"
                                color="success"
                                onClick={() => setDisplayAcceptModal(true)}
                                aria-label="Accept without Follow Up">
                          Accept without Follow Up
                        </Button>
                      }
                    </Col>
                  </Row>
                }
              </Form>
            )}
          </Formik>
          <DenyCapModal isOpen={displayDenyCapModal}
                        localUnitAudit={localUnitAudit}
                        onSubmit={handleDenyCap}
                        onCancel={() => setDisplayDenyCapModal(false)}/>
          <ConfirmationModal isOpen={displayAcceptModal}
                             title="Accept PA 660 Audit"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             cancelButtonDisabled={isSubmitting}
                             confirmButtonDisabled={isSubmitting}
                             cancelCallback={() => setDisplayAcceptModal(false)}
                             confirmCallback={handleAcceptWithoutFollowUp}>
            <p>
              Are you sure you want to accept the PA 660 Audit for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
            </p>
          </ConfirmationModal>
          <ConfirmationModal isOpen={displaySendNoticeModal}
                             title="Send 21 Day Notice"
                             confirmButtonText="Yes"
                             cancelButtonText="No"
                             cancelButtonDisabled={isSubmitting}
                             confirmButtonDisabled={isSubmitting}
                             cancelCallback={() => setDisplaySendNoticeModal(false)}
                             confirmCallback={handleSendNotice}>
            <p>
              Are you sure you want to send a 21 Day Notice for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
            </p>
          </ConfirmationModal>
          <FollowUpModal isOpen={displayFollowUpModal}
                         localUnitAudit={localUnitAudit}
                         onSubmit={handleAcceptWithFollowUp}
                         onCancel={() => setDisplayFollowUpModal(false)}/>
          <FollowUpModal isOpen={displayAssumeModal}
                         localUnitAudit={localUnitAudit}
                         onSubmit={handleAssume}
                         onCancel={() => setDisplayAssumeModal(false)}
                         isAssume={true}/>
        </>
        }
      </Container>
    );
  }
};

export default CapStateView;