import * as Yup from 'yup';

import {YupFileArray} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';

const capUploadsSchema = Yup.object().shape({
  multipleFiles: new YupFileArray()
    .maxFileSize(50 * 1000 * 1000, messages.MAX_FILE_SIZE)
    .acceptedFileTypes(['PDF', 'PNG', 'JPG', 'JPEG', 'DOC', 'DOCX'], messages.BAD_CAP_UPLOAD_FORMAT)
    .schema()
    .required(messages.REQUIRED)
});

export default capUploadsSchema;