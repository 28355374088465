import * as Yup from 'yup';

import * as messages from '../../messages';
import {LocalUnitAuditAction} from '../../enum';

export const localUnitBackground = Yup.object().shape({
  assessmentRollCertificationDate: Yup.date()
    .required(messages.REQUIRED).nullable(),
  cod: Yup.string()
    .matches(/^[0-9]{2}\.[0-9]{2}$/, messages.NUMBER_RANGE_VALIDATION)
    .required(messages.REQUIRED),
  codNotes: Yup.string(),
  prd: Yup.string()
    .matches(/^[0-9]{2}\.[0-9]{2}$/, messages.NUMBER_RANGE_VALIDATION)
    .required(messages.REQUIRED),
  prdNotes: Yup.string(),
  l4022Matches: Yup.string()
    .required(messages.REQUIRED),
  l4022MatchesNotes: Yup.string(),
  form4142Completed: Yup.string()
    .required(messages.REQUIRED),
  form4142CompletedNotes: Yup.string(),
  writtenProcedures: Yup.string()
    .required(messages.REQUIRED),
  writtenProceduresNotes: Yup.string(),
  landValueMapsAccurate: Yup.string()
    .required(messages.REQUIRED),
  landValueMapsAccurateNotes: Yup.string()
});

export const assessmentRollAnalysis = Yup.object().shape({
  economicConditionFactorsMet: Yup.string()
    .required(messages.REQUIRED),
  economicConditionFactorsMetNotes: Yup.string(),
  landValueDeterminationsAppropriate: Yup.string()
    .required(messages.REQUIRED),
  landValueDeterminationsAppropriateNotes: Yup.string(),
  cashValueAgrees: Yup.string()
    .required(messages.REQUIRED),
  cashValueAgreesNotes: Yup.string()
});

export const personalPropertyReview = Yup.object().shape({
  conductsAnnualPersonalPropertyCanvas: Yup.string()
    .required(messages.REQUIRED),
  conductsAnnualPersonalPropertyCanvasNotes: Yup.string(),
  smallBusinessExemptionsGranted: Yup.string()
    .required(messages.REQUIRED),
  smallBusinessExemptionsGrantedNotes: Yup.string(),
  exemptionsProcessingRequirementMet: Yup.string()
    .when('smallBusinessExemptionsGranted', {
      is: (smallBusinessExemptionsGranted: string) => smallBusinessExemptionsGranted === 'Yes',
      then: Yup.string()
        .required(messages.REQUIRED),
      otherwise: Yup.string()
    }),
  exemptionsProcessingRequirementMetNotes: Yup.string()
});

export const reviewOfExemptionsGranted = Yup.object().shape({
  povertyExemptionsGranted: Yup.string()
    .required(messages.REQUIRED),
  povertyExemptionsGrantedNotes: Yup.string(),
  hasPovertyExemptionGuidelines: Yup.string()
    .required(messages.REQUIRED),
  hasPovertyExemptionGuidelinesNotes: Yup.string(),
  guidelinesIncludeAssetLevelTest: Yup.string()
    .required(messages.REQUIRED),
  guidelinesIncludeAssetLevelTestNotes: Yup.string(),
  statutoryRequirementsMetAndPolicyFollowed: Yup.string()
    .when('povertyExemptionsGranted', {
      is: (povertyExemptionsGranted: string) => povertyExemptionsGranted === 'Yes',
      then: Yup.string()
        .required(messages.REQUIRED),
      otherwise: Yup.string()
    }),
  statutoryRequirementsMetAndPolicyFollowedNotes: Yup.string(),
  onlyStatutoryAuthorityItemsConsidered: Yup.string()
    .required(messages.REQUIRED),
  onlyStatutoryAuthorityItemsConsideredNotes: Yup.string(),
  ptaFilingImposed: Yup.string()
    .required(messages.REQUIRED),
  ptaFilingImposedNotes: Yup.string(),
  penaltyWaivedByResolution: Yup.string()
    .when('ptaFilingImposed', {
      is: (ptaFilingImposed: string) => ptaFilingImposed === 'No',
      then: Yup.string()
        .required(messages.REQUIRED),
      otherwise: Yup.string()
        .nullable()
    }),
  penaltyWaivedByResolutionNotes: Yup.string()
});

export const baseSchema = Yup.object().shape({
  saveAction: Yup.string()
    .oneOf([
      LocalUnitAuditAction.REVIEW_SHEET_SAVE,
      LocalUnitAuditAction.REVIEW_SHEET_SEND_TO_REVIEW,
      LocalUnitAuditAction.REVIEW_SHEET_RETURN,
      LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED
    ]),
  version: Yup.string()
    .required(),
  localUnitBackground: localUnitBackground,
  assessmentRollAnalysis: assessmentRollAnalysis,
  personalPropertyReview: personalPropertyReview,
  reviewOfExemptionsGranted: reviewOfExemptionsGranted,
  comments: Yup.string(),
  signature: Yup.object()
    .when('saveAction', {
      is: (saveAction: LocalUnitAuditAction) => saveAction === LocalUnitAuditAction.REVIEW_SHEET_MARK_REVIEWED,
      then: Yup.object().shape({
        name: Yup.string()
          .required(messages.REQUIRED),
        date: Yup.date()
          .required(messages.REQUIRED)
          .nullable(),
        attestationConfirmation: Yup.boolean()
          .oneOf([true], messages.REQUIRED)
      }),
      otherwise: Yup.object().shape({
        name: Yup.string(),
        date: Yup.date()
          .nullable(),
        attestationConfirmation: Yup.boolean()
      })
    })
});