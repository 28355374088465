import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {Formik, FormikProps, FormikValues} from 'formik';
import {FormikMultipleFileInput} from '@reasoncorp/kyber-js';
import {useCallback, useMemo} from 'react';
import {capUploadsSchema} from '../../schema/cap';

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  onSubmit: (values: FormikValues) => void,
  onToggle: () => void
}

const CapUploadsModal = ({
                           isOpen,
                           setOpen,
                           onSubmit,
                           onToggle
                         }: Props) => {

  const initialValues: {multipleFiles: File[]} = useMemo(() => ({
    multipleFiles: []
  }), []);

  const handleClose = useCallback((formikProps: FormikProps<{multipleFiles: File[]}>) => {
    setOpen(false);
    onToggle();
    formikProps.resetForm();
  }, [
    setOpen,
    onToggle
  ]);

  const handleFilesSubmit = useCallback((formikProps: FormikProps<{multipleFiles: File[]}>) => {
    onSubmit(formikProps.values);
    handleClose(formikProps);
  }, [
    onSubmit,
    handleClose
  ]);

  return (
    <Formik initialValues={initialValues}
            validationSchema={capUploadsSchema}
            onSubmit={onSubmit}>
      {(formikProps) => (
        <Modal isOpen={isOpen}
               size="xl">
          <ModalHeader toggle={() => handleClose(formikProps)}>
            Add Additional Documents
          </ModalHeader>
          <Form autoComplete="off">
            <ModalBody>
              <Row>
                <Col>
                  <p className="text-muted">
                    Upload Additional Supporting Documentation
                  </p>
                  <FormikMultipleFileInput name="multipleFiles"/>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button color="success"
                      className="mr-1"
                      onClick={() => handleFilesSubmit(formikProps)}
                      disabled={!formikProps.dirty || !formikProps.isValid || formikProps.isSubmitting}
                      aria-label="Save Button">
                Save
              </Button>
              <Button color="secondary"
                      onClick={() => handleClose(formikProps)}
                      disabled={formikProps.isSubmitting}
                      aria-label="Cancel Button">
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CapUploadsModal;