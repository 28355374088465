import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardHeader, Container} from 'reactstrap';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {BreadcrumbsNav, ButtonIcon, CustomTable, ProgressIndicator, useAlerts, windowUtils} from '@reasoncorp/kyber-js';

import {auditHistoryApi, localUnitAuditApi} from '../api';
import * as messages from '../messages';
import {AuditHistoryRecord, LocalUnitAudit, Portal} from '../types';

type Props = {
  portal: Portal
}

const LocalUnitAuditHistory = ({portal}: Props) => {
  const {countyAuditId, localUnitAuditId, localUnitId}: any = useParams();
  const {showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnitAudit, setLocalUnitAudit] = useState<LocalUnitAudit | undefined>(undefined);
  const [auditHistoryRecords, setAuditHistoryRecords] = useState<AuditHistoryRecord[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [localUnitAudit, auditHistoryRecords] = await Promise.all([
          localUnitAuditApi.findById(localUnitAuditId),
          auditHistoryApi.findByLocalUnitAudit(localUnitAuditId, portal)
        ]);
        setLocalUnitAudit(localUnitAudit);
        setAuditHistoryRecords(auditHistoryRecords);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [localUnitAuditId, portal, showErrorAlert]);

  const breadcrumbs = useMemo(() => {
    return portal === 'local-unit' ?
      [{
        text: 'Dashboard',
        icon: 'home' as IconProp,
        route: `/${portal}-portal/${localUnitId}`
      }, {
        text: `${localUnitAudit?.county.displayNameWithType} - ${localUnitAudit?.localUnit.displayNameWithType} History`,
        active: true
      }]
      :
      // Reason and State portals have the same breadcrumb logic
      [{
        text: 'Dashboard',
        icon: 'home' as IconProp,
        route: `/${portal}-portal`
      }, {
        text: `${localUnitAudit?.county.displayNameWithType} Local Units`,
        route: `/${portal}-portal/county-audits/${countyAuditId}`
      }, {
        text: `${localUnitAudit?.localUnit.displayNameWithType} History`,
        active: true
      }];
  }, [
    portal,
    countyAuditId,
    localUnitAudit,
    localUnitId
  ]);

  const handleDocumentClick = useCallback(async (localUnitAuditId: number,
                                                 auditHistoryRecord: AuditHistoryRecord) => {
    try {
      if (auditHistoryRecord.documentType === 'Accepted CAP') {
        await auditHistoryApi.findCapZip(localUnitAuditId, auditHistoryRecord.id);
      } else {
        await windowUtils.openFileInNewWindow(auditHistoryApi.findDocument(localUnitAuditId, auditHistoryRecord.id));
      }
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT);
    }
  }, [
    showErrorAlert
  ]);

  const tableProps = useMemo(() => ({
    items: auditHistoryRecords,
    className: 'mb-0',
    noResultsMessage: 'No audit history found',
    headers: [
      {title: 'Document Type', sortKey: 'documentType', className: 'align-middle'},
      {title: 'Date Sent', sortKey: 'dateSent', className: 'align-middle text-center'},
      {title: 'Document', className: 'align-middle text-center'}
    ],
    initialSort: {sortKey: 'dateSent', direction: 'desc' as const},
    renderRow: (auditHistoryRecord: AuditHistoryRecord) => {
      return (
        <tr key={auditHistoryRecord.id}>
          <td className="align-middle">{auditHistoryRecord.documentType}</td>
          <td className="align-middle text-center">{auditHistoryRecord.dateSentDisplay}</td>
          <td className="align-middle text-center">
            <ButtonIcon icon="file-pdf"
                        className="text-primary"
                        ariaLabel={auditHistoryRecord.documentType}
                        title={auditHistoryRecord.documentType}
                        onClick={() => handleDocumentClick(localUnitAuditId, auditHistoryRecord)}/>
          </td>
        </tr>
      );
    }
  }), [
    auditHistoryRecords,
    handleDocumentClick,
    localUnitAuditId
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="LocalUnitAuditHistory">
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <BreadcrumbsNav breadcrumbs={breadcrumbs} />
          <Card>
            <CardHeader>Document History</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </>
        }
      </Container>
    );
  }
};

export default LocalUnitAuditHistory;