import {Button, Card, CardBody, CardHeader, Col, FormFeedback} from 'reactstrap';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {FormikValues, useFormikContext} from 'formik';

import {ButtonIcon, ConfirmationModal, CustomTable, useAlerts, windowUtils} from '@reasoncorp/kyber-js';

import {capApi} from '../../api';
import * as messages from '../../messages';
import CapUploadsModal from './CapUploadsModal';
import {LocalUnitAudit} from '../../types';
import {Cap, CapUpload, CapV1FormFields} from '../../types/cap';

type Props = {
  editable: boolean
  cap: Cap
  localUnitAudit: LocalUnitAudit
  handleCapUploadDelete?: (capUpload: CapUpload) => void
  handleCapUploadSubmit?: (newCapUploads: File[]) => void
}

const CapUploadsCard = ({
                          editable,
                          localUnitAudit,
                          handleCapUploadDelete = () => null,
                          handleCapUploadSubmit = () => null
                        }: Props) => {
  const {setFieldValue, ...formikContext} = useFormikContext<CapV1FormFields>();
  const {showErrorAlert} = useAlerts();
  const [capUploads, setCapUploads] = useState<CapUpload[]>([]);
  const [displayCapUploadModal, setDisplayCapUploadModal] = useState(false);
  const [displayDeleteCapUploadModal, setDisplayDeleteCapUploadModal] = useState(false);
  const [selectedCapUpload, setSelectedCapUpload] = useState<CapUpload | null>(null);

  useEffect(() => {
      const loadData = async () => {
        try {
          const capUploads = await capApi.findUploads(localUnitAudit.id);
          setCapUploads(capUploads);
        } catch (error) {
          showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
        }
      };

      void loadData();
    },
    [localUnitAudit.id, showErrorAlert]
  );

  const toggleCapUploadsModal = useCallback(() => {
    setDisplayCapUploadModal(false);
  }, []);

  const openDeleteModal = useCallback((capUpload: CapUpload) => {
    setSelectedCapUpload(capUpload);
    setDisplayDeleteCapUploadModal(true);
  },[]);

  const toggleDeleteModal = useCallback((confirmDelete: boolean = false) => {
    if (confirmDelete && selectedCapUpload) {
      //Remove from table
      const index = capUploads.findIndex(capUpload => capUpload.id === selectedCapUpload.id);
      let updatedUploads: CapUpload[] = [...capUploads];
      updatedUploads.splice(index, 1);
      setCapUploads(updatedUploads);

      if (selectedCapUpload.id > 0) {
        void setFieldValue('filesToDelete', formikContext.values.filesToDelete.concat(selectedCapUpload.id));
      }

      handleCapUploadDelete(selectedCapUpload);
    }

    setSelectedCapUpload(null);
    setDisplayDeleteCapUploadModal(false);
  }, [
    capUploads,
    handleCapUploadDelete,
    selectedCapUpload,
    setFieldValue,
    formikContext.values.filesToDelete
  ]);

  const handleCapUploadsModalSubmit = useCallback((values: FormikValues) => {
    const files: File[] = values.multipleFiles;
    let id = capUploads.length ? capUploads.length * -1 : 0;
    let uploads: CapUpload[] = [...capUploads];

    files.forEach(function (file) {
      const capUpload: CapUpload = {id: id--, fileName: file.name};
      uploads.push(capUpload);
    });

    setCapUploads(uploads);

    handleCapUploadSubmit(files);
    void setFieldValue('files', formikContext.values.files.concat(files));
    setDisplayCapUploadModal(false);
  }, [
    capUploads,
    handleCapUploadSubmit,
    setFieldValue,
    formikContext.values.files
  ]);

  const handleViewCapUpload = useCallback(async (capUploadId: number) => {
    await windowUtils.openFileInNewWindow(
      capApi.findUpload(localUnitAudit.id, capUploadId),
      () => showErrorAlert(messages.UNABLE_TO_RETRIEVE_FILE)
    );
  }, [
    localUnitAudit.id,
    showErrorAlert
  ]);


  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Attached Files', sortKey: 'fileName', className: 'align-middle'},
      {title: 'View File', className: 'align-middle text-center'},
      {title: 'Delete File', className: 'align-middle text-center', hide: !editable}
    ],
    initialSort: {sortKey: 'fileName', direction: 'asc' as const},
    renderRow: (capUpload: CapUpload) => {
      return (
        <tr key={capUpload.id}>
          <td className="align-left">{capUpload.fileName}</td>
          <td className="align-middle text-center">
            <ButtonIcon icon="paperclip"
                        className="text-primary"
                        ariaLabel={`View ${capUpload.fileName}`}
                        title={`View ${capUpload.fileName} file`}
                        disabled={capUpload.id < 1}
                        onClick={() => handleViewCapUpload(capUpload.id)}/>
          </td>
          {editable &&
            <td className="align-middle text-center">
              <ButtonIcon icon="trash"
                          className="text-primary"
                          ariaLabel={`Delete ${capUpload.fileName} file`}
                          title={`Delete ${capUpload.fileName} file`}
                          onClick={() => openDeleteModal(capUpload)}/>
            </td>
          }
        </tr>
      );
    }
  }), [
    editable,
    handleViewCapUpload,
    openDeleteModal
  ]);

  const filesLength = formikContext.values.files.length;
  useEffect(() => {
      if (editable && filesLength !== capUploads.length) {
        // Create dummy files from the previously uploaded files with a valid mime type to appease formik
        const formikFiles = capUploads.map(() => new Blob(['text'], {type: 'application/pdf'}));
        void setFieldValue('files', formikFiles);
      }
    },
    [editable, capUploads, filesLength, setFieldValue]
  );


  return (
    <Card className="cap-uploads-card">
      <CardHeader className="font-weight-bold">Corrective Action Plan Supported Document Upload</CardHeader>
      <CardBody>
        {editable &&
          <>
            <ol className="text-muted font-weight-bold">
              <li>Complete CAP</li>
              <li>Save data</li>
              <li>Generate PDF using View PDF Button</li>
              <li>Print and Sign PDF</li>
              <li>Upload signed PDF and any supporting documentation</li>
            </ol>
            <Col className="d-flex justify-content-end pr-0">
              <Button className="mb-3"
                      color="primary"
                      onClick={() => setDisplayCapUploadModal(true)}
                      aria-label="Upload File">
                Upload File
              </Button>
            </Col>
          </>
        }
        {formikContext.errors.files &&
          <FormFeedback style={{display: 'block'}}>At least one file upload is required.</FormFeedback>
        }
        <CustomTable className="mb-0"
                     headers={tableProps.headers}
                     initialSort={tableProps.initialSort}
                     items={capUploads}
                     noResultsMessage="No CAP files found"
                     renderRow={tableProps.renderRow}/>
      </CardBody>
      <ConfirmationModal size="xl"
                         isOpen={displayDeleteCapUploadModal}
                         title="Delete Upload"
                         confirmButtonText="Yes"
                         cancelButtonText="No"
                         confirmCallback={() => toggleDeleteModal(true)}
                         cancelCallback={() => toggleDeleteModal(false)}>
        <p>
          Are you sure you want to delete the <span className="text-danger">{selectedCapUpload && selectedCapUpload.fileName}</span> upload?
        </p>
      </ConfirmationModal>
      <CapUploadsModal isOpen={displayCapUploadModal}
                       setOpen={setDisplayDeleteCapUploadModal}
                       onSubmit={handleCapUploadsModalSubmit}
                       onToggle={toggleCapUploadsModal}/>
    </Card>
  );
};

export default CapUploadsCard;