import { useCallback } from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {useFormikContext} from 'formik';

import {FormikCheckboxGroup, FormikDatePicker, FormikInput, UserPermissions} from '@reasoncorp/kyber-js';

import {BaseReviewSheet} from '../../types/reviewSheet';

type Props = {
  formEditable: boolean
  permissions: UserPermissions
}

const ReviewSheetCertificationCard = ({
                                        formEditable,
                                        permissions
                                      }: Props) => {
  const formikContext = useFormikContext<BaseReviewSheet>();

  const handleToggleCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue = e.target.checked ? new Date() : undefined;
      formikContext.setFieldValue('signature.date', fieldValue);
    },
    [formikContext]
  );

  if (permissions.isBranchManager || permissions.isAuditSupport) {
    return (
      <Card>
        <CardHeader className="font-weight-bold">Certification</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <p className="text-secondary font-weight-bold">
                I hereby declare that the foregoing information submitted is a complete and true statement.
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={8} lg={10}>
              <FormikInput name="signature.name"
                           labelText="Signature"
                           formGroupClass="larger-label"
                           disableFloatingLabel={true}
                           disabled={true}/>
            </Col>
            <Col sm={12} md={4} lg={2} className="d-flex justify-content-md-end">
              <FormikDatePicker name="signature.date"
                                labelText="Signature Date"
                                formGroupClass="larger-label"
                                ariaLabel="Signature mm/dd/yyyy"
                                disabled={true}/>
            </Col>
          </Row>
          <Row className="review-sheet-v3-signature">
            <Col sm={12} md={12} lg={12} className="d-inline-flex">
              <FormikCheckboxGroup disabled={!formEditable}
                                   checkboxes={[{
                                     name: 'signature.attestationConfirmation',
                                     labelText: '',
                                     ariaLabel: 'Agree',
                                     onChange: handleToggleCallback
                                   }]}/>
              <p className={`d-inline ${formikContext.errors?.signature ? 'text-danger' : 'text-secondary'}`}>
                I agree and confirm that the signature I have typed above is the electronic representation of my original,
                handwritten signature when used on this document. I further understand
                that signing this document using my electronic signature will have the same legally binding effect as signing
                my signature using pen and paper.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default ReviewSheetCertificationCard;