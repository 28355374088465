import {LocalUnitAuditAction} from '../../enum';
import {ReviewSheetV1, ReviewSheetV1FormFields} from '../../types/reviewSheet';
import formUtils from '../formUtils';

const reviewSheetV1Utils = {
  getInitialValues: (reviewSheet: ReviewSheetV1): ReviewSheetV1FormFields => ({
    saveAction: LocalUnitAuditAction.REVIEW_SHEET_SAVE,
    version: reviewSheet.version,
    localUnitBackground: {
      assessmentRollCertificationDate: reviewSheet?.localUnitBackground?.assessmentRollCertificationDate ?? null,
      cod: reviewSheet?.localUnitBackground?.cod ?? '',
      codNotes: reviewSheet?.localUnitBackground?.codNotes ?? '',
      prd: reviewSheet?.localUnitBackground?.prd ?? '',
      prdNotes: reviewSheet?.localUnitBackground?.prdNotes ?? '',
      l4022Matches: reviewSheet?.localUnitBackground?.l4022Matches ?? '',
      l4022MatchesNotes: reviewSheet?.localUnitBackground?.l4022MatchesNotes ?? '',
      form4142Completed: reviewSheet?.localUnitBackground?.form4142Completed ?? '',
      form4142CompletedNotes: reviewSheet?.localUnitBackground?.form4142CompletedNotes ?? '',
      writtenProcedures: reviewSheet?.localUnitBackground?.writtenProcedures ?? '',
      writtenProceduresNotes: reviewSheet?.localUnitBackground?.writtenProceduresNotes ?? '',
      landValueMapsAccurate: reviewSheet?.localUnitBackground?.landValueMapsAccurate ?? '',
      landValueMapsAccurateNotes: reviewSheet?.localUnitBackground?.landValueMapsAccurateNotes ?? ''
    },
    assessmentRollAnalysis: {
      economicConditionFactorsMet: reviewSheet?.assessmentRollAnalysis?.economicConditionFactorsMet ?? '',
      economicConditionFactorsMetNotes: reviewSheet?.assessmentRollAnalysis?.economicConditionFactorsMetNotes ?? '',
      landValueDeterminationsAppropriate: reviewSheet?.assessmentRollAnalysis?.landValueDeterminationsAppropriate ?? '',
      landValueDeterminationsAppropriateNotes: reviewSheet?.assessmentRollAnalysis?.landValueDeterminationsAppropriateNotes ?? '',
      cashValueAgrees: reviewSheet?.assessmentRollAnalysis?.cashValueAgrees ?? '',
      cashValueAgreesNotes: reviewSheet?.assessmentRollAnalysis?.cashValueAgreesNotes ?? ''
    },
    personalPropertyReview: {
      conductsAnnualPersonalPropertyCanvas: reviewSheet?.personalPropertyReview?.conductsAnnualPersonalPropertyCanvas ?? '',
      conductsAnnualPersonalPropertyCanvasNotes: reviewSheet?.personalPropertyReview?.conductsAnnualPersonalPropertyCanvasNotes ?? '',
      smallBusinessExemptionsGranted: reviewSheet?.personalPropertyReview?.smallBusinessExemptionsGranted ?? '',
      smallBusinessExemptionsGrantedNotes: reviewSheet?.personalPropertyReview?.smallBusinessExemptionsGrantedNotes ?? '',
      exemptionsProcessingRequirementMet: reviewSheet?.personalPropertyReview?.exemptionsProcessingRequirementMet ?? '',
      exemptionsProcessingRequirementMetNotes: reviewSheet?.personalPropertyReview?.exemptionsProcessingRequirementMetNotes ?? ''
    },
    reviewOfExemptionsGranted: {
      povertyExemptionsGranted: reviewSheet?.reviewOfExemptionsGranted?.povertyExemptionsGranted ?? '',
      povertyExemptionsGrantedNotes: reviewSheet?.reviewOfExemptionsGranted?.povertyExemptionsGrantedNotes ?? '',
      hasPovertyExemptionGuidelines: reviewSheet?.reviewOfExemptionsGranted?.hasPovertyExemptionGuidelines ?? '',
      hasPovertyExemptionGuidelinesNotes: reviewSheet?.reviewOfExemptionsGranted?.hasPovertyExemptionGuidelinesNotes ?? '',
      guidelinesIncludeAssetLevelTest: reviewSheet?.reviewOfExemptionsGranted?.guidelinesIncludeAssetLevelTest ?? '',
      guidelinesIncludeAssetLevelTestNotes: reviewSheet?.reviewOfExemptionsGranted?.guidelinesIncludeAssetLevelTestNotes ?? '',
      statutoryRequirementsMetAndPolicyFollowed: reviewSheet?.reviewOfExemptionsGranted?.statutoryRequirementsMetAndPolicyFollowed ?? '',
      statutoryRequirementsMetAndPolicyFollowedNotes: reviewSheet?.reviewOfExemptionsGranted?.statutoryRequirementsMetAndPolicyFollowedNotes ?? '',
      onlyStatutoryAuthorityItemsConsidered: reviewSheet?.reviewOfExemptionsGranted?.onlyStatutoryAuthorityItemsConsidered ?? '',
      onlyStatutoryAuthorityItemsConsideredNotes: reviewSheet?.reviewOfExemptionsGranted?.onlyStatutoryAuthorityItemsConsideredNotes ?? '',
      ptaFilingImposed: reviewSheet?.reviewOfExemptionsGranted?.ptaFilingImposed ?? '',
      ptaFilingImposedNotes: reviewSheet?.reviewOfExemptionsGranted?.ptaFilingImposedNotes ?? '',
      penaltyWaivedByResolution: reviewSheet?.reviewOfExemptionsGranted?.penaltyWaivedByResolution ?? '',
      penaltyWaivedByResolutionNotes: reviewSheet?.reviewOfExemptionsGranted?.penaltyWaivedByResolutionNotes ?? ''
    },
    comments: reviewSheet?.comments ?? '',
    signature: {
      name: reviewSheet?.signature?.name ?? 'Al Consiglio',
      date: reviewSheet?.signature?.date,
      attestationConfirmation: reviewSheet?.signature?.attestationConfirmation ?? false
    }
  }),
  scrubSubmissionValues: (values: ReviewSheetV1) => {
    const scrubbedValues = formUtils.trimAndNullValues(values);
    if (values.personalPropertyReview.smallBusinessExemptionsGranted === 'No') {
      scrubbedValues.personalPropertyReview.exemptionsProcessingRequirementMet = null;
      scrubbedValues.personalPropertyReview.exemptionsProcessingRequirementMetNotes = null;
    }
    if (values.reviewOfExemptionsGranted.povertyExemptionsGranted === 'No') {
      scrubbedValues.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed = null;
      scrubbedValues.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedNotes = null;
    }
    if (values.reviewOfExemptionsGranted.ptaFilingImposed === 'Yes') {
      scrubbedValues.reviewOfExemptionsGranted.penaltyWaivedByResolution = null;
      scrubbedValues.reviewOfExemptionsGranted.penaltyWaivedByResolutionNotes = null;
    }
    return scrubbedValues;
  }
};

export default reviewSheetV1Utils;