import {format, isValid, parseISO} from 'date-fns';

export const formatDate = (dateValue: Date | string | null, dateFormat = 'MM/dd/yyyy') => {
  if (dateValue instanceof Date && isValid(dateValue)) {
    return format(dateValue, dateFormat);
  } else {
    const parsedDate = parseISO((dateValue as string));
    return (dateValue != null && isValid(parsedDate)) ? format(parsedDate, dateFormat) : undefined;
  }
};
