import {LocalUnitAuditAction} from '../../enum';
import {ReviewSheetV3, ReviewSheetV3FormFields} from '../../types/reviewSheet';
import formUtils from '../formUtils';

const reviewSheetV3Utils = {
  getInitialValues: (reviewSheet: ReviewSheetV3): ReviewSheetV3FormFields => ({
    saveAction: LocalUnitAuditAction.REVIEW_SHEET_SAVE,
    version: reviewSheet.version,
    localUnitBackground: {
      assessmentRollCertificationDate: reviewSheet?.localUnitBackground?.assessmentRollCertificationDate ?? null,
      didAssessorReceiveAssistance: reviewSheet?.localUnitBackground?.didAssessorReceiveAssistance ?? '',
      didAssessorReceiveAssistanceNotes: reviewSheet?.localUnitBackground?.didAssessorReceiveAssistanceNotes ?? ''
    },
    assessmentRollAnalysis: {
      economicConditionFactorsMet: reviewSheet?.assessmentRollAnalysis?.economicConditionFactorsMet ?? '',
      economicConditionFactorsMetNotes: reviewSheet?.assessmentRollAnalysis?.economicConditionFactorsMetNotes ?? '',
      landValueDeterminationsAppropriate: reviewSheet?.assessmentRollAnalysis?.landValueDeterminationsAppropriate ?? '',
      landValueDeterminationsAppropriateNotes: reviewSheet?.assessmentRollAnalysis?.landValueDeterminationsAppropriateNotes ?? '',
      cashValueAgrees: reviewSheet?.assessmentRollAnalysis?.cashValueAgrees ?? '',
      cashValueAgreesNotes: reviewSheet?.assessmentRollAnalysis?.cashValueAgreesNotes ?? ''
    },
    assessmentTechnicalAnalysis: {
      doesUseCama: reviewSheet?.assessmentTechnicalAnalysis?.doesUseCama ?? '',
      doesUseCamaNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesUseCamaNotes ?? '',
      hasPovertyExemptionGuidelines: reviewSheet?.assessmentTechnicalAnalysis?.hasPovertyExemptionGuidelines ?? '',
      hasPovertyExemptionGuidelinesNotes: reviewSheet?.assessmentTechnicalAnalysis?.hasPovertyExemptionGuidelinesNotes ?? '',
      hasPublishedPolicyNotes: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyNotes ?? '',
      hasPublishedPolicyDesignation: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyDesignation ?? '',
      hasPublishedPolicyEstimatedResponseTime: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyEstimatedResponseTime ?? '',
      hasPublishedPolicyInPersonMeetingInformation: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyInPersonMeetingInformation ?? '',
      hasPublishedPolicyRequestsInformation: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyRequestsInformation ?? '',
      hasPublishedPolicyDisputeInformation: reviewSheet?.assessmentTechnicalAnalysis?.hasPublishedPolicyDisputeInformation ?? '',
      doesProvideOnlineInformation: reviewSheet?.assessmentTechnicalAnalysis?.doesProvideOnlineInformation ?? '',
      doesProvideOnlineInformationNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesProvideOnlineInformationNotes ?? '',
      doesProvideContactInformation: reviewSheet?.assessmentTechnicalAnalysis?.doesProvideContactInformation ?? '',
      doesProvideContactInformationNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesProvideContactInformationNotes ?? '',
      doesTrainSupportStaff: reviewSheet?.assessmentTechnicalAnalysis?.doesTrainSupportStaff ?? '',
      doesTrainSupportStaffNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesTrainSupportStaffNotes ?? '',
      doesComplyWithFeeCollection: reviewSheet?.assessmentTechnicalAnalysis?.doesComplyWithFeeCollection ?? '',
      doesComplyWithFeeCollectionNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesComplyWithFeeCollectionNotes ?? '',
      doesConductCanvass: reviewSheet?.assessmentTechnicalAnalysis?.doesConductCanvass ?? '',
      doesConductCanvassNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesConductCanvassNotes ?? '',
      doesBoardReviewMeetRequirements: reviewSheet?.assessmentTechnicalAnalysis?.doesBoardReviewMeetRequirements ?? '',
      doesBoardReviewMeetRequirementsNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesBoardReviewMeetRequirementsNotes ?? '',
      doesHaveAdequateProcess: reviewSheet?.assessmentTechnicalAnalysis?.doesHaveAdequateProcess ?? '',
      doesHaveAdequateProcessNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesHaveAdequateProcessNotes ?? '',
      doesMeetSTCRequirements: reviewSheet?.assessmentTechnicalAnalysis?.doesMeetSTCRequirements ?? '',
      doesMeetSTCRequirementsNotes: reviewSheet?.assessmentTechnicalAnalysis?.doesMeetSTCRequirementsNotes ?? '',
      wasAuditorMadeAware: reviewSheet?.assessmentTechnicalAnalysis?.wasAuditorMadeAware ?? '',
      wasAuditorMadeAwareNotes: reviewSheet?.assessmentTechnicalAnalysis?.wasAuditorMadeAwareNotes ?? '',
      reviewOfExemptionsGrantedNotes: reviewSheet?.assessmentTechnicalAnalysis?.reviewOfExemptionsGrantedNotes ?? '',
      didLocalUnitGrantPovertyExemptions: reviewSheet?.assessmentTechnicalAnalysis?.didLocalUnitGrantPovertyExemptions ?? '',
      doesLocalUnitHavePovertyExemptionGuidelines: reviewSheet?.assessmentTechnicalAnalysis?.doesLocalUnitHavePovertyExemptionGuidelines ?? '',
      doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest: reviewSheet?.assessmentTechnicalAnalysis?.doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest ?? '',
      doesSamplingMeetRequirements: reviewSheet?.assessmentTechnicalAnalysis?.doesSamplingMeetRequirements ?? '',
      hasImplementedCamaStandards: reviewSheet?.assessmentTechnicalAnalysis?.hasImplementedCamaStandards ?? '',
      hasImplementedCamaStandardsNotes: reviewSheet?.assessmentTechnicalAnalysis?.hasImplementedCamaStandardsNotes ?? ''
    },
    comments: reviewSheet?.comments ?? '',
    signature: {
      name: reviewSheet?.signature?.name ?? 'Al Consiglio',
      date: reviewSheet?.signature?.date,
      attestationConfirmation: reviewSheet?.signature?.attestationConfirmation ?? false
    }
  }),
  scrubSubmissionValues: (values: ReviewSheetV3) => formUtils.trimAndNullValues(values)
};

export default reviewSheetV3Utils;