import {memo, useMemo} from 'react';
import {Table} from 'reactstrap';

import {MiSuiteRole} from '@reasoncorp/kyber-js';

import {LocalUnitContactInfo} from '../../types';

type Props = {
  auditYear: number
  localUnitName: string
  countyName: string
  localUnitContactInfo: LocalUnitContactInfo
}

const ReviewSheetContactInfoV3 = ({
                                    auditYear,
                                    localUnitName,
                                    countyName,
                                    localUnitContactInfo
                                  }: Props) => {
  const roleDisplayValue = useMemo(() => {
    const role = localUnitContactInfo.manager?.role;
    switch (role) {
      case MiSuiteRole.TOWNSHIP_SUPERVISOR:
        return 'Supervisor';
      case MiSuiteRole.CITY_MANAGER:
        return 'City Manager';
      case MiSuiteRole.MAYOR:
        return 'Mayor';
      default:
        return null;
    }
  }, [
    localUnitContactInfo
  ]);

  return <>
    <div>
      <Table bordered>
        <thead>
          <tr className="font-weight-bold">
            <th className="w-20">Audit Year</th>
            <th className="w-30">Local Unit</th>
            <th>County</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {auditYear}
            </td>
            <td>
              {localUnitName}
            </td>
            <td>
              {countyName}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr className="font-weight-bold">
            <th className="w-50">Assessor Name</th>
            <th>Assessor Certification Level</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {localUnitContactInfo.assessorOfRecord?.fullName} &nbsp;
            </td>
            <td>
              {localUnitContactInfo.assessorOfRecord?.certificationLevel}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr className="font-weight-bold">
            <th className="w-50">Supervisor, City Manager or Mayor Name</th>
            <th className="w-50">Title</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {localUnitContactInfo.manager?.fullName} &nbsp;
            </td>
            <td>
              {roleDisplayValue}
            </td>
          </tr>
        </tbody>
      </Table>
      <Table bordered>
        <thead>
          <tr className="font-weight-bold">
            <th>Mailing Address</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bottom-table-row">
            <td>
              {localUnitContactInfo.manager?.address &&
                `${localUnitContactInfo.manager?.address.addressLine1}, ${localUnitContactInfo.manager?.address.addressLine2}`
              }&nbsp;
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  </>;
};

export default memo(ReviewSheetContactInfoV3);