const reviewSheetV1Questions = {
  localUnitBackground: {
    assessmentRollCertificationDate: 'What date did the assessor certify the assessment roll?',
    cod: 'What is the Residential Coefficient of Dispersion (COD) for the local unit?',
    prd: 'What is the Residential Price Related Differential (PRD) for the local unit?',
    l4022Matches: 'Does the L-4022 in possession of the local unit match the L-4022 in possession of the County ' +
      'Equalization Director and the information uploaded on the L-4023 on the E-File Site?',
    form4142Completed: 'MCL 211.7cc requires interest at a rate of 1.25% per month or fraction of a month to be charged to the ' +
      'owner of property that has been issued a PRE denial notice. Upon collecting the interest, MCL 211.7cc ' +
      'also details the required distribution of the interest depending on the governmental unit that issued ' +
      'the denial notice. Was Form 4142 completed and submitted to the Michigan Department of Treasury by a ' +
      'County, City or Township when the State’s portion of PRE denial interest is remitted?',
    writtenProcedures: 'Does the local unit have written procedures, including audit procedures, for determining how to grant ' +
      'real property exemptions or remove real property exemptions when the property no longer qualifies ' +
      'for the exemption?',
    landValueMapsAccurate: 'Does the local unit have accurate Land Value Maps that meet State Tax Commission Land Value Map Publications?'
  },
  assessmentRollAnalysis: {
    economicConditionFactorsMet: '1. Does the local unit have properly calculated and appropriately documented ' +
      'Economic Condition Factors that meet State Tax Commission requirements per MCL 211.10e and STC ' +
      'ECF Publications?',
    landValueDeterminationsAppropriate: '2. Does the local unit have Land Value Determinations that are ' +
      'appropriately documented, properly calculated and meet State Tax Commission requirements per MCL 211.10e ' +
      'and State Tax Commission Land Value Determination Publications and less than 1% land adjustments ' +
      'without reason?',
    cashValueAgrees: '3. Does the true cash value on the local unit record cards agree with the true cash value ' +
      'indicated on the assessment roll with less than 1% overrides and less than 1% flat land values – ' +
      'excluding DNR PILT Property (STC Policy)?'
  },
  personalPropertyReview: {
    conductsAnnualPersonalPropertyCanvas: '4a. Does the local unit conduct an annual personal property canvass?',
    smallBusinessExemptionsGranted: '4b. Did the local unit grant any exemptions under MCL 211.9o (Small Business ' +
      'Taxpayer Exemption)?',
    exemptionsProcessingRequirementMet: '4c. If the answer to item 4b is yes, does a sampling indicate the local unit ' +
      'properly processed the exemptions received? This includes: Form 5076 filled out completely, timely received ' +
      'and received annually. If Form 5076 is not received the exemption is removed, parcel number created for any ' +
      'business that was granted an exemption, ensuring that a parcel with the exemption is not retired, all ' +
      'locations within the local unit are considered when granting the exemption.'
  },
  reviewOfExemptionsGranted: {
    povertyExemptionsGranted: '5a. Did the local unit grant any exemptions under MCL 211.7u (Poverty Exemption)?',
    hasPovertyExemptionGuidelines: '5b. Does the local unit have proper poverty exemption guidelines?',
    guidelinesIncludeAssetLevelTest: '5c. Does the local unit poverty exemption guidelines include a proper asset level test?',
    statutoryRequirementsMetAndPolicyFollowed: '5d. Does a sampling of the exemptions granted under MCL 211.7u indicate ' +
      'that the statutory requirements were met and that the local unit policy was followed?',
    onlyStatutoryAuthorityItemsConsidered: '6. Does a sample of the July and December Board of Review actions ' +
      'indicate the Board met the requirements of MCL 211.53b and considered only those items over which they have' +
      ' statutory authority?',
    ptaFilingImposed: '7. Does the local unit follow the requirements under MCL 211.27b to levy the interest and ' +
      'penalty for failure to file a Property Transfer Affidavit?',
    penaltyWaivedByResolution: 'If waived, did the local unit waive the interest and penalty by resolution and is ' +
      'that resolution kept on file?'
  }
};

export default reviewSheetV1Questions;