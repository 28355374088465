import {KeyboardEvent, MouseEvent, useCallback, useMemo} from 'react';
import {Badge, Card, CardBody, CardFooter, CardHeader, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useUserContext} from '@reasoncorp/kyber-js';

import {CountyAuditStatusBadge} from './index';
import {CountyAudit, Portal} from '../types';

type Props = {
  portal: Portal
  countyAudit: CountyAudit
  showYear: boolean
  onSelection: () => void
}

const CountyAuditCard = ({
                           portal,
                           countyAudit,
                           showYear,
                           onSelection
                         }: Props) => {
  const {permissions} = useUserContext();

  const handleSelection = useCallback((e: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
    if (e.type === 'click' || (e as KeyboardEvent<HTMLElement>).key === 'Enter') {
      onSelection();
    }
  }, [
    onSelection
  ]);

  const CountyAuditCardBody = useMemo(() => () => {
    const reasonActionRequired = portal === 'reason' &&
      ((permissions.isBranchManager && countyAudit.actionRequiredByBranchManager) ||
        (permissions.isAuditor && countyAudit.actionRequiredByAuditor) ||
        (permissions.isAuditSupport && countyAudit.actionRequiredByAuditSupport));
    const stateActionRequired = portal === 'state' && permissions.isStateAdmin && countyAudit.actionRequiredByStateAdmin;
    return (
      <Row>
        <Col>{countyAudit.totalLocalUnitAudits} Local {countyAudit.totalLocalUnitAudits === 1 ? 'Unit' : 'Units'}</Col>
        {(reasonActionRequired || stateActionRequired) &&
          <Col className="d-flex justify-content-end">
            <FontAwesomeIcon icon="redo" className="text-danger" title="Action Required"/>
          </Col>
        }
      </Row>
    );
  }, [
    countyAudit,
    permissions,
    portal
  ]);

  const CardBadge = useMemo(() => () => {
    if (countyAudit.offline) {
      return <Badge color="warning"
                    tag="button"
                    aria-label="View Audit"
                    className="border-0 text-uppercase">Offline</Badge>;
    } else {
      const rawStatus = portal === 'reason' ? countyAudit.reasonPortalRawStatus : countyAudit.statePortalRawStatus;
      const status = portal === 'reason' ? countyAudit.reasonPortalStatus : countyAudit.statePortalStatus;
      return <CountyAuditStatusBadge rawStatus={rawStatus}
                                     status={status}/>;
    }
  }, [
    countyAudit,
    portal
  ]);

  return (
    <Card className="CountyAuditCard h-100 cursor-pointer"
          role="link"
          tabIndex={0}
          onClick={handleSelection}
          onKeyDown={handleSelection}>
      <CardHeader>
        <Row noGutters>
          <Col sm={8}>
            {countyAudit.county.displayNameWithType}{showYear ? ` - ${countyAudit.year}` : ''}
          </Col>
          {countyAudit.followUp && <Col sm={4}
                                        className="d-flex justify-content-end">
            Follow Up
          </Col>}
        </Row>
      </CardHeader>
      <CardBody>
        <CountyAuditCardBody/>
      </CardBody>
      <CardFooter className="d-flex justify-content-end">
        <CardBadge/>
      </CardFooter>
    </Card>
  );
};

export default CountyAuditCard;