import * as Yup from 'yup';

import * as messages from '../messages';

const deficienciesSelectedSchema = Yup.array()
  .of(Yup.object().shape({
    id: Yup.number().nullable(),
    selected: Yup.boolean()
      .nullable(),
    requiresSubDeficiencies: Yup.boolean()
      .nullable()
  }));

const followUpFormSchema = (requireYear: boolean) => Yup.object().shape({
  year: requireYear ? Yup.number()
    .required(messages.REQUIRED) : Yup.number(),
  deficiencies: deficienciesSelectedSchema,
  subDeficiencies: Yup.object().shape({
    1: deficienciesSelectedSchema,
    2: deficienciesSelectedSchema
  })
});

export default followUpFormSchema;