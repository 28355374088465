import {useFormikContext} from 'formik';

import {AssessmentRollAnalysisCard, PersonalPropertyReviewCard, ReviewOfExemptionsCard} from '../shared';
import CapResolution from './CapResolution';
import CapComments from './CapComments';
import {CapV2FormFields} from '../../types/cap';
import {reviewSheetV2Questions} from '../../schema/reviewSheet';
import {capV2Resolutions} from '../../schema/cap';

type Props = {
  formEditable: boolean
}

const CapV2Form = ({
                     formEditable
                   }: Props) => {
  const {values} = useFormikContext<CapV2FormFields>();

  return (
    <>
      <AssessmentRollAnalysisCard className="mb-4"
                                  bodyClassName="cap-resolution-group">
        <CapResolution renderCondition={values.assessmentRollAnalysis.economicConditionFactorsMet === 'No'}
                       radioFieldName="assessmentRollAnalysis.economicConditionFactorsMet"
                       radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.economicConditionFactorsMet}
                       notesFieldName="assessmentRollAnalysis.economicConditionFactorsMetNotes"
                       resolutions={capV2Resolutions.assessmentRollAnalysis.economicConditionFactorsMetResolutions}
                       resolutionFieldName="assessmentRollAnalysis.economicConditionFactorsMetResolution"
                       resolutionDateFieldName="assessmentRollAnalysis.economicConditionFactorsMetDate"
                       resolutionCommentsFieldName="assessmentRollAnalysis.economicConditionFactorsMetComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.assessmentRollAnalysis.landValueDeterminationsAppropriate === 'No'}
                       radioFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriate"
                       radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.landValueDeterminationsAppropriate}
                       notesFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateNotes"
                       resolutions={capV2Resolutions.assessmentRollAnalysis.landValueDeterminationsAppropriateResolutions}
                       resolutionFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateResolution"
                       resolutionDateFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateDate"
                       resolutionCommentsFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.assessmentRollAnalysis.cashValueAgrees === 'No'}
                       radioFieldName="assessmentRollAnalysis.cashValueAgrees"
                       radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.cashValueAgrees}
                       resolutions={capV2Resolutions.assessmentRollAnalysis.cashValueAgreesResolutions}
                       resolutionFieldName="assessmentRollAnalysis.cashValueAgreesResolution"
                       notesFieldName="assessmentRollAnalysis.cashValueAgreesNotes"
                       resolutionDateFieldName="assessmentRollAnalysis.cashValueAgreesDate"
                       resolutionCommentsFieldName="assessmentRollAnalysis.cashValueAgreesComments"
                       disabled={!formEditable}/>
      </AssessmentRollAnalysisCard>
      <PersonalPropertyReviewCard className="mb-4"
                                  bodyClassName="cap-resolution-group">
        <CapResolution renderCondition={values.personalPropertyReview.conductsAnnualPersonalPropertyCanvas === 'No'}
                       radioFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvas"
                       radioLabelText={reviewSheetV2Questions.personalPropertyReview.conductsAnnualPersonalPropertyCanvas}
                       notesFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvasNotes"
                       resolutionDateFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvasDate"
                       resolutionCommentsFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvasComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.personalPropertyReview.exemptionsProcessingRequirementMet === 'No'}
                       radioFieldName="personalPropertyReview.exemptionsProcessingRequirementMet"
                       radioLabelText={reviewSheetV2Questions.personalPropertyReview.exemptionsProcessingRequirementMet}
                       notesFieldName="personalPropertyReview.exemptionsProcessingRequirementMetNotes"
                       resolutionDateFieldName="personalPropertyReview.exemptionsProcessingRequirementMetDate"
                       resolutionCommentsFieldName="personalPropertyReview.exemptionsProcessingRequirementMetComments"
                       disabled={!formEditable}/>
      </PersonalPropertyReviewCard>
      <ReviewOfExemptionsCard className="mb-4" bodyClassName="cap-resolution-group">
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.hasPovertyExemptionGuidelines === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelines"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.hasPovertyExemptionGuidelines}
                       notesFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest}
                       notesFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed}
                       notesFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered}
                       radioButtons={[
                         {value: 'Yes', labelText: 'Yes'},
                         {value: 'N/A', labelText: 'N/A'},
                         {value: 'No', labelText: 'No'}
                       ]}
                       notesFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.ptaFilingImposed === 'No' && values.reviewOfExemptionsGranted.penaltyWaivedByResolution === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.ptaFilingImposed"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.ptaFilingImposed}
                       notesFieldName="reviewOfExemptionsGranted.ptaFilingImposedNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.ptaFilingImposedDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.ptaFilingImposedComments"
                       disabled={!formEditable}/>
        <CapResolution renderCondition={values.reviewOfExemptionsGranted.implementedCamaDataStandards === 'No'}
                       radioFieldName="reviewOfExemptionsGranted.implementedCamaDataStandards"
                       radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.implementedCamaDataStandards}
                       notesFieldName="reviewOfExemptionsGranted.implementedCamaDataStandardsNotes"
                       resolutionDateFieldName="reviewOfExemptionsGranted.implementedCamaDataStandardsDate"
                       resolutionCommentsFieldName="reviewOfExemptionsGranted.implementedCamaDataStandardsComments"
                       disabled={!formEditable}/>
      </ReviewOfExemptionsCard>
      <CapComments formEditable={formEditable}/>
    </>
  );
};

export default CapV2Form;