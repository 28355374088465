import {ReactNode, useState} from 'react';

import {User} from '@reasoncorp/kyber-js';

import {AppContext} from './AppContext';
import {LocalUnitPermission} from '../types';

type Props = {
  children: ReactNode
}

const AppProvider = ({children}: Props) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [localUnitPermissions, setLocalUnitPermissions] = useState<LocalUnitPermission[]>([]);

  const value = {
    currentUser,
    setCurrentUser,
    localUnitPermissions,
    setLocalUnitPermissions
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;