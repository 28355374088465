import {Col, Row} from 'reactstrap';

import {FormikDatePicker, FormikInput} from '@reasoncorp/kyber-js';

import {ReviewSheetQuestion} from '../reviewSheet';

type Props = {
  renderCondition: boolean
  radioFieldName: string
  radioLabelText: string
  radioButtons?: {value: string, labelText: string}[]
  notesFieldName: string
  resolutionDateFieldName: string
  resolutionCommentsFieldName: string
  disabled: boolean
  className?: string
  hasNotes?: boolean
  showDate?: boolean
  showComments?: boolean
  isMultiQuestion?: boolean
}

const CapResolutionV3 = ({
                           renderCondition,
                           radioFieldName,
                           radioLabelText,
                           radioButtons = [
                             {value: 'Yes', labelText: 'Yes'},
                             {value: 'No', labelText: 'No'}
                           ],
                           notesFieldName,
                           resolutionDateFieldName,
                           resolutionCommentsFieldName,
                           disabled,
                           className = '',
                           hasNotes = true,
                           showDate = true,
                           showComments = true,
                           isMultiQuestion = false
                         }: Props) => {
  // Only render if the render condition is true.
  if (renderCondition) {
    return (
      <Row className={isMultiQuestion ? "": "mb-4 review-sheet-v3-question-row"}>
        <Col className={isMultiQuestion ? "mt-3 ml-3 pl-3 pt-3 mr-3 review-sheet-multi-question-item" : ""}>
          <Row className={className}>
            <Col>
              <ReviewSheetQuestion radioFieldName={radioFieldName}
                                   radioLabelText={radioLabelText}
                                   radioButtons={radioButtons}
                                   notesFieldName={notesFieldName}
                                   notesLabelText="Audit Notes"
                                   notesAriaLabel="Audit Notes"
                                   formEditable={false}
                                   hasNotes={hasNotes}/>

              {showDate && <FormikDatePicker name={resolutionDateFieldName}
                                             labelText="Resolution Date"
                                             aria-label="Resolution Date mm/dd/yyyy"
                                             formGroupClass="larger-label"
                                             wrapperClassName="w-auto"
                                             disabled={disabled}/>}
              {showComments && <FormikInput name={resolutionCommentsFieldName}
                                            labelText="Resolution Comments"
                                            type="textarea"
                                            formGroupClass="larger-label"
                                            maxLength={650}
                                            aria-label="Resolution Comments"
                                            disabled={disabled}/>}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  } else {
    return null;
  }
};

export default CapResolutionV3;