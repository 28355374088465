const reviewSheetV3Questions = {
  localUnitBackground: {
    assessmentRollCertificationDate: 'Date the assessor certified the assessment roll',
    didAssessorReceiveAssistanceNotes: 'If answering yes, provide their names, and detail the work completed.',
    didAssessorReceiveAssistance: 'Other than staff listed on Form 5730, did the assessor receive outside assistance, in the form of a contract or otherwise, in the development of valuation studies and or field data collection?'
  },
  assessmentRollAnalysis: {
    landValueDeterminationsAppropriate: '1. Does the assessing district have properly developed and documented land values that meet State Tax Commission requirements per MCL 211.10e and STC Land Value Determination Publications and have less than 1% of land adjustments without reason? MCL 211.10g(1)(h)(i).',
    economicConditionFactorsMet: '2. Does the assessing district have properly developed and documented Economic Condition Factors (“ECF”) that meet State Tax Commission requirements per MCL 211.10e and STC ECF Publications? MCL 211.10g(1)(h)(iii).',
    cashValueAgrees: '3.  Does the assessing district have an assessment database for which not more than 1% of parcels are in override and less than 1% flat land values – excluding DNR PILT Property (STC Policy)? MCL 211.10g(1)(h)(ii).'
  },
  assessmentTechnicalAnalysis: {
    doesUseCama: '4. Does the assessing district use a computer-assisted mass appraisal system (CAMA) that is approved by the State Tax Commission as having sufficient software capabilities to meet requirements of the General Property Tax Act and to store and back up necessary data? MCL 211.10g(1)(b).',
    hasPublishedPolicy: '5. Does the assessing district have and follow a published policy under which its assessor’s office is reasonably accessible to taxpayers? MCL 211.10g(1)(c).',
    hasPublishedPolicyDesignation: '5a. A designation by name, telephone number, and electronic mail address, of at least one official or employee in the assessor’s office to whom taxpayer inquiries may be submitted directly by telephone or electronic mail. MCL 211.10g(1)(c)(i).',
    hasPublishedPolicyEstimatedResponseTime: '5b. An estimated response time for taxpayer inquiries submitted, not to exceed seven business days. MCL 211.10g(1)(c)(ii).',
    hasPublishedPolicyInPersonMeetingInformation: '5c. Information about how a taxpayer may arrange an in-person meeting with an official or employee of the assessor’s office for purposes of discussing an inquiry. MCL 211.10g(1)(c)(iii).',
    hasPublishedPolicyRequestsInformation: '5d. Information about how requests for inspection or production of records maintained by the assessor’s office should be made by a taxpayer and how those requests will be handled by the assessor’s office. MCL 211.10g(1)(c)(iv).',
    hasPublishedPolicyDisputeInformation: '5e. Information about any process that the assessor’s office may have to informally hear or resolve disputes brought by taxpayers before the March meeting of the Board of Review. MCL 211.10g(1)(c)(v).',
    doesProvideOnlineInformation: '6. Does the assessing district provide taxpayers online access to information regarding its assessment services, including, but not limited to, parcel information, land value studies and documentation, and economic condition factors if there is a city or township building within the assessing district in an area with broadband internet access? MCL 211.10g(1)(d).',
    doesProvideContactInformation: '7. Does the assessing district include the contact information of at least one official or employee in the assessor’s office including name, telephone number, and electronic mail address in notices to taxpayers concerning assessment changes and exemption determinations? MCL 211.10g(1)(e).',
    doesTrainSupportStaff: '8. Does the assessing district ensure that its support staff is sufficiently trained to respond to taxpayer inquiries, require that its assessors maintain their certification levels, and require that its Board of Review members receive training and updates required and approved by the State Tax Commission? MCL 211.10g(1)(f).',
    doesComplyWithFeeCollection: '9. Does the assessing district comply with MCL 211.44(4) in respect to any property tax administration fee collected under section MCL 211.44? MCL 211.10g(1)(g).',
    doesConductCanvass: '10. Does the assessing district conduct an annual personal property canvass and maintain sufficient personal property records according to developed State Tax Commission policies and statutory requirements? MCL 211.10g(1)(h)(iv).',
    doesBoardReviewMeetRequirements: '11. Does a sample of the July and/or December Board of Review actions indicate the Board met the requirements of MCL 211.53b and considered only those items over which they have statutory authority? MCL 211.10g(1)(h)(v).',
    doesHaveAdequateProcess: '12. Does the assessing district have an adequate process for determining whether to grant or deny exemptions according to statutory requirements? MCL 211.10g(1)(h)(vi).',
    doesMeetSTCRequirements: '13. Does the assessing district meet the requirements outlined in the State Tax Commission’s publication entitled, “Supervising Preparation of the Assessment Roll,” including, but not limited to, the submission of all required forms, attendance at all required meetings, fulfilling the requirements of the assessment roll, appraising and assessing taxable property including new construction, and maintenance of accurate record cards? MCL 211.10g(1)(h)(vii).',
    wasAuditorMadeAware: '13a. Was the auditor made aware of possible record card issues or inaccuracies by County Equalization, the local unit, the State Tax Commission, or the Michigan Tax Tribunal?',
    reviewOfExemptionsGranted: '14. Review of Exemptions Granted under MCL 211.7u (Poverty Exemptions) MCL 211.10g(i).',
    didLocalUnitGrantPovertyExemptions: '14a. Did the local unit grant any exemptions under MCL 211.7u (Poverty Exemption)?',
    doesLocalUnitHavePovertyExemptionGuidelines: '14b. Does the local unit have proper Poverty Exemption guidelines?',
    doesLocalUnitHavePovertyExemptionGuidelinesAssetLevelTest: '14c. Do the local unit Poverty Exemption guidelines include a proper asset level test?',
    doesSamplingMeetRequirements: '14d. Does a sampling of the exemptions granted under MCL 211.7u indicate that the statutory requirements were met and that the local unit policy was followed?',
    hasImplementedCamaStandards: '15. Has the local unit implemented CAMA Data Standards as currently adopted by the State Tax Commission? MCL 211.10g(i).'
  }
};

export default reviewSheetV3Questions;