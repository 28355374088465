import {createContext} from 'react';
import {LocalUnitPermission} from '../types';

export type AppContextType = {
  localUnitPermissions: LocalUnitPermission[]
  setLocalUnitPermissions: (localUnitAuditPermissions: LocalUnitPermission[]) => void
}

export const AppContext = createContext<AppContextType>({
  localUnitPermissions: [],
  setLocalUnitPermissions: (localUnitAuditPermissions: LocalUnitPermission[]) => localUnitAuditPermissions
});