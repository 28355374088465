import reviewSheetV1Questions from './reviewSheetV1Questions';

// A question 8 was added to the Review of Exemptions Granted section in version 2. There were no other changes.
const reviewSheetV2Questions = {
  ...reviewSheetV1Questions,
  reviewOfExemptionsGranted: {
    ...reviewSheetV1Questions.reviewOfExemptionsGranted,
    implementedCamaDataStandards: '8. Has the local unit implemented CAMA Data Standards as adopted by the State Tax Commission on April 6, 2021?'
  }
};

export default reviewSheetV2Questions;