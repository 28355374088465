import {Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from 'reactstrap';
import {FieldInputProps, Formik, FormikHelpers, FormikProps} from 'formik';

import {ButtonIcon, FormikInput, FormikMultipleFileInput, StatefulButton} from '@reasoncorp/kyber-js';

import {fileUploadSchema} from '../../schema';
import {FilingCabinetUpload} from '../../types';
import {useCallback, useMemo} from 'react';

type Props = {
  isOpen: boolean
  onSubmit: (filingCabinetUpload: FilingCabinetUpload, actions: FormikHelpers<FilingCabinetUpload>) => void
  onToggle: () => void
}

const FileUploadModal = ({
                           isOpen,
                           onSubmit,
                           onToggle
                         }: Props) => {
  const handleToggle = useCallback((formikProps: FormikProps<FilingCabinetUpload>) => {
    onToggle();
    formikProps.resetForm();
  }, [onToggle]);

  const initialValues: FilingCabinetUpload = useMemo(() => ({
    files: [],
    descriptions: []
  }), []);

  const handleChangeComplete = useCallback((formikProps: FormikProps<any>, files: File[]) => {
    let descriptions = [...formikProps.values.descriptions];
    for (let i = 0; i <= files.length - descriptions.length; i++) {
      descriptions.unshift('');
    }
    formikProps.setFieldValue('descriptions', descriptions);
  }, []);

  const renderFiles = useMemo(() => ({
                                       files,
                                       removeFile,
                                       field
                                     }: {
    files: File[],
    removeFile: (index: number, field: FieldInputProps<any>) => void,
    field: FieldInputProps<any>
  }) => {
    return <div className="FormikMultipleFileInputFiles">
      <Table bordered responsive>
      <thead>
        <tr>
          <th className="w-40">File Name</th>
          <th>Description</th>
          <th className="w-10 text-center">Remove</th>
        </tr>
      </thead>
      <tbody>
        {files.length === 0 && <tr>
          <td colSpan={3}>No files selected.</td>
        </tr>}
        {files.map((file: File, index: number) => {
          return <tr key={`file-${index}`}>
            <td>
              {file.name}
            </td>
            <td>
              <FormikInput name={`descriptions[${index}]`}
                           placeholder="Enter your description here"
                           ariaLabel={`${file.name} description`}/>
            </td>
            <td className="align-middle text-center">
              <ButtonIcon className="text-danger"
                          title="Remove"
                          icon="trash"
                          onClick={() => removeFile(index, field)}
                          ariaLabel="Remove"/>
            </td>
          </tr>;
        })}
      </tbody>
    </Table>
    </div>
  }, []);

  const handleFileRemove = useCallback((formikProps: FormikProps<any>) => (index: number) => {
    const descriptionsCopy = [...formikProps.values.descriptions];
    descriptionsCopy.splice(index, 1);
    formikProps.setFieldValue('descriptions', descriptionsCopy);
  }, []);

  return (
    <Formik initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={fileUploadSchema}
            validateOnMount={true}
            onSubmit={onSubmit}>
      {(formikProps) => {
        return (
          <Modal isOpen={isOpen}
                 size="xl"
                 aria-modal={true}
                 toggle={() => handleToggle(formikProps)}
                 returnFocusAfterClose={true}
                 autoFocus={false}>
            <ModalHeader className="h5" tag="h2">
              Upload Files
            </ModalHeader>
            <ModalBody className="pt-2">
              <Form onSubmit={formikProps.handleSubmit}>
                <Row>
                  <Col>
                    <FormikMultipleFileInput name="files"
                                             aria-label="Select Files"
                                             buttonText="Select Files"
                                             bsSize="lg"
                                             disabled={formikProps.values.files.length >= 5}
                                             onChangeComplete={(files: File[]) => handleChangeComplete(formikProps, files)}
                                             onFileRemove={handleFileRemove(formikProps)}
                                             renderFiles={renderFiles}/>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <StatefulButton className="mr-1"
                              color="success"
                              disabled={!formikProps.isValid || !formikProps.dirty || formikProps.isSubmitting}
                              onClick={formikProps.submitForm}
                              aria-label="Upload Button"
                              activeState={formikProps.isSubmitting ? 'uploading' : 'default'}
                              states={[
                                {name: 'uploading', text: 'Uploading', icon: 'spinner', spinIcon: true},
                                {name: 'default', text: 'Upload'}
                              ]}/>
              <Button color="secondary"
                      onClick={() => handleToggle(formikProps)}
                      disabled={formikProps.isSubmitting}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default FileUploadModal;