import {ReactNode} from 'react';
import {Card, CardBody, CardHeader} from 'reactstrap';

import {LocalUnitAudit, LocalUnitContactInfo} from '../../types';
import ReviewSheetContactInfoV3 from './ReviewSheetContactInfoV3';

type Props = {
  className?: string
  showContactInfo: boolean
  localUnitAudit: LocalUnitAudit
  localUnitContactInfo?: LocalUnitContactInfo
  children: ReactNode
}

const LocalUnitBackgroundV3Card = ({
                                     className,
                                     showContactInfo = false,
                                     localUnitAudit,
                                     localUnitContactInfo,
                                     children
                                   }: Props) => {
  return (
    <Card className={className}>
      <CardHeader className="font-weight-bold">Local Unit Background Information</CardHeader>
      {showContactInfo && localUnitContactInfo &&
        <ReviewSheetContactInfoV3 auditYear={localUnitAudit.year}
                                  localUnitName={localUnitAudit.localUnit.displayNameWithType}
                                  countyName={localUnitAudit.county.displayNameWithType}
                                  localUnitContactInfo={localUnitContactInfo}/>
      }
      <CardBody>
        {children}
      </CardBody>
    </Card>
  );
};

export default LocalUnitBackgroundV3Card;