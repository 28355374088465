import {ReactNode} from 'react';
import {Col, Row} from 'reactstrap';

type Props = {
  className: string
  children: ReactNode
  headerText: string
  renderCondition?: boolean
}

const ReviewSheetMultiQuestion = ({
                                    className,
                                    children,
                                    headerText,
                                    renderCondition = true
                                  }: Props) => {
  return renderCondition ? <div className={`${className} review-sheet-multi-question`}>
    <Row className="review-sheet-multi-question-header mb-2">
      <Col className="font-weight-bold text-primary">
        {headerText}
      </Col>
    </Row>
    {children}
  </div> : null;
};

export default ReviewSheetMultiQuestion;
