import {cloneDeep} from 'lodash';

const trimAndNullValues = (object: any) => {
  const newObject = cloneDeep(object);
  Object.keys(newObject).forEach(key => {
    if (typeof newObject[key] === 'object' && newObject[key] !== null && !(newObject[key] instanceof File)) {
      newObject[key] = trimAndNullValues(newObject[key]);
    } else if (newObject[key] === '') {
      newObject[key] = null;
    } else if (typeof newObject[key] === 'string') {
      newObject[key] = newObject[key].trim();
    }
  });
  return newObject;
};

const formUtils = {
  trimAndNullValues
};

export default formUtils;