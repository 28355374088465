import {memo, useMemo} from 'react';

import {MiSuiteRole} from '@reasoncorp/kyber-js';

import {LocalUnitContactInfo} from '../../types';

type Props = {
  auditYear: number
  localUnitName: string
  countyName: string
  localUnitContactInfo: LocalUnitContactInfo
}

const ReviewSheetContactInfo = ({
                                  auditYear,
                                  localUnitName,
                                  countyName,
                                  localUnitContactInfo
                                }: Props) => {
  // Meant to handle case where Township Supervisor should be shown as Supervisor
  const roleDisplayValue = useMemo(() => {
    const role = localUnitContactInfo.manager?.role;
    switch (role) {
      case MiSuiteRole.TOWNSHIP_SUPERVISOR:
        return 'Supervisor';
      case MiSuiteRole.CITY_MANAGER:
        return 'City Manager';
      case MiSuiteRole.MAYOR:
        return 'Mayor';
      default:
        return null;
    }
  }, [
    localUnitContactInfo
  ]);

  const addressDisplayValue = useMemo(() => {
    return localUnitContactInfo.manager?.address ?
      `${localUnitContactInfo.manager?.address.addressLine1}, ${localUnitContactInfo.manager?.address.addressLine2}`
      :
      null
  }, [
    localUnitContactInfo
  ]);

  return (
    <div>
      <p>
        <span className="mr-1">Year of Audit:</span>
        <span className="font-weight-bold">{auditYear}</span>
      </p>
      <p>
        <span className="mr-1">Name of Local Unit:</span>
        <span className="font-weight-bold">{localUnitName}</span>
      </p>
      <p>
        <span className="mr-1">Name of County:</span>
        <span className="font-weight-bold">{countyName}</span>
      </p>
      <p>
        <span className="mr-1">Name of Assessor:</span>
        <span className="font-weight-bold">{localUnitContactInfo.assessorOfRecord?.fullName}</span>
      </p>
      <p>
        <span className="mr-1">Assessor Certification Level:</span>
        <span className="font-weight-bold">{localUnitContactInfo.assessorOfRecord?.certificationLevel}</span>
      </p>
      <p>
        <span className="mr-1">Name of Supervisor, City Manager or Mayor:</span>
        <span className="font-weight-bold">{localUnitContactInfo.manager?.fullName}</span>
      </p>
      <p>
        <span className="mr-1">Title:</span>
        <span className="font-weight-bold">{roleDisplayValue}</span>
      </p>
      <p className="mb-0">
        <span className="mr-1">Mailing Address for Supervisor, City Manager, or Mayor:</span>
        <span className="font-weight-bold">
          {addressDisplayValue}
        </span>
      </p>
      <hr/>
    </div>
  );
};

export default memo(ReviewSheetContactInfo);