import {useMemo} from 'react';
import {Badge} from 'reactstrap';

import {CountyAuditStatus} from '../enum';

type Props = {
  rawStatus: string
  status: string
}

const CountyAuditStatusBadge = ({
                                  rawStatus,
                                  status
                                }: Props) => {
  const color = useMemo(() => {
    if ([
      CountyAuditStatus.NOT_STARTED,
      CountyAuditStatus.IN_PROGRESS
    ].includes(rawStatus as CountyAuditStatus)) {
      return 'light';
    } else if ([
      CountyAuditStatus.IN_REVIEW,
      CountyAuditStatus.PENDING,
      CountyAuditStatus.RECEIVED
    ].includes(rawStatus as CountyAuditStatus)) {
      return 'danger';
    } else {
      return 'success';
    }
  }, [rawStatus]);

  return (
    <Badge tag="button"
           aria-label="View"
           className="border-0 text-uppercase"
           color={color}
           tabIndex={-1}>
      {status}
    </Badge>
  );
};

export default CountyAuditStatusBadge;