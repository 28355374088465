import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Button, Col, Container, Row} from 'reactstrap';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  ProgressIndicator,
  useAlerts,
  useUserContext,
  windowUtils
} from '@reasoncorp/kyber-js';

import {countyAuditApi, localUnitAuditApi} from '../api';
import * as messages from '../messages';
import {CountyAuditStatus, LocalUnitAuditStatus} from '../enum';
import {CountyAudit, ExcludeIncludeModalState, Portal} from '../types';
import {LocalUnitAuditsCard} from '../components';

type Props = {
  portal: Portal
}

const CountyAuditDetails = ({
                              portal
                            }: Props) => {
  const params = useParams() as {countyAuditId: string};
  const countyAuditId = useMemo(() => parseInt(params.countyAuditId), [params.countyAuditId]);
  const {permissions} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [countyAudit, setCountyAudit] = useState<CountyAudit | undefined>(undefined);
  const [displaySendUnitNoticesModal, setDisplaySendUnitNoticesModal] = useState(false);
  const [displaySubmitToStateModal, setDisplaySubmitToStateModal] = useState(false);
  const [excludeIncludeModalState, setExcludeIncludeModalState] = useState<ExcludeIncludeModalState>({
    isOpen: false,
    action: 'exclude',
    localUnitAuditId: 0,
    localUnitName: '',
    submitting: false
  });
  const [rollbackModalState, setRollbackModalState] = useState({
    isOpen: false,
    localUnitAuditId: 0,
    localUnitName: '',
    submitting: false
  });
  const [sendingUnitNotices, setSendingUnitNotices] = useState(false);
  const [submittingCounty, setSubmittingCounty] = useState(false);

  const breadcrumbs = useMemo(() => countyAudit ? [{
      text: 'Dashboard',
      icon: 'home' as IconProp,
      route: `/${portal}-portal`
    }, {
      text: `${countyAudit.county.displayNameWithType} Local Units`,
      active: true
    }]
    :
    [], [
    portal,
    countyAudit
  ]);

  const handleExcludeIncludeConfirmation = useCallback(async () => {
    try {
      setExcludeIncludeModalState(excludeIncludeModalState => ({...excludeIncludeModalState, submitting: false}));
      if (excludeIncludeModalState.action === 'exclude') {
        await localUnitAuditApi.exclude(excludeIncludeModalState.localUnitAuditId);
      } else {
        await localUnitAuditApi.include(excludeIncludeModalState.localUnitAuditId);
      }
      const updatedCountyAudit = await countyAuditApi.findById(countyAuditId);
      setCountyAudit(updatedCountyAudit);
      showSuccessAlert(messages.INCLUDE_EXCLUDE_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.INCLUDE_EXCLUDE_FAILURE);
    }

    setExcludeIncludeModalState(excludeIncludeModalState => ({...excludeIncludeModalState, isOpen: false}));
  }, [
    countyAuditId,
    excludeIncludeModalState,
    showSuccessAlert,
    showErrorAlert
  ]);

  const handleRollbackConfirmation = useCallback(async () => {
    setRollbackModalState({...rollbackModalState, submitting: true});
    try {
      await localUnitAuditApi.rollback(rollbackModalState.localUnitAuditId);
      const countyAudit = await countyAuditApi.findById(countyAuditId);
      setCountyAudit(countyAudit);
      showSuccessAlert(messages.ROLLBACK_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.ROLLBACK_FAILURE);
    }

    setRollbackModalState({...rollbackModalState, isOpen: false, submitting: false});
  }, [
    countyAuditId,
    rollbackModalState,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleSendUnitNoticesConfirmation = useCallback(async () => {
    try {
      setSendingUnitNotices(true);
      const countyAudit = await countyAuditApi.sendNotices(countyAuditId);
      setCountyAudit(countyAudit);
      showSuccessAlert(messages.UNIT_NOTIFICATION_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.UNIT_NOTIFICATION_FAILURE);
    } finally {
      setSendingUnitNotices(false);
      setDisplaySendUnitNoticesModal(false);
    }
  }, [
    countyAuditId,
    showErrorAlert,
    showSuccessAlert
  ]);

  const handleSubmitCountyAuditConfirmation = useCallback(async () => {
    try {
      setSubmittingCounty(true);
      const countyAudit = await countyAuditApi.submit(countyAuditId);
      showSuccessAlert(messages.REVIEW_SHEET_SUBMIT_SUCCESSFUL);
      setCountyAudit(countyAudit);
    } catch (error) {
      showErrorAlert(messages.REVIEW_SHEET_SUBMIT_FAILURE);
    } finally {
      setSubmittingCounty(false);
      setDisplaySubmitToStateModal(false);
    }
  }, [
    countyAuditId,
    showErrorAlert,
    showSuccessAlert
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const countyAudit = await countyAuditApi.findById(countyAuditId);
        setCountyAudit(countyAudit);
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [countyAuditId, showErrorAlert]);

  const disableSendNoticeButton = useMemo(() => {
    return !countyAudit ||
      countyAudit.unitNoticeSentOn !== null ||
      countyAudit.submittedOn !== null ||
      countyAudit.localUnitAudits.every(lua => lua.rawStatus === LocalUnitAuditStatus.EXCLUDED);
  }, [
    countyAudit
  ]);

  const shouldDisplayNoticeButtons = useMemo(() => {
    return portal === 'reason' &&
      (permissions.isBranchManager || permissions.isAuditSupport) &&
      countyAudit &&
      !countyAudit.offline;
  }, [
    portal,
    permissions,
    countyAudit
  ]);

  const handleViewUnitNoticeButtonClick = useCallback(async () => {
    try {
      await windowUtils.openFileInNewWindow(countyAuditApi.findUnitNoticeDocument(countyAuditId));
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT);
    }
  }, [
    countyAuditId,
    showErrorAlert
  ]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="CountyAuditDetails">
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          {countyAudit &&
            <>
              <Row className="mb-3">
                <Col md="6">
                  <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                </Col>
                <Col md="6" className="d-flex justify-content-end">
                  {shouldDisplayNoticeButtons && <Button color="primary"
                                                         className="mr-2"
                                                         disabled={!countyAudit?.unitNoticeSentOn}
                                                         onClick={() => handleViewUnitNoticeButtonClick()}>
                    View Unit Notice PDFs
                  </Button>}
                  {shouldDisplayNoticeButtons && <Button color="primary"
                                                         disabled={disableSendNoticeButton}
                                                         onClick={() => setDisplaySendUnitNoticesModal(true)}>
                    Send Unit Notice
                  </Button>}
                </Col>
              </Row>
              <LocalUnitAuditsCard countyAudit={countyAudit}
                                   portal={portal}
                                   onRollback={setRollbackModalState}
                                   onExclude={setExcludeIncludeModalState}/>
              {countyAudit && portal === 'reason' && permissions.isBranchManager &&
                <Row className="mt-3">
                  <Col className="d-flex justify-content-end">
                    <Button color="success"
                            disabled={loadingState.loading || countyAudit.reasonPortalRawStatus !== CountyAuditStatus.PENDING}
                            onClick={() => setDisplaySubmitToStateModal(true)}>
                      Submit
                    </Button>
                  </Col>
                </Row>}

              <ConfirmationModal isOpen={excludeIncludeModalState.isOpen}
                                 title={excludeIncludeModalState.action === 'exclude' ? 'Confirm Exclude' : 'Confirm Include'}
                                 confirmButtonText="Yes"
                                 confirmButtonDisabled={excludeIncludeModalState.submitting}
                                 confirmCallback={handleExcludeIncludeConfirmation}
                                 cancelButtonText="No"
                                 cancelButtonDisabled={excludeIncludeModalState.submitting}
                                 cancelCallback={() => setExcludeIncludeModalState((excludeIncludeModalState) => ({
                                   ...excludeIncludeModalState,
                                   isOpen: false
                                 }))}>
                <p>
                  {excludeIncludeModalState.action === 'exclude' && <>
                    Are you sure you want to exclude <span className="text-danger">{excludeIncludeModalState.localUnitName}</span> from this audit cycle?
                  </>}
                  {excludeIncludeModalState.action !== 'exclude' && <>
                    Are you sure you want to include <span className="text-danger">{excludeIncludeModalState.localUnitName}</span> in this audit cycle?
                  </>}
                </p>
              </ConfirmationModal>

              <ConfirmationModal isOpen={rollbackModalState.isOpen}
                                 title="Confirm Roll Back"
                                 confirmButtonText="Yes"
                                 confirmButtonDisabled={rollbackModalState.submitting}
                                 confirmCallback={handleRollbackConfirmation}
                                 cancelButtonText="No"
                                 cancelButtonDisabled={rollbackModalState.submitting}
                                 cancelCallback={() => setRollbackModalState((rollbackModalState) => ({
                                   ...rollbackModalState,
                                   isOpen: false
                                 }))}>
                <p>
                  Are you sure you want to rollback <span className="text-danger">{rollbackModalState.localUnitName}</span> into the received status?
                </p>
              </ConfirmationModal>
              <ConfirmationModal isOpen={displaySendUnitNoticesModal}
                                 title="Send Unit Notice"
                                 confirmButtonText={sendingUnitNotices ? 'Sending' : 'Yes'}
                                 confirmButtonIcon={sendingUnitNotices ? 'spinner' as IconProp : undefined}
                                 confirmButtonIconSpin={sendingUnitNotices}
                                 confirmButtonDisabled={sendingUnitNotices}
                                 confirmCallback={handleSendUnitNoticesConfirmation}
                                 cancelButtonDisabled={sendingUnitNotices}
                                 cancelCallback={() => setDisplaySendUnitNoticesModal(false)}>
                <p>
                  Are you sure you want to send the Unit Notification for <span className="text-danger">{countyAudit.county.displayNameWithType}</span>?
                </p>
              </ConfirmationModal>
              <ConfirmationModal isOpen={displaySubmitToStateModal}
                                 title="Submit PA 660 Audits"
                                 confirmButtonText={submittingCounty ? 'Submitting' : 'Yes'}
                                 confirmButtonIcon={submittingCounty ? 'spinner' as IconProp : undefined}
                                 confirmButtonIconSpin={submittingCounty}
                                 confirmButtonDisabled={submittingCounty}
                                 confirmCallback={handleSubmitCountyAuditConfirmation}
                                 cancelButtonDisabled={submittingCounty}
                                 cancelCallback={() => setDisplaySubmitToStateModal(false)}>
                <p>
                  Are you sure you want to submit all PA 660 Audits for <span className="text-danger">{countyAudit.county.displayNameWithType}</span>?
                </p>
              </ConfirmationModal>
            </>
          }
        </>
        }
      </Container>
    );
  }
};

export default CountyAuditDetails;