import { useCallback } from 'react';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import {useFormikContext} from 'formik';

import {FormikCheckboxGroup, FormikDatePicker, FormikInput, UserPermissions} from '@reasoncorp/kyber-js';

import {BaseReviewSheet} from '../../types/reviewSheet';

type Props = {
  formEditable: boolean
  permissions: UserPermissions
}

const ReviewSheetSignature = ({
                                formEditable,
                                permissions
                              }: Props) => {
  const formikContext = useFormikContext<BaseReviewSheet>();

  const handleToggleCallback = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue = e.target.checked ? new Date() : undefined;
      void formikContext.setFieldValue('signature.date', fieldValue);
    },
    [formikContext]
  );

  if (permissions.isBranchManager || permissions.isAuditSupport) {
    return (
      <Card>
        <CardHeader>Signature</CardHeader>
        <CardBody>
          <Row>
            <Col>
              <p className="text-secondary">I hereby declare that the foregoing information submitted is a complete and true statement.</p>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col sm={12} md={9} lg={10}>
              <FormikInput name="signature.name"
                           labelText="Signature"
                           formGroupClass="larger-label"
                           disableFloatingLabel={true}
                           disabled={true}/>
            </Col>
            <Col sm={12} md={3} lg={2} className="d-flex justify-content-md-end">
              <FormikDatePicker name="signature.date"
                                labelText="Signature Date"
                                formGroupClass="larger-label"
                                ariaLabel="Signature mm/dd/yyyy"
                                disabled={true}/>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={2} lg={1}>
              <FormikCheckboxGroup checkboxes={[{
                                      name: 'signature.attestationConfirmation', 
                                      labelText: 'Agree', 
                                      onChange: handleToggleCallback
                                    }]}
                                   disabled={!formEditable} />
            </Col>
            <Col sm={12} md={10} lg={11}>
              <p className={`d-inline ${formikContext.errors?.signature ? 'text-danger' : 'text-secondary'}`}>
                I agree and confirm that the signature I have typed above is the electronic representation of my original,
                handwritten signature when used on this document and creates a legally-binding contract. I further understand
                that signing this document using my electronic signature will have the same legally-binding effect as signing
                my signature using pen and paper.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default ReviewSheetSignature;