import {pa660Api} from './apiUtils';
import {CountyAudit, Portal} from '../types';

export const findOpen = (portal: Portal): Promise<CountyAudit[]> => pa660Api.getWithJsonResponse(
  '/county-audits',
  {
    queryParams: {open: 'true', portal: portal}
  }
);

export const findByYear = (auditYear: number, portal: Portal): Promise<CountyAudit[]> => pa660Api.getWithJsonResponse(
  '/county-audits',
  {
    queryParams: {auditYear, portal}
  }
);

export const findById = (countyAuditId: number): Promise<CountyAudit> => pa660Api.getWithJsonResponse(
  `/county-audits/${countyAuditId}`
);

export const submit = (countyAuditId: number): Promise<CountyAudit> => pa660Api.patchWithJsonResponse(
  `/county-audits/${countyAuditId}/submit`
);

export const sendNotices = (countyAuditId: number): Promise<CountyAudit> => pa660Api.patchWithJsonResponse(
  `/county-audits/${countyAuditId}/send-notice`
);

export const findUnitNoticeDocument = (countyAuditId: number): Promise<string> => pa660Api.getWithTextResponse(
  `/county-audits/${countyAuditId}/notice`
)