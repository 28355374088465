import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Col, Container, Form, Row} from 'reactstrap';
import {Formik, FormikHelpers} from 'formik';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {
  BreadcrumbsNav,
  ConfirmationModal,
  ProgressIndicator,
  useAlerts,
  useUserContext,
  windowUtils
} from '@reasoncorp/kyber-js';

import * as messages from '../../messages';
import {auditHistoryApi, capApi, reviewSheetApi, localUnitAuditApi} from '../../api';
import {FollowUpModal} from '../../components/shared';
import {ReviewSheetV1Form, ReviewSheetV2Form, ReviewSheetV3Form} from '../../components/reviewSheet';
import {FollowUpDto, FollowUpFormFields, LocalUnitAudit} from '../../types';
import {reviewSheetUtils} from '../../schema/reviewSheet';

const ReviewSheetStateView = () => {
  const navigate = useNavigate();
  const params = useParams() as {countyAuditId: string, localUnitAuditId: string};
  const [countyAuditId, localUnitAuditId] = useMemo(() => ([
    parseInt(params.countyAuditId),
    parseInt(params.localUnitAuditId)
  ]), [params]);
  const {permissions} = useUserContext();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnitAudit, setLocalUnitAudit] = useState<LocalUnitAudit | undefined>(undefined);
  const [displayRequestCapModal, setDisplayRequestCapModal] = useState(false);
  const [displayNoticeModal, setDisplayNoticeModal] = useState(false);
  const [displayAcceptModal, setDisplayAcceptModal] = useState(false);
  const [displayAcceptWithTechnicalIssuesModal, setDisplayAcceptWithTechnicalIssuesModal] = useState(false);
  const [displaySendNoticeModal, setDisplaySendNoticeModal] = useState(false);
  const [displayAssumeModal, setDisplayAssumeModal] = useState(false);
  const [displayCapRequestFollowUpModal, setDisplayCapRequestFollowUpModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFailureFollowUpModalOpen, setIsFailureFollowUpModalOpen] = useState(false);

  const breadcrumbs = useMemo(() => ([{
    text: 'Dashboard',
    icon: 'home' as IconProp,
    route: `/state-portal`
  }, {
    text: `${localUnitAudit?.county?.displayNameWithType} Local Units`,
    route: `/state-portal/county-audits/${countyAuditId}`
  }, {
    text: `${localUnitAudit?.localUnit?.displayNameWithType} Assessment Roll Audit`,
    active: true
  }]), [countyAuditId, localUnitAudit]);


  useEffect(() => {
    const loadLocalUnitAudit = async () => {
      try {
        const localUnitAudit = await localUnitAuditApi.findById(localUnitAuditId);
        // If user directly enters URL and the audit is done send them back to previous breadcrumb
        if (localUnitAudit.completed) {
          navigate(`/state-portal/county-audits/${countyAuditId}`);
        } else {
          setLocalUnitAudit(localUnitAudit);
          setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
        }
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadLocalUnitAudit();
  }, [navigate, countyAuditId, localUnitAuditId, showErrorAlert]);

  const handleViewPdf = useCallback(async () => {
    await windowUtils.openFileInNewWindow(
      auditHistoryApi.findReviewSheetDocument(localUnitAuditId),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [localUnitAuditId, showErrorAlert]);

  const handleRequestCap = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await capApi.request(localUnitAuditId);
      showSuccessAlert(messages.CAP_REQUEST_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setIsSubmitting(false);
      setDisplayRequestCapModal(false);
      showErrorAlert(messages.CAP_REQUEST_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleFailureLetter = useCallback(async (followUpDto: FollowUpDto) => {
    try {
      setIsSubmitting(true);
      await localUnitAuditApi.fail(localUnitAuditId, followUpDto);
      showSuccessAlert(messages.FAILURE_LETTER_SUCCESS);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      showErrorAlert(messages.FAILURE_LETTER_FAILURE);
    } finally {
      setIsSubmitting(false);
      setIsFailureFollowUpModalOpen(false);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleFollowUpRequestCap = useCallback(async (followUpDto: FollowUpDto,
                                                      formikHelpers: FormikHelpers<FollowUpFormFields>) => {
    try {
      setIsSubmitting(true);
      const localUnitAudit = await capApi.followUpRequest(localUnitAuditId, followUpDto);
      setLocalUnitAudit(localUnitAudit);
      showSuccessAlert(messages.CAP_REQUEST_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      formikHelpers?.resetForm();
      setIsSubmitting(false);
      setDisplayRequestCapModal(false);
      showErrorAlert(messages.CAP_REQUEST_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleAccept = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await reviewSheetApi.accept(localUnitAuditId);
      showSuccessAlert(messages.REVIEW_SHEET_ACCEPT_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setIsSubmitting(false);
      setDisplayAcceptModal(false);
      showErrorAlert(messages.REVIEW_SHEET_ACCEPT_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleAcceptWithTechnicalIssues = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await reviewSheetApi.acceptWithTechnicalIssues(localUnitAuditId);
      showSuccessAlert(messages.REVIEW_SHEET_ACCEPT_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setIsSubmitting(false);
      setDisplayAcceptWithTechnicalIssuesModal(false);
      showErrorAlert(messages.REVIEW_SHEET_ACCEPT_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleSendNotice = useCallback(async () => {
    try {
      setIsSubmitting(true);
      await localUnitAuditApi.sendNotice(localUnitAuditId);
      showSuccessAlert(messages.SEND_NOTICE_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setIsSubmitting(false);
      setDisplaySendNoticeModal(false);
      showErrorAlert(messages.SEND_NOTICE_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  const handleAssume = useCallback(async (followUpDto: FollowUpDto) => {
    try {
      await localUnitAuditApi.assume(localUnitAuditId, followUpDto);
      showSuccessAlert(messages.ASSUME_SUCCESSFUL);
      navigate(`/state-portal/county-audits/${countyAuditId}`);
    } catch (error) {
      setDisplaySendNoticeModal(false);
      showErrorAlert(messages.ASSUME_FAILURE);
    }
  }, [countyAuditId, localUnitAuditId, navigate, showErrorAlert, showSuccessAlert]);

  // Update this code as new versions of the review sheet come about
  const ReviewSheetCard = useMemo(() => () => {
    if (!localUnitAudit) {
      return null;
    } else if (localUnitAudit.reviewSheet.version === 'v1') {
      return <ReviewSheetV1Form formEditable={false}
                                localUnitAudit={localUnitAudit}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v2') {
      return <ReviewSheetV2Form formEditable={false}
                                localUnitAudit={localUnitAudit}/>;
    } else if (localUnitAudit.reviewSheet.version === 'v3') {
      return <ReviewSheetV3Form formEditable={false}
                                localUnitAudit={localUnitAudit}/>;
    } else {
      return null;
    }
  }, [localUnitAudit]);

  const handleRequestCapClick = useCallback(() => {
    if (localUnitAudit?.followUp) {
      setDisplayCapRequestFollowUpModal(true);
    } else {
      setDisplayRequestCapModal(true);
    }
  }, [localUnitAudit?.followUp]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="CountyAuditDetails">
        {(loadingState.loading || !localUnitAudit) && <ProgressIndicator/>}
        {!loadingState.loading && localUnitAudit && <>
          <Formik initialValues={reviewSheetUtils.getInitialValues(localUnitAudit.reviewSheet)}
                  validationSchema={reviewSheetUtils.getValidationSchema(localUnitAudit.reviewSheet)}
                  onSubmit={() => void 0}>
            {() => (
              <Form autoComplete="off">
                <Row className="mb-3">
                  <Col md="6">
                    <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
                  </Col>
                  <Col md="6" className="d-flex justify-content-end">
                    <Button color="primary"
                            aria-label="View PDF"
                            onClick={() => handleViewPdf()}>
                      View PDF
                    </Button>
                  </Col>
                </Row>
                <ReviewSheetCard/>
                {permissions.isStateAdmin &&
                  <>
                    <Row className="pt-4">
                      <Col className="d-flex justify-content-end">
                        <Button className="mr-1"
                                color="secondary"
                                onClick={() => navigate(`/state-portal/county-audits/${countyAuditId}`)}
                                aria-label="Cancel">
                          Cancel
                        </Button>
                        <Button className="mr-1"
                                color="danger"
                                onClick={() => handleRequestCapClick()}
                                aria-label="Request Cap">
                          Request CAP
                        </Button>
                        {localUnitAudit?.followUp && <Button className="mr-1"
                                color="danger"
                                onClick={() => setIsFailureFollowUpModalOpen(true)}
                                aria-label="Failure"
                                disabled={localUnitAudit.hasCap}>
                          Failure
                        </Button>}
                        <Button className="mr-1"
                                color="danger"
                                onClick={() => setDisplayNoticeModal(true)}
                                aria-label="Notice"
                                disabled={localUnitAudit.noticeBy !== null}>
                          Notice
                        </Button>
                        <Button className="mr-1"
                                color="danger"
                                onClick={() => setDisplayAssumeModal(true)}
                                aria-label="Assume"
                                hidden={localUnitAudit.noticeBy === null}>
                          Assume
                        </Button>
                        <Button color="success"
                                className="mr-1"
                                onClick={() => setDisplayAcceptModal(true)}
                                aria-label="Accept">
                          Accept
                        </Button>
                        <Button color="success"
                                onClick={() => setDisplayAcceptWithTechnicalIssuesModal(true)}
                                aria-label="Technical Issues">
                          Technical Issues
                        </Button>
                      </Col>
                    </Row>
                    <ConfirmationModal isOpen={displayRequestCapModal}
                                       title="Request Corrective Action Plan"
                                       confirmButtonText="Yes"
                                       confirmButtonColor="success"
                                       cancelButtonText="No"
                                       cancelButtonDisabled={isSubmitting}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelCallback={() => setDisplayRequestCapModal(false)}
                                       confirmCallback={handleRequestCap}>
                      <p>
                        Are you sure you want to request a Corrective Action Plan for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal isOpen={displayNoticeModal}
                                       title="Send 21 Day Notice"
                                       confirmButtonText="Yes"
                                       confirmButtonColor="success"
                                       cancelButtonText="No"
                                       cancelButtonDisabled={isSubmitting}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelCallback={() => setDisplayNoticeModal(false)}
                                       confirmCallback={handleSendNotice}>
                      <p>
                        Are you sure you want to send a 21 Day Notice for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal isOpen={displayAcceptModal}
                                       title="Accept PA 660 Audit"
                                       confirmButtonText="Yes"
                                       confirmButtonColor="success"
                                       cancelButtonText="No"
                                       cancelButtonDisabled={isSubmitting}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelCallback={() => setDisplayAcceptModal(false)}
                                       confirmCallback={handleAccept}>
                      <p>
                        Are you sure you want to accept the PA 660 Audit for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal isOpen={displayAcceptWithTechnicalIssuesModal}
                                       title="Accept PA 660 Audit"
                                       confirmButtonText="Yes"
                                       confirmButtonColor="success"
                                       cancelButtonText="No"
                                       cancelButtonDisabled={isSubmitting}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelCallback={() => setDisplayAcceptWithTechnicalIssuesModal(false)}
                                       confirmCallback={handleAcceptWithTechnicalIssues}>
                      <p>
                        Are you sure you want to accept the PA 660 Audit for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span> with technical issues?
                      </p>
                    </ConfirmationModal>
                    <ConfirmationModal isOpen={displaySendNoticeModal}
                                       title="Send 21 Day Notice"
                                       confirmButtonText="Yes"
                                       cancelButtonText="No"
                                       cancelButtonDisabled={isSubmitting}
                                       confirmButtonDisabled={isSubmitting}
                                       cancelCallback={() => setDisplaySendNoticeModal(false)}
                                       confirmCallback={handleSendNotice}>
                      <p>
                        Are you sure you want to send a 21 Day Notice for <span className="text-danger">{localUnitAudit.localUnit.displayNameWithType}</span>?
                      </p>
                    </ConfirmationModal>
                    <FollowUpModal isOpen={displayAssumeModal}
                                   localUnitAudit={localUnitAudit}
                                   onSubmit={handleAssume}
                                   onCancel={() => setDisplayAssumeModal(false)}
                                   isAssume={true}/>
                    <FollowUpModal isOpen={displayCapRequestFollowUpModal}
                                   localUnitAudit={localUnitAudit}
                                   onSubmit={handleFollowUpRequestCap}
                                   requireYear={false}
                                   onCancel={() => setDisplayCapRequestFollowUpModal(false)} />
                    <FollowUpModal isOpen={isFailureFollowUpModalOpen}
                                   localUnitAudit={localUnitAudit}
                                   onSubmit={handleFailureLetter}
                                   onCancel={() => setIsFailureFollowUpModalOpen(false)} />
                  </>
                }
              </Form>
            )}
          </Formik>
        </>
        }
      </Container>
    );
  }
};

export default ReviewSheetStateView;