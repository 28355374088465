import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

import {FormikInput, UserPermissions} from '@reasoncorp/kyber-js';
import {useMemo} from 'react';

type Props = {
  formEditable: boolean
  permissions: UserPermissions
}

const ReviewSheetComments = ({
                               formEditable,
                               permissions
                             }: Props) => {
  const className = useMemo(() => {
    return permissions.isBranchManager || permissions.isAuditSupport ? 'mb-4' : ''
  }, [permissions]);

  return (
    <Card className={className}>
      <CardHeader className="font-weight-bold">Comments</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <FormikInput name="comments"
                         labelText="Comments"
                         formGroupClass="larger-label"
                         type="textarea"
                         maxLength="500"
                         disabled={!formEditable}/>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ReviewSheetComments;