// Growl Messages
export const UNABLE_TO_LOAD_DATA = 'Unable to load data. Please try refreshing your page.';
export const UNABLE_TO_DOWNLOAD_DOCUMENT = 'Unable to download document.';
export const UNABLE_TO_RETRIEVE_FILE = 'Unable to retrieve file.';
export const INCLUDE_EXCLUDE_SUCCESSFUL = 'Update successful.';
export const INCLUDE_EXCLUDE_FAILURE = 'Unable to update.';
export const UNIT_NOTIFICATION_SUCCESSFUL = 'Unit Notification Sent';
export const UNIT_NOTIFICATION_FAILURE = 'Unit Notification Failed';
export const SEND_NOTICE_SUCCESSFUL = 'Notice Sent';
export const SEND_NOTICE_FAILURE = 'Unable to Send Notice';
export const ASSUME_SUCCESSFUL = 'Assumption Sent';
export const ASSUME_FAILURE = 'Unable to Send Assumption';
export const ROLLBACK_SUCCESSFUL = 'Rollback successful';
export const ROLLBACK_FAILURE = 'Unable to roll back.';
export const FAILURE_LETTER_SUCCESS = 'Audit complete successful.'
export const FAILURE_LETTER_FAILURE = 'Unable to complete audit.'

// Review sheet
export const REVIEW_SHEET_FORM_INVALID = 'Review Sheet is invalid or incomplete for the requested action.';
export const REVIEW_SHEET_SAVE_SUCCESSFUL = 'Review Sheet Saved';
export const REVIEW_SHEET_SAVE_FAILURE = 'Save Failed';
export const REVIEW_SHEET_SEND_TO_REVIEW_SUCCESSFUL = 'Send to Review Successful';
export const REVIEW_SHEET_SEND_TO_REVIEW_FAILURE = 'Send to Review Failed';
export const REVIEW_SHEET_RETURN_SUCCESSFUL = 'Return Successful';
export const REVIEW_SHEET_RETURN_FAILURE = 'Return Failed';
export const REVIEW_SHEET_REVIEWED_SUCCESSFUL = 'Reviewed Successful';
export const REVIEW_SHEET_REVIEWED_FAILURE = 'Reviewed Failed';
export const REVIEW_SHEET_SUBMIT_SUCCESSFUL = 'County Submitted';
export const REVIEW_SHEET_SUBMIT_FAILURE = 'County Submission Failed';
export const REVIEW_SHEET_ACCEPT_SUCCESSFUL = 'Audit Accepted';
export const REVIEW_SHEET_ACCEPT_FAILURE = 'Unable to accept audit';
export const REVIEW_SHEET_REPORT_SUCCESSFUL = 'Report Retrieved.';
export const REVIEW_SHEET_REPORT_FAILURE = 'Unable to Retrieve Report.';

// Cap
export const CAP_REQUEST_SUCCESSFUL = 'CAP Requested';
export const CAP_REQUEST_FAILURE = 'Unable to Request CAP';
export const CAP_SAVE_SUCCESSFUL = 'CAP Saved.';
export const CAP_SAVE_FAILURE = 'Unable to Save.';
export const CAP_SUBMIT_SUCCESSFUL = 'CAP Submitted.';
export const CAP_SUBMIT_FAILURE = 'Unable to Submit.';
export const CAP_DENY_SUCCESSFUL = 'CAP Denied';
export const CAP_DENY_FAILURE = 'Unable to Deny CAP';

// Review Sheet Validation
export const REQUIRED = 'Required';
export const NUMBER_RANGE_VALIDATION = 'Format XX.XX';

//Files
export const BAD_FILE_FORMAT_UPLOAD = 'Invalid file type. Allowed File Types are .pdf, .png, .jpg, .jpeg, .doc, .docx, .txt, .xls, .xlsm, .xlsx, and .csv.';
export const MAX_FILES_UPLOAD = 'Limit 5 files per upload.';
export const NO_FILES_UPLOADED = 'No files uploaded.';
export const FILE_DELETE_SUCCESSFUL = 'File deleted.';
export const FILE_DELETE_FAILURE = 'Unable to delete file.';
export const FILE_UPLOAD_SUCCESSFUL = 'File uploaded.';
export const FILE_UPLOAD_FAILURE = 'Unable to upload file.';
export const MUST_SELECT_AT_LEAST_1_SUB_DEFICIENCY = 'You must select at least 1 sub-deficiency.';

// Misc messages
export const NO_OPEN_AUDITS = 'No Open Audits';
export const NO_HISTORY_AVAILABLE = 'No History Available';
export const MAX_FILE_SIZE = 'File size must be smaller than 50MB.';
export const BAD_CAP_UPLOAD_FORMAT = 'File format must be PDF, PNG, JPG, JPEG, DOC, or DOCX.';
export const VIEW_FILE_FAILURE = 'Unable to view file.';

//Validation messages
export const maxCharactersMessage = (characters: number) => `Cannot be more than ${characters} characters.`;