import {useCallback, useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {
  BreadcrumbsNav,
  ButtonIcon,
  CustomTable,
  MiSuiteRole,
  ProgressIndicator,
  ssoUtils,
  useAlerts,
  useUserContext,
  windowUtils
} from '@reasoncorp/kyber-js';

import {useAppContext} from '../hooks';
import {auditHistoryApi, localUnitAuditApi} from '../api';
import * as messages from '../messages';
import {JurisdictionFormFields, LocalUnitAudit} from '../types';
import {LocalUnitAuditStatus} from '../enum';
import {JurisdictionSelect} from '../components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const LocalUnitPortalAudits = () => {
  const navigate = useNavigate();
  const params = useParams() as {localUnitId: string};
  const localUnitId = useMemo(() => parseInt(params.localUnitId), [params.localUnitId]);
  const {showErrorAlert} = useAlerts();
  const {localUnitPermissions} = useAppContext();
  const {currentUser} = useUserContext();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false});
  const [localUnitAudits, setLocalUnitAudits] = useState<LocalUnitAudit[]>([]);
  const [hideFilingCabinetCell, setHideFilingCabinetCell] = useState(true);

  const breadcrumbs = useMemo(() => ([{text: 'Dashboard', active: true}]), []);

  const handleJurisdictionChange = useCallback(async (values: JurisdictionFormFields,
                                                      formikHelpers: FormikHelpers<JurisdictionFormFields>) => {
    try {
      setLoadingState(prevLoadingState => ({...prevLoadingState, loading: true}));
      const localUnitAudits = await localUnitAuditApi.findByLocalUnitId(values.localUnitId);
      setLocalUnitAudits(localUnitAudits);
      setHideFilingCabinetCell(!ssoUtils.hasJurisdictionCanonicalIdAndRole(
        currentUser,
        values.localUnitId,
        MiSuiteRole.ASSESSOR_OF_RECORD
      ));

      navigate(`/local-unit-portal/${values.localUnitId}`);
    } catch (error) {
      showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
    } finally {
      formikHelpers.setSubmitting(false);
      setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
    }
  }, [
    navigate,
    showErrorAlert,
    currentUser
  ]);

  const handleReviewSheetPdfClick = useCallback(async (localUnitAuditId: number) => {
    await windowUtils.openFileInNewWindow(
      auditHistoryApi.findReviewSheetDocument(localUnitAuditId),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [
    showErrorAlert
  ]);

  const handleCapClick = useCallback((localUnitAudit: LocalUnitAudit) => {
    navigate(`/local-unit-portal/${localUnitAudit.localUnit.id}/local-unit-audits/${localUnitAudit.id}/cap`);
  }, [
    navigate
  ]);

  const handleCapPdfClick = useCallback(async (localUnitAuditId: number) => {
    await windowUtils.openFileInNewWindow(
      auditHistoryApi.findCapDocument(localUnitAuditId),
      () => showErrorAlert(messages.UNABLE_TO_DOWNLOAD_DOCUMENT)
    );
  }, [
    showErrorAlert
  ]);

  const handleFilingCabinetClick = useCallback(async (localUnitAudit: LocalUnitAudit) => {
    navigate(`/local-unit-portal/${localUnitAudit.localUnit.id}/${localUnitAudit.year}/filing-cabinet`);
  }, [
    navigate
  ]);

  const handleHistoryClick = useCallback((localUnitAudit: LocalUnitAudit) => {
    navigate(`/local-unit-portal/${localUnitAudit.localUnit.id}/local-unit-audits/${localUnitAudit.id}/audit-history`);
  }, [
    navigate
  ]);

  const AuditReviewSheetCell = useMemo(() => ({
                                                localUnitAudit
                                              }: {localUnitAudit: LocalUnitAudit}) => {
    if (localUnitAudit.offline) {
      return null;
    } else if (localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_REQUESTED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_RECEIVED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_DENIED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.NOTICE_RECEIVED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ROLL_ASSUMED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITH_TECHNICAL_ISSUES ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITHOUT_FOLLOW_UP ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.ACCEPTED_WITH_FOLLOW_UP) {
      return (
        <ButtonIcon icon="file-pdf"
                    title={`${localUnitAudit.localUnit.displayNameWithType} Audit review sheet PDF`}
                    ariaLabel={`${localUnitAudit.localUnit.displayNameWithType} Audit review sheet PDF`}
                    className="text-primary"
                    onClick={() => handleReviewSheetPdfClick(localUnitAudit.id)}/>
      );
    } else {
      return null;
    }
  }, [
    handleReviewSheetPdfClick
  ]);

  const CapCell = useMemo(() => ({
                                   localUnitAudit
                                 }: {localUnitAudit: LocalUnitAudit}) => {
    if (localUnitAudit.offline || localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED) {
      return null;
    } else if (localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_REQUESTED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_RECEIVED ||
      localUnitAudit.rawStatus === LocalUnitAuditStatus.CAP_DENIED ||
      (localUnitAudit.hasCap && localUnitAudit.rawStatus === LocalUnitAuditStatus.NOTICE_RECEIVED)) {
      return (
        <ButtonIcon icon="clipboard"
                    title={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP`}
                    ariaLabel={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP`}
                    className="text-primary"
                    onClick={() => handleCapClick(localUnitAudit)}/>
      );
    } else if (localUnitAudit.hasCap) {
      return (
        <ButtonIcon icon="file-pdf"
                    title={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP PDF`}
                    ariaLabel={`Latest ${localUnitAudit.localUnit.displayNameWithType} CAP PDF`}
                    className="text-primary"
                    onClick={() => handleCapPdfClick(localUnitAudit.id)}/>

      );
    } else {
      return null;
    }
  }, [
    handleCapClick,
    handleCapPdfClick
  ]);

  const FilingCabinetCell = useMemo(() => ({
                                             localUnitAudit
                                           }: {localUnitAudit: LocalUnitAudit}) => {
    return <Button color="link"
                   onClick={() => handleFilingCabinetClick(localUnitAudit)}
                   className="FilingCabinetButton">
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon="folder-open"/>
        {localUnitAudit.filingCabinetCount > 0 &&
          <span className="fa-layers-counter filing-cabinet-badge">{localUnitAudit.filingCabinetCount}</span>}
      </span>
    </Button>;
  }, [
    handleFilingCabinetClick
  ]);

  const HistoryCell = useMemo(() => ({
                                       localUnitAudit
                                     }: {localUnitAudit: LocalUnitAudit}) => {
    if (localUnitAudit.offline || localUnitAudit.rawStatus === LocalUnitAuditStatus.EXCLUDED) {
      return null;
    } else {
      return <ButtonIcon icon="history"
                         className="text-primary"
                         title={`${localUnitAudit.localUnit.displayNameWithType} history`}
                         ariaLabel={`${localUnitAudit.localUnit.displayNameWithType} history`}
                         onClick={() => handleHistoryClick(localUnitAudit)}/>;
    }
  }, [
    handleHistoryClick
  ]);

  const tableProps = useMemo(() => ({
    headers: [
      {title: 'Audit Year', sortKey: 'year', className: 'align-middle text-center text-nowrap'},
      {title: 'Audit Type', sortKey: 'followUp', className: 'align-middle text-center text-nowrap'},
      {title: 'Audit Status', sortKey: 'status', className: 'align-middle text-center text-nowrap'},
      {title: 'Audit', className: 'align-middle text-center'},
      {title: 'CAP', className: 'align-middle text-center'},
      {title: 'Filing Cabinet', className: 'align-middle text-center', hide: hideFilingCabinetCell},
      {title: 'History', className: 'align-middle text-center'}
    ],
    className: 'mb-0',
    noResultsMessage: 'No Audits Available',
    initialSort: {sortKey: 'year', direction: 'desc' as const},
    items: localUnitAudits,
    renderRow: (localUnitAudit: LocalUnitAudit) => {
      return (
        <tr key={localUnitAudit.id}>
          <td className="align-middle text-center">{localUnitAudit.year}</td>
          <td className="align-middle text-center">{localUnitAudit.followUpYear ? 'Follow Up' : 'Regular'}</td>
          <td className="align-middle text-center">
            {localUnitAudit.offline ? 'Offline' : localUnitAudit.localUnitPortalStatus}
          </td>
          <td className="align-middle text-center">
            <AuditReviewSheetCell localUnitAudit={localUnitAudit}/>
          </td>
          <td className="align-middle text-center">
            <CapCell localUnitAudit={localUnitAudit}/>
          </td>
          {!hideFilingCabinetCell &&
            <td className="align-middle text-center">
              <FilingCabinetCell localUnitAudit={localUnitAudit}/>
            </td>}
          <td className="align-middle text-center">
            <HistoryCell localUnitAudit={localUnitAudit}/>
          </td>
        </tr>
      );
    }
  }), [
    hideFilingCabinetCell,
    localUnitAudits,
    AuditReviewSheetCell,
    CapCell,
    FilingCabinetCell,
    HistoryCell
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (localUnitPermissions.length !== 0) {
          const localUnitIdToUse = localUnitId ? localUnitId : localUnitPermissions[0].localUnitId;
          const localUnitAudits = await localUnitAuditApi.findByLocalUnitId(localUnitIdToUse);
          setLocalUnitAudits(localUnitAudits);
          setHideFilingCabinetCell(!ssoUtils.hasJurisdictionCanonicalIdAndRole(
            currentUser,
            localUnitIdToUse,
            MiSuiteRole.ASSESSOR_OF_RECORD
          ));

          setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
        }
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [localUnitPermissions, localUnitId, showErrorAlert, currentUser]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="LocalUnitPortalAudits">
        <Row className="d-flex align-items-center mb-3">
          <Col md="6">
            <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
          </Col>
          <Col md="6" className="d-flex justify-content-end">
            <JurisdictionSelect localUnitId={localUnitId}
                                localUnitPermissions={localUnitPermissions}
                                disabled={loadingState.loading}
                                onChange={handleJurisdictionChange}/>
          </Col>
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Card>
            <CardHeader>Audits</CardHeader>
            <CustomTable {...tableProps}/>
          </Card>
        </>
        }
      </Container>
    );
  }
};

export default LocalUnitPortalAudits;
