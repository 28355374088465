import {useCallback, useEffect, useMemo, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Col, Container, Jumbotron, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';

import {BreadcrumbsNav, ProgressIndicator, ProgressModal, useAlerts} from '@reasoncorp/kyber-js';

import {auditYearApi, countyAuditApi, reportApi} from '../api';
import * as messages from '../messages';
import {AuditYearFormFields, CountyAudit, Portal} from '../types';
import {AuditYearSelect, CountyAuditCard} from '../components';

type Props = {
  portal: Portal
}

const CountyAudits = ({portal}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {showSuccessAlert, showErrorAlert} = useAlerts();
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, exportingReport: false});
  const urlAuditYear = new URLSearchParams(location.search).get('auditYear');
  const [data, setData] = useState<{auditYears: number[], countyAudits: CountyAudit[]}>({
    auditYears: [],
    countyAudits: []
  });

  const breadcrumbs = useMemo(() => ([
    {text: 'Dashboard', route: `/${portal}-portal`, active: true}
  ]), [
    portal
  ]);

  const loadCountyAudits = useCallback(async (auditYear: null | string | number) => {
      if (auditYear) {
        return await countyAuditApi.findByYear(auditYear as number, portal);
      } else {
        return await countyAuditApi.findOpen(portal);
      }
    }, [
      portal
  ]);

  const handleAuditYearChange = useCallback(async (values: AuditYearFormFields,
                                       formikHelpers: FormikHelpers<AuditYearFormFields>) => {
    setLoadingState(prevLoadingState => ({...prevLoadingState, loading: true}));
    const searchParams = new URLSearchParams();

    if (values.auditYear) {
      searchParams.set('auditYear', values.auditYear.toString());
    }

    navigate(`${location.pathname}?${searchParams.toString()}`);

    try {
      const countyAudits = await loadCountyAudits(values.auditYear);
      formikHelpers.setSubmitting(false);
      setData({...data, countyAudits});
      setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
    } catch (error) {
      setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
      showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
    }
  }, [
    navigate,
    showErrorAlert,
    data,
    loadCountyAudits,
    location.pathname
  ]);

  const handleReviewSheetReportClick = useCallback(async () => {
    try {
      setLoadingState(prevLoadingState => ({...prevLoadingState, exportingReport: true}));
      await reportApi.generateReviewSheet(Number(urlAuditYear));
      showSuccessAlert(messages.REVIEW_SHEET_REPORT_SUCCESSFUL);
    } catch (error) {
      showErrorAlert(messages.REVIEW_SHEET_REPORT_FAILURE);
    } finally {
      setLoadingState(prevLoadingState => ({...prevLoadingState, exportingReport: false}));
    }
  }, [
    showErrorAlert,
    showSuccessAlert,
    urlAuditYear
  ]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [auditYears, countyAudits] = await Promise.all([
          auditYearApi.findAll(),
          loadCountyAudits(urlAuditYear)
        ]);
        setData({auditYears, countyAudits});
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false}));
      } catch (error) {
        setLoadingState(prevLoadingState => ({...prevLoadingState, loading: false, loadError: true}));
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA);
      }
    };

    void loadData();
  }, [urlAuditYear, loadCountyAudits, showErrorAlert]);

  if (loadingState.loadError) {
    return null;
  } else {
    return (
      <Container fluid className="CountyAudits">
        <BreadcrumbsNav breadcrumbs={breadcrumbs}/>
        <Row className="d-flex align-items-center">
          <Col md="2">
            <AuditYearSelect selectedAuditYear={urlAuditYear ? urlAuditYear : ''}
                             auditYears={data.auditYears ? data.auditYears : []}
                             disabled={loadingState.loading}
                             onChange={handleAuditYearChange}/>
          </Col>
          {portal === 'state' && urlAuditYear && data.countyAudits.length !== 0 &&
            <Col className="d-flex justify-content-end" md="10">
              <Button color="primary"
                      aria-label="PA 660 Audit Report"
                      disabled={loadingState.loading}
                      onClick={handleReviewSheetReportClick}>
                PA 660 Audit Report
              </Button>
            </Col>
          }
        </Row>
        {loadingState.loading && <ProgressIndicator/>}
        {!loadingState.loading && <>
          <Row>
            {data.countyAudits.map((countyAudit) => {
                return (
                  <Col sm="12" md="6" lg="4" xl="3" className="mb-3" key={countyAudit.id}>
                    <CountyAuditCard portal={portal}
                                     countyAudit={countyAudit}
                                     showYear={!urlAuditYear}
                                     onSelection={() => navigate(`/${portal}-portal/county-audits/${countyAudit.id}`)}/>
                  </Col>
                );
              }
            )}
            {data.countyAudits.length === 0 &&
              <Col>
                <Jumbotron role="region">
                  <p className="text-muted text-center">
                    {urlAuditYear ? messages.NO_HISTORY_AVAILABLE : messages.NO_OPEN_AUDITS}
                  </p>
                </Jumbotron>
              </Col>
            }
          </Row>
          <ProgressModal isOpen={loadingState.exportingReport}
                         title="Generating PA 660 Audit Report"
                         content="Report is being generated. Please do not refresh the page, as this could take a few moments."/>
        </>
        }
      </Container>
    );
  }
};

export default CountyAudits;