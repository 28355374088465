import {FilingCabinetType} from '../types';
import {pa660Api} from './apiUtils';

export const upload = (localUnitId: number | string, year: number | string, fileData: FormData) => pa660Api.postWithJsonResponse(
  `/filing-cabinet/local-units/${localUnitId}/files?year=${year}`,
  {body: fileData}
);

export const deleteFile = (localUnitId: number | string, fileUploadId: number) => pa660Api.deleteWithJsonResponse(
  `/filing-cabinet/local-units/${localUnitId}/files/${fileUploadId}`
);


export const findFilesByLocalUnitIdAndYear = (localUnitId: number | string,
                                              year: number | string): Promise<FilingCabinetType> => pa660Api.getWithJsonResponse(
  `/filing-cabinet/local-units/${localUnitId}/files?year=${year}`
);