import {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';

import {Button, Card, CardHeader, Col, Container, Row} from 'reactstrap';
import {FormikHelpers} from 'formik';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

import {
  BreadcrumbsNav,
  ButtonIcon,
  ConfirmationModal,
  CustomTable,
  ProgressIndicator,
  useAlerts
} from '@reasoncorp/kyber-js';

import {downloadFile, filingCabinetApi} from '../api';
import {FileUploadModal} from '../components/filingCabinet';
import {LocalUnitAuditStatus} from '../enum';
import * as messages from '../messages';
import {FileUpload, FilingCabinetType, FilingCabinetUpload, Portal} from '../types';
import {formatDate} from '../utils';

type Props = {
  portal: Portal
}

type Breadcrumb = {
  text: string;
  icon?: IconProp;
  route?: string;
  active?: boolean;
}

const FilingCabinet = ({portal}: Props) => {
  const {countyAuditId, localUnitId, year} = useParams() as {countyAuditId: string, localUnitId: string, year: string};
  const [loadingState, setLoadingState] = useState({loading: true, loadError: false, processing: false});
  const [fileUploadModalIsOpen, setFileUploadModalIsOpen] = useState(false);
  const [filingCabinet, setFilingCabinet] = useState<FilingCabinetType | undefined>(undefined);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [selectedFileUpload, setSelectedFileUpload] = useState<FileUpload | undefined>(undefined);
  const {showErrorAlert, showSuccessAlert} = useAlerts();
  const disableDelete = useMemo(() => {
    return (portal === 'reason' || portal === 'local-unit') &&
      filingCabinet &&
      filingCabinet.localUnitAuditStatus === LocalUnitAuditStatus.SUBMITTED_TO_STATE;
  }, [portal, filingCabinet]);

  useEffect(() => {
    const findFileUploads = async () => {
      try {
        const filingCabinet = await filingCabinetApi.findFilesByLocalUnitIdAndYear(localUnitId, year);
        setFilingCabinet(filingCabinet);
        setLoadingState({loading: false, loadError: false, processing: false});
      } catch (e) {
        showErrorAlert(messages.UNABLE_TO_LOAD_DATA, true);
        setLoadingState({loading: false, loadError: true, processing: false});
      }

    };

    void findFileUploads();
  }, [countyAuditId, localUnitId, portal, year, showErrorAlert]);

  const isPreviousYearAudit = useMemo(() => {
    const now = new Date();
    const cutoffDate = new Date(now.getFullYear(), 2, 1);
    return now >= cutoffDate && +year < now.getFullYear();
  }, [year]);

  const breadcrumbs = useMemo((): Breadcrumb[] => {
    if (portal === 'local-unit') {
      return [{
        text: 'Dashboard',
        active: false,
        route: `/${portal}-portal/${localUnitId}/`,
        icon: 'home' as const
      },
        {
          text: filingCabinet?.localUnit ? filingCabinet.localUnit.county.displayNameWithType + ' - ' + filingCabinet.localUnit.displayNameWithType + ' Filing Cabinet' : '',
          active: true
        }
      ];
    } else {
      return [{
        text: 'Dashboard',
        active: false,
        route: `/${portal}-portal/`,
        icon: 'home' as const
      },
        {
          text: filingCabinet?.localUnit ? `${filingCabinet.localUnit.county.displayNameWithType} Local Units` : 'Dashboard',
          active: false,
          route: `/${portal}-portal/county-audits/${countyAuditId}`
        },
        {
          text: filingCabinet?.localUnit ? filingCabinet.localUnit.displayNameWithType + ' Filing Cabinet' : '',
          active: true
        }];
    }
  }, [
    portal,
    countyAuditId,
    filingCabinet?.localUnit,
    localUnitId
  ]);

  const handleUpload = useCallback(async (filingCabinetUpload: FilingCabinetUpload,
                                          actions: FormikHelpers<FilingCabinetUpload>) => {
    try {
      setLoadingState({...loadingState, processing: true});
      const formData = new FormData();
      filingCabinetUpload.files.forEach(file => formData.append(`files`, file as File));
      filingCabinetUpload.descriptions.forEach((description, index) => {
        formData.append(`descriptions[${index}]`, description);
      });

      await filingCabinetApi.upload(localUnitId, year, formData);
      actions.resetForm();
      setFileUploadModalIsOpen(false);
      showSuccessAlert(messages.FILE_UPLOAD_SUCCESSFUL);

      const filingCabinet = await filingCabinetApi.findFilesByLocalUnitIdAndYear(localUnitId, year);
      setFilingCabinet(filingCabinet);
    } catch (e) {
      actions.setSubmitting(false);
      actions.resetForm();
      setFileUploadModalIsOpen(false);
      showErrorAlert(messages.FILE_UPLOAD_FAILURE);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    loadingState,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    year
  ]);

  const handleOpenDeleteModal = useCallback((fileUpload: FileUpload) => {
    setSelectedFileUpload(fileUpload);
    setDeleteModalIsOpen(true);
  }, []);

  const handleDelete = useCallback(async (fileUploadId: number) => {
    setLoadingState({...loadingState, processing: true});
    try {
      await filingCabinetApi.deleteFile(localUnitId, fileUploadId);
      const filingCabinet: FilingCabinetType = await filingCabinetApi.findFilesByLocalUnitIdAndYear(localUnitId, year);
      setFilingCabinet(filingCabinet);
      showSuccessAlert(messages.FILE_DELETE_SUCCESSFUL);
    } catch (e) {
      showErrorAlert(messages.FILE_DELETE_FAILURE);
    } finally {
      setLoadingState({...loadingState, processing: false});
    }
  }, [
    loadingState,
    localUnitId,
    showErrorAlert,
    showSuccessAlert,
    year
  ]);

  const toggleDeleteModal = useCallback(async (confirmDelete = false) => {
    if (confirmDelete && selectedFileUpload) {
      await handleDelete(selectedFileUpload.id);
    }

    setDeleteModalIsOpen(!deleteModalIsOpen);
  }, [
    deleteModalIsOpen,
    handleDelete,
    selectedFileUpload
  ]);

  const handleDownload = downloadFile(showErrorAlert);

  const tableProps = useMemo(() => ({
    headers: [
      {
        title: 'Date',
        className: 'text-nowrap text-center',
        sortKey: 'createdAt'
      },
      {
        title: 'File Name',
        className: 'text-nowrap',
        sortKey: 'fileName'
      },
      {
        title: 'File Description',
        className: 'text-nowrap'
      }, {
        title: 'User',
        className: 'text-nowrap',
        sortKey: 'createdByUserFullName'
      }, {
        title: 'View File',
        className: 'text-nowrap text-center'
      }, {
        title: 'Delete File',
        className: 'align-middle text-nowrap text-center',
        hide: portal === 'local-unit'
      }
    ],
    noResultsMessage: messages.NO_FILES_UPLOADED,
    items: filingCabinet?.fileUploads ?? [],
    renderRow: (fileUpload: FileUpload) => <tr key={fileUpload.id}>
      <td className="text-center align-middle">{formatDate(fileUpload.createdAt)}</td>
      <td className="align-middle">{fileUpload.fileName}</td>
      <td className="align-middle">{fileUpload.description || ''}</td>
      <td className="align-middle">{fileUpload.createdByUserFullName}</td>
      <td className="text-center align-middle">
        <ButtonIcon ariaLabel="View File"
                    title="View File"
                    size="lg"
                    icon="paperclip"
                    onClick={() => handleDownload(fileUpload.url)}
        />
      </td>
      {portal !== 'local-unit' && <td className="text-center align-middle">
        <ButtonIcon onClick={() => handleOpenDeleteModal(fileUpload)}
                    disabled={disableDelete}
                    icon="trash"
                    className={disableDelete ? 'disabled' : 'text-danger'}
                    title="Delete File"
                    ariaLabel="Delete File"
                    size="lg"/>
      </td>}
    </tr>,
    initialSort: {
      sortKey: 'createdAt',
      direction: 'desc' as const
    }
  }), [
    disableDelete,
    filingCabinet?.fileUploads,
    handleDownload,
    handleOpenDeleteModal,
    portal
  ]);

  return <Container fluid className="CountyFilingCabinet">
    {loadingState.loading && <ProgressIndicator/>}
    {!loadingState.loading && <>
      <Row className="mb-3">
        <Col md="6">
          <BreadcrumbsNav breadcrumbs={breadcrumbs} inline/>
        </Col>
        <Col md="6" className="d-flex justify-content-end">
          <Button color="primary"
                  onClick={() => setFileUploadModalIsOpen(!fileUploadModalIsOpen)}
                  disabled={loadingState.loadError || loadingState.processing || isPreviousYearAudit}>
            Upload File
          </Button>
        </Col>
      </Row>
      <Card className="mt-2">
        <CardHeader className="bg-secondary text-white">
          Filing Cabinet
        </CardHeader>
        <CustomTable {...tableProps}/>
      </Card>
      {portal === 'local-unit' &&
        <Row className="mt-3">
          <Col className="text-secondary">
            <strong>All files uploaded may be viewed by Auditor and Department of Treasury.</strong>
          </Col>
        </Row>}
    </>}
    <FileUploadModal isOpen={fileUploadModalIsOpen}
                     onSubmit={handleUpload}
                     onToggle={() => setFileUploadModalIsOpen(!fileUploadModalIsOpen)}/>

    <ConfirmationModal isOpen={deleteModalIsOpen}
                       title="Delete Upload"
                       confirmButtonText="Yes"
                       cancelButtonText="No"
                       confirmCallback={() => toggleDeleteModal(true)}
                       cancelCallback={() => toggleDeleteModal(false)}>
      <p>
        Are you sure you want to delete the <span className="text-danger">{selectedFileUpload && selectedFileUpload.fileName}</span> upload?
      </p>
    </ConfirmationModal>
  </Container>;
};

export default FilingCabinet;