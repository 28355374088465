import {pa660Api} from './apiUtils';
import {DenyCapFormFields, FollowUpDto, LocalUnitAudit} from '../types';
import {Cap, CapUpload} from '../types/cap';

export const request = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.postWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap`
);

export const followUpRequest = (localUnitAuditId: number,
                        followUpRequest: FollowUpDto): Promise<LocalUnitAudit> => pa660Api.postWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/follow-up-audit/cap`,
  {
    body: JSON.stringify(followUpRequest)
  }
);

export const save = (localUnitAuditId: number,
                     capFormRequest: FormData): Promise<Cap> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap`,
  {
    body: capFormRequest
  }
);

export const submit = (localUnitAuditId: number,
                       capFormRequest: FormData): Promise<Cap> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap/submit`,
  {
    body: capFormRequest
  }
);

export const deny = (localUnitAuditId: number,
                     denyCapRequest: DenyCapFormFields): Promise<Cap> => pa660Api.patchWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap/deny`,
  {
    body: JSON.stringify(denyCapRequest)
  }
);

export const acceptWithoutFollowUp = (localUnitAuditId: number): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `/local-unit-audits/${localUnitAuditId}/cap/accept/without-follow-up`
);

export const acceptWithFollowUp = (localUnitAuditId: number,
                                   followUpRequest: FollowUpDto): Promise<LocalUnitAudit> => pa660Api.patchWithJsonResponse(
  `/local-unit-audits/${localUnitAuditId}/cap/accept/with-follow-up`,
  {
    body: JSON.stringify(followUpRequest)
  }
);

export const findLatest = (localUnitAuditId: number): Promise<Cap> => pa660Api.getWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap/latest`
);

export const findUploads = (localUnitAuditId: number): Promise<CapUpload[]> => pa660Api.getWithJsonResponse(
  `local-unit-audits/${localUnitAuditId}/cap/latest/uploads`
);

export const findUpload = (localUnitAuditId: number, capUploadId: number): Promise<string> => pa660Api.getWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/cap/latest/uploads/${capUploadId}`
);

export const generateDraftPdf = (localUnitAuditId: number, cap: Cap): Promise<string> => pa660Api.postWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/cap/draft`,
  {
    body: JSON.stringify(cap)
  }
);

export const generateDenyDraftPdf = (localUnitAuditId: number,
                                     capDeniedRequest: DenyCapFormFields): Promise<string> => pa660Api.postWithTextResponse(
  `local-unit-audits/${localUnitAuditId}/cap/deny/draft`,
  {
    body: JSON.stringify(capDeniedRequest)
  }
);