enum CountyAuditStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  RECEIVED = 'RECEIVED',
  CLOSED = 'CLOSED'
}

export default CountyAuditStatus;