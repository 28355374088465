// Reference the font awesome icon library from React Kyber.
// This library will have icons needed by all React Kyber components
import {kyberFontAwesomeLibrary} from '@reasoncorp/kyber-js';
// import all icons the app will need here
import {
  faCaretSquareLeft,
  faClipboard,
  faFilePdf,
  faFolderOpen,
  faHistory,
  faPaperclip,
  faRedo,
  faSpinner,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

// Add any additional icons your app needs to the library.
// This avoids having to explicitly import them in your components.
kyberFontAwesomeLibrary.add(
  faCaretSquareLeft,
  faFilePdf,
  faFolderOpen,
  faHistory,
  faRedo,
  faClipboard,
  faTrash,
  faPaperclip,
  faSpinner
);